import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { refreshSession } from "../refresh-session";

interface Options {
  chart_token?: string;
}

const makeApiInstance = (config: AxiosRequestConfig = {}, opts: Options = {}): AxiosInstance => {
  const instance = axios.create({
    withCredentials: true,
    ...config,
  });

  // Set Authorization header in interceptor as window.chart_token could not yet have been initialized
  instance.interceptors.request.use((request) => {
    const chartToken = opts.chart_token || window.chart_token;
    if (chartToken) {
      request.headers.Authorization = `Bearer ${chartToken}`;
    }

    return request;
  });

  instance.interceptors.response.use((response) => {
    // Call throttled refreshSession function to refresh session if user is active
    refreshSession();
    return response;
  });

  return instance;
};

export { makeApiInstance };
