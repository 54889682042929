<template lang="pug">
button.btn.btn-default.btn-dark-new-layout.btn-sm.btn-icon.report-action-button(v-uiv-tooltip.hover="duplicateTooltip" @click="duplicateReport()")
  i.fa.fa-files-o.fa-lg
</template>

<script lang="ts">
import i18n from "../i18n";
import Vue from "vue";
import Component from "vue-class-component";
import EventBus from "../events/event-bus";
import Events from "../events/events";

@Component({})
export default class DuplicateReportButton extends Vue {
  get duplicateTooltip() {
    return i18n.t("actions.duplicate");
  }

  duplicateReport() {
    EventBus.emit(Events.duplicateReport);
  }
}
</script>
