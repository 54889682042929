// http://bootstraptour.com/api/

import $ from "jquery";
import I18n from "../i18n";

const Tour = window.Tour;

$(document).ready(function() {
  // Helper function to click the element of the current step
  const clickElement = function(tour) {
    const idx = tour.getCurrentStep();
    const step = tour.getStep(idx);
    return $(step.element).click();
  };

  const tours = {
    tour: {
      storage: false,
      template:
        `\
<div class='popover tour'> \
<div class='arrow'></div> \
<h3 class='popover-title'></h3> \
<div class='popover-content'></div> \
<div class='popover-navigation'> \
<div class='btn-group'> \
<button class='btn btn-sm btn-default disabled' data-role='prev'>« ` +
        I18n.t("demo.tours.btn-prev") +
        `</button> \
<button class='btn btn-sm btn-default' data-role='next'>` +
        I18n.t("demo.tours.btn-next") +
        ` »</button> \
</div> \
<button class='btn btn-sm btn-default btn-end-tour' data-role='end'>` +
        I18n.t("demo.tours.btn-end-tour") +
        `</button> \
</div> \
</div>\
`,
      steps: [
        {
          orphan: true,
          backdrop: true,
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 1/2</div><div>" +
            I18n.t("demo.tours.welcome.card1.title") +
            "</div>",
          content:
            I18n.t("demo.tours.welcome.card1.content") +
            `\
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div>\
`,
        },
        {
          backdrop: false,
          element: "form.edit_tour:first input.btn",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 2/2</div><div>" +
            I18n.t("demo.tours.welcome.card2.title") +
            "</div>",
          content:
            I18n.t("demo.tours.welcome.card2.content") +
            `\
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
        { nothing: "so we show 'next' on previous step" },
      ],
    },
    dashboard: {
      //storage: window.localStorage,
      storage: false,
      orphan: true,
      autoscroll: true,
      delay: 100,
      template:
        `\
<div class='popover tour'> \
<div class='arrow'></div> \
<h3 class='popover-title'></h3> \
<div class='popover-content'></div> \
<div class='popover-navigation'> \
<div class='btn-group'> \
<button class='btn btn-sm btn-default btn-prev' data-role='prev'>« ` +
        I18n.t("demo.tours.btn-prev") +
        `</button> \
<button class='btn btn-sm btn-default btn-next' data-role='next'>` +
        I18n.t("demo.tours.btn-next") +
        ` »</button> \
</div> \
<button class='btn btn-sm btn-default btn-end-tour' data-role='end'>` +
        I18n.t("demo.tours.btn-end-tour") +
        `</button> \
</div> \
</div>\
`,
      steps: [
        {
          backdrop: true,
          element: ".is-metric-snippet.metric-sales > .panel-snippet",
          placement: "auto right",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 1/11</div><div>" +
            I18n.t("demo.tours.dashboard.card1.title") +
            "</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card1.title-content") +
            "</div>",
          content:
            `\
<div class='antonanton'> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
        {
          backdrop: false,
          element: ".is-metric-snippet.metric-sales > .panel-snippet .show-more",
          placement: "bottom",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 2/11</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card2.title-content") +
            "</div>",
          content:
            `\
<div class='cathkidston'> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
        {
          backdrop: false,
          element: ".component-details #tour-grouping-select",
          placement: "top",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 3/11</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card3.title-content") +
            "</div>",
          content:
            `\
<div class='themoltonbrown'> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
        {
          backdrop: false,
          element: ".component-details .drilldown-wrapper",
          placement: "left",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 4/11</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card8.title-content") +
            "</div>",
          content:
            `\
<div class='thebodyshop'> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
        {
          backdrop: false,
          element: ".component-details .panel-body .dashboard-block",
          placement: "top",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 5/11</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card9.title-content") +
            "</div>",
          content:
            `\
<div class='timanttiset'> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
        {
          backdrop: false,
          element: ".filters-toggle-container .fo-handle.show-filters > *:first-child",
          placement: "left",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 6/11</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card4.title-content") +
            "</div>",
          content:
            `\
<div class='picnic'> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
        {
          backdrop: true,
          element: ".time-structure .btn:first",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 7/11</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card5.title-content") +
            "</div>",
          content:
            `\
<div class='gateau'> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div> \
</div>\
`,
        },
        {
          backdrop: true,
          element: ".widget-filters .main-widget-filters",
          placement: "bottom",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 8/11</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card6.title-content") +
            "</div>",
          content:
            `\
<div class='kekaele'> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
        {
          backdrop: true,
          element: ".btn.refresh-button.btn-primary",
          placement: "bottom",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 9/11</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card7.title-content") +
            "</div>",
          content:
            `\
<div class='intersport'> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
        {
          backdrop: false,
          element: ".tour-dashboards-analytics",
          placement: "auto right",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 10/11</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card11.title-content") +
            "</div>",
          content:
            `\
<div class='stjaernurmakarna'> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial") +
            `</button> \
</a> \
</div> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
        {
          backdrop: true,
          element: ".tour-profile-dropdown",
          placement: "left",
          title:
            "<div class='step'>" +
            I18n.t("demo.tours.step") +
            " 11/11</div><div class='content'>" +
            I18n.t("demo.tours.dashboard.card10.title-content") +
            "</div>",
          content:
            `\
<div class='kornapets'> \
<div class='specs'> \
<h4>` +
            I18n.t("demo.tours.dashboard.card10.specs.h4-cta-1") +
            `</h4> \
<h4>` +
            I18n.t("demo.tours.dashboard.card10.specs.h4-cta-2") +
            `</h4> \
</div> \
<div class='popover-cta'> \
<a href='https://calendly.com/zoined' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-book-a-demo-emp") +
            `</button> \
</a> \
<a href='https://www.zoined.com/en/?showTrialModal=1#contact' target='_new'> \
<button class='btn btn-sm btn-default'>` +
            I18n.t("demo.tours.btn-request-trial-emp") +
            `</button> \
</a> \
</div> \
</div>\
`,
          reflex: true,
          onNext: clickElement,
        },
      ],
    },
    cognimaticstour: {
      storage: false,
      steps: [
        {
          orphan: true,
          backdrop: true,
          title: "Welcome!",
          content: "Welcome to the interactive tour of TrueView Xense!  Click Next to get started.",
        },
        {
          element: "form.edit_tour:first input.btn",
          title: "Start tour",
          content: "This button takes you to the dashboard of a sample store.",
          reflex: true,
          onNext: clickElement,
        },
        { nothing: "so we show 'next' on previous step" },
      ],
    },
    cognimaticsdashboard: {
      storage: false,
      steps: [
        {
          element: ".dashboard-snippet:first .panel-snippet",
          title: "Welcome to TrueView Xense! ",
          content: "In the dashboard, you can see selected metrics, such as your number of visitors.",
        },
        {
          element: ".dashboard-snippet:first .panel-snippet .show-more",
          placement: "bottom",
          content: "You can quickly drill down into detailed data.",
          reflex: true,
          onNext: clickElement,
        },
        {
          element: ".dashboard-snippet:first .panel-snippet select.form-control",
          placement: "right",
          content:
            "From this dropdown you can also change the perspective from 'Top Store' to something else. For example, if you select 'Top Sales Area' as your perspective, you can drill down to countries by clicking on the blue bars. Try it!",
        },
        {
          element: ".fo-handle.show-filters",
          placement: "bottom",
          content: "The real power of the service lies with the filters.",
          reflex: true,
          onNext: clickElement,
        },
        {
          element: ".time-structure button:first",
          content: "Here, you have the flexibility to select the desired timeframe.",
        },
        {
          element: "#selector_snippets",
          content: "And which KPIs and charts you wish to view.",
        },
        {
          element: ".btn.refresh-button.btn-primary",
          placement: "top",
          content: "Then click Refresh to update the Dashboard.",
          reflex: true,
          onNext: clickElement,
        },
        {
          element: "#user-toggle",
          placement: "left",
          content: "To find out more, please check the user manual. You can find it via opening this dropdown menu.",
          reflex: true,
          onNext: clickElement,
        },
      ],
    },
    axistour: {
      storage: false,
      steps: [
        {
          orphan: true,
          backdrop: true,
          title: "Welcome!",
          content: "Welcome to the interactive tour of AXIS Store Reporter!  Click Next to get started.",
        },
        {
          element: "form.edit_tour:first input.btn",
          title: "Start tour",
          content: "This button takes you to the dashboard of a sample store.",
          reflex: true,
          onNext: clickElement,
        },
        { nothing: "so we show 'next' on previous step" },
      ],
    },
    axisdashboard: {
      storage: false,
      steps: [
        {
          element: ".dashboard-snippet:first .panel-snippet",
          title: "Welcome to AXIS Store Reporter! ",
          content: "In the dashboard, you can see selected metrics, such as your number of visitors.",
        },
        {
          element: ".dashboard-snippet:first .panel-snippet .show-more",
          placement: "bottom",
          content: "You can quickly drill down into detailed data.",
          reflex: true,
          onNext: clickElement,
        },
        {
          element: ".dashboard-snippet:first .panel-snippet select.form-control",
          placement: "right",
          content:
            "From this dropdown you can also change the perspective from 'Top Store' to something else. For example, if you select 'Top Sales Area' as your perspective, you can drill down to countries by clicking on the blue bars. Try it!",
        },
        {
          element: ".fo-handle.show-filters",
          placement: "bottom",
          content: "The real power of the service lies with the filters.",
          reflex: true,
          onNext: clickElement,
        },
        {
          element: ".time-structure button:first",
          content: "Here, you have the flexibility to select the desired timeframe.",
        },
        {
          element: "#selector_snippets",
          content: "And which KPIs and charts you wish to view.",
        },
        {
          element: ".btn.refresh-button.btn-primary",
          placement: "top",
          content: "Then click Refresh to update the Dashboard.",
          reflex: true,
          onNext: clickElement,
        },
        {
          element: "#user-toggle",
          placement: "left",
          content: "To find out more, please check the user manual. You can find it via opening this dropdown menu.",
          reflex: true,
          onNext: clickElement,
        },
      ],
    },
  };

  const vTourend = window.getCookie("tourend");
  if (vTourend !== "1") {
    $(".tour").each(function(ix: any, el: any) {
      const name = $(el).data("tour");
      const options = tours[name];
      const tour = new Tour(options);
      tour.init;
      return tour.start(true);
    }); // for start tour where user left
    //tour.restart(true)      # for start tour all over

    if (document.location.pathname === "/dashboard") {
      const meta_info = $("meta[name='zoined']");
      if ((meta_info != null ? meta_info.data("type") : undefined) === "demo") {
        const style = (meta_info != null ? meta_info.data("style") : undefined) || "";
        const tour_name = `${style}dashboard`;
        const start_with_delay = function() {
          const options = tours[tour_name];
          const tour = new Tour(options);
          tour.init;
          return tour.start(true); // for start tour where user left
        };
        //tour.restart(true)      # for start tour all over
        setTimeout(start_with_delay, 1000);
      }

      const bind_tour_btn = () =>
        $(".tour-tour .btn-group button").on("click", () => setTimeout(bind_tour_buttons, 2000));

      const bind_end_tour_btn = () =>
        $(".tour-tour button[data-role=end]").on("click", () => window.setSessionCookie("tourend", "1"));

      var bind_tour_buttons = function() {
        bind_tour_btn();
        return bind_end_tour_btn();
      };

      setTimeout(bind_tour_buttons, 2000);
    }
  }
});
