<template lang="pug">
uiv-popover(:title="'actions.are_you_sure' | i18n" :disabled="disabled" :enable="!disabled" :tag="tag" v-model="show")
  template(slot="default")
    slot
      uiv-btn(:type="variant") {{ buttonTitle }}
  template(slot="popover")
    p(v-if="popoverMessage") {{ popoverMessage}}
    .flex-row.justify-content-space-between.gap-md
      .flex-1
        uiv-btn(type="success" block @click.stop="confirm()") {{ 'actions.confirm' | i18n }}
      .flex-1
        uiv-btn(block @click.stop="cancel()") {{ 'actions.cancel' | i18n }}
</template>

<script>
export default {
  props: {
    id: String,
    buttonTitle: String,
    popoverMessage: String,
    variant: {
      type: String,
      default: "danger",
    },
    tag: {
      type: String,
      default: "span",
    },
    disabled: Boolean,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.show = false;
    },
    cancel() {
      this.$emit("cancel");
      this.show = false;
    },
  },
};
</script>
