<template lang="pug">
.currency-selector
  dropdown(v-if="availableCurrencies.length > 1" v-model="open")
    .toggle
      navbar-dropdown-button.show-new-layout(:title="'activerecord.attributes.user.currency' | i18n" :subtitle="currentCurrency.currency")
        template(slot="icon")
          .currency-icon {{ currentCurrency.symbol }}
      a#currency-toggle.hide-new-layout
        span.text {{ currencyTitle(currentCurrency) }}
        span.short-text {{ currentCurrency.symbol }}
        i.fa.fa-chevron-down
    template(slot="dropdown")
      li(v-for="currency in availableCurrencies")
        a.currency-switch(@click="switchCurrency(currency)") {{ currencyTitle(currency) }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import i18n from "../i18n";
import { Dropdown } from "uiv";
import { postForm } from "../lib/form";
import Currency from "../model/currency";
import NavbarDropdownButton from "../components/navbar-dropdown-button.vue";
import { showOverlay } from "@/ui/overlay";

@Component({
  components: {
    Dropdown,
    NavbarDropdownButton,
  },
})
export default class CurrencySelector extends Vue {
  open = false;

  get currentCurrency() {
    return window.zoinedContext.currency;
  }

  get availableCurrencies() {
    return window.zoinedContext.currencies || [];
  }

  currencyTitle(currency: Currency) {
    return `${currency.currency} (${currency.symbol})`;
  }

  toggleOpen() {
    console.log("toggle");
    this.open = !this.open;
  }

  switchCurrency(currency: Currency) {
    this.open = false;
    showOverlay(i18n.t("please_wait"));
    postForm(`/switch_currency/${currency.currency}`);
  }
}
</script>
<style lang="scss">
.currency-selector {
  .dropdown-menu {
    min-width: initial;

    li + li {
      border-top: 1px solid #eee;
    }
  }

  .currency-icon {
    font-size: 16px;
  }
}
</style>
