<!-- wraps bootstrap dropdown -->
<template lang="pug">
.dropdown(:class="{ open }", @click.stop="")
  slot(name="toggle")
  .dropdown-menu
    slot(name="menu")
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onDocumentClick() {
      this.$emit("close");
    }
  },
  created() {
    document.addEventListener("click", this.onDocumentClick);
  },
  destroyed() {
    document.removeEventListener("click", this.onDocumentClick);
  }
};
</script>

<style scoped>
.dropdown {
  display: inline-block;
}
</style>
