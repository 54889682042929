<template lang="pug">
router-link.sidenav-report(v-if="report.router !== false", :to="report.path", :title="report.description")
  .icon
    svg-icon(:name="icon")

  span.font-weight-sb.font-size-md {{ report.name }}

a.sidenav-report(v-else, :title="report.description", :class="{ 'router-link-exact-active': active }", :href="report.path")
  .icon
    svg-icon(:name="icon")

  span.font-weight-sb.font-size-md {{ report.name }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SvgIcon from "../components/svg-icon.vue";
import { isReportActive } from "./utils";

@Component({
  components: {
    SvgIcon,
  },
})
export default class SidenavReport extends Vue {
  @Prop()
  report: any;

  get icon() {
    switch (this.report.type) {
      case "new-dashboard":
        return "new-analytics";
      case "new-report":
        return "new-analytics";
      case "dashboard":
        return "dashboard";
      case "table":
        return "table";
      default:
        return "visual-chart";
    }
  }

  get active() {
    return isReportActive(this.report);
  }
}
</script>

<style lang="scss" scoped>
.sidenav-report {
  padding: 6px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.2;
  color: var(--color-sidenav-text);

  .icon {
    display: flex;
    justify-content: center;
    height: 16px;
    margin-right: 10px;
    svg {
      max-height: 16px;
      max-width: 16px;
    }
  }

  &:hover {
    background-color: var(--color-sidenav-report-active-background);
  }

  &.router-link-exact-active {
    background-color: var(--color-sidenav-report-active-background);
    color: var(--color-sidenav-report-active-text);
  }
}
</style>
