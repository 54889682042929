<template lang="pug">
.report-block(
  :class="{ 'detail-open': isDetailsOpen, 'full-screen': fullScreen, 'drilldown-enabled': drilldownEnabled, handle: editor, [componentClass]: true, [metricClass]: true, [`span-${component.span}`]: component.span }"
)
  drilldown-provider.panel.panel-snippet
    .panel-heading.flex-column.gap-md
      .flex-row.justify-content-space-between.align-items-flex-start
        h3.panel-title
          span.fg-text-primary.font-semibold(v-if="title" v-uiv-tooltip="title")
            span {{ title }}
            span(v-if="isIndex")  {{ 'filter.metric_type.index' | i18n }}
            i.fa.fa-lg.fa-info-circle(v-if="component.help_text", v-uiv-tooltip.bottom="component.help_text")
          span.fg-text-variant.font-normal(v-if="secondaryTitle") {{ secondaryTitle }}
        .hidden-print.flex-row.gap-md.align-items-center
          excel-button.show-new-layout(v-if="dashboard && excelExportEnabled && !editor && !preview", @click="excelExport()")
          i.fa(
            v-if="fullScreenEnabled && !editor && !preview",
            :class="fullScreen ? 'fa-times' : 'fa-expand'",
            @click="fullScreen = !fullScreen"
          )
          .remove.flex-row.text-base(v-if="editor")
            i.fa.fa-cog.edit(v-uiv-tooltip.hover="t('actions.edit')", @click="showEditComponentModal(index)")
            i.fa.fa-files-o.duplicate(v-uiv-tooltip.hover="t('actions.duplicate')", @click="duplicate(index)") 
            i.fa.fa-times.remove(v-uiv-tooltip.hover="t('actions.delete')", @click="remove(index)") 
      component-filters.full-screen-hidden(v-if="showFilterConfiguration" :component="component" :chartOptions="chartOptions")
      .full-screen-hidden.flex-row.justify-content-space-between.align-items-flex-start.flex-wrap.gap-md
        .filter-configuration(v-if="showFilterConfiguration")
          legends.small(
            :show-symbol="showLegendSymbol",
            :selection="effectiveFilterConfiguration.selection",
            :comparisons="effectiveFilterConfiguration.comparisons",
            :span="component.span",
          )
        .flex-1(v-else)
        .toolbar.hidden-print.flex-row.align-items-center.justify-content-space-between.gap-md.flex-wrap(v-if="!public", :class="component.span <= 4 && ['hidden-sm', 'hidden-md', 'hidden-lg']")
          round-values-selector(
            v-if="dashboard && showRoundValuesSelector",
            :value="effectiveFilters.round_values",
            @input="updateRoundValues"
          )
          table-column-selector(
            v-if="dashboard && showTableColumnSelector",
            :config="tableColumnConfig",
            @change="tableColumnConfigUpdated"
          )
          data-labels-selector(
            v-if="dashboard && showDataLabelsSelector",
            :value="effectiveChartOptions.data_labels",
            @input="updateDataLabels($event)"
          )
          proportions-selector(
            v-if="dashboard && showProportionsSelector",
            :value="effectiveFilters.proportions",
            @input="updateProportions($event)"
          )
          chart-type-selector(
            v-if="dashboard && showChartTypeSelector",
            :value="chartType",
            @input="updateChartType($event)"
          )
          a.btn.btn-default.btn-sm(
            v-if="dashboard && showHeatmapSettings",
            @click="$refs.heatmapSettingsModal.show()"
          )
            i.fa.fa-bar-chart.fa-lg
          cross-tab-settings-modal(
            v-if="dashboard && showTableSettings",
            :table-config="component.tableConfig",
            :filter-configuration="effectiveFilterConfiguration",
            @update="updateTableConfig($event)"
          )
          trend-direction-selector(
            v-if="dashboard && showTrendSortSelector",
            :value="sortDirection",
            @input="onSortDirectionChange"
          )
          trend-chart-variant-selector(
            v-if="dashboard && showTrendSortSelector",
            :value="chartVariant",
            @input="onChartVariantChange"
          )
          a.hide-new-layout.btn.btn-default.btn-sm.btn-icon(
            v-if="dashboard && excelExportEnabled && !preview",
            @click="excelExport()"
          )
            i.fa.fa-file-excel-o.fa-lg
      .fullscreen-hidden.hidden-print.flex-row.flex-wrap
        drilldown-dropdown(v-if="drilldownEnabled && !preview")
        .flex-1
        trend-sort-selector(
            v-if="dashboard && showTrendSortSelector",
            :value="sortKey",
            @input="onSortKeyChange"
          )
    .panel-body
      .report-component(:class="component.type + ' ' + component.class_name", :style="style")
        dashboard-snippet(
          v-if="blockToUse == 'dashboard_snippet'",
          :type="effectiveFilters.metrics[0]",
          :component="component",
          :filters="effectiveFilters",
          @data="$emit('data', $event)"
        )
        zoined-snippet(
          v-if="blockToUse == 'snippet'",
          :type="component.name",
          :component="component",
          :full-screen="fullScreen",
          :filters="effectiveFilters",
          :filter-configuration="effectiveFilterConfiguration",
          :chart-options="effectiveChartOptions",
          :table-config="component.tableConfig || tableConfig",
          :pagination="paginationEnabled",
          @pageChanged="handlePageChange",
          @data="onSnippetData",
          @updateLimit="updateLimit"
        )
        trend-block(
          v-else-if="blockToUse == 'trend'",
          :chart="component.name",
          :component="component",
          :filters="effectiveFilters",
          :chart-options="effectiveChartOptions",
          :show-top="false",
          :pagination="paginationEnabled",
          @drilldown="handleDrilldown",
          @pageChanged="handlePageChange"
        )
        zoined-chart(
          v-else-if="blockToUse == 'metric_chart'",
          :type="effectiveFilters.metrics[0]",
          :component="component",
          :overflow-hidden="isNotification",
          :filters="effectiveFilters",
          :chart-options="effectiveChartOptions",
          :highchart-options="highchartOptions",
          :drilldown="drilldownEnabled",
          :editing="editor",
          @drilldown="handleDrilldown",
          @pageChanged="handlePageChange",
          @redraw="handleRedraw",
          @updateLimit="updateLimit"
        )
        insight-chart(
          v-else-if="blockToUse == 'insight_chart'",
          :component="component",
          :filters="effectiveFilters",
          :chart-options="effectiveChartOptions",
          @update="updateFilterConfiguration"
        )
        trend-insights-chart(
          v-else-if="blockToUse == 'trend_insights'",
          :component="component",
          :filters="effectiveFilters",
          :chart-options="effectiveChartOptions",
          :highchart-options="highchartOptions",
          :pagination="paginationEnabled",
          :limit="component.limit",
          :drilldown="drilldownEnabled",
          :editing="editor",
          @drilldown="handleDrilldown",
          @pageChanged="handlePageChange",
          @redraw="handleRedraw",
          @updateLimit="updateLimit"
        )
        zoined-chart(
          v-else-if="blockToUse == 'chart'",
          :type="component.name",
          :component="component",
          :filters="effectiveFilters",
          :chart-options="effectiveChartOptions",
          :highchart-options="highchartOptions",
          :pagination="paginationEnabled",
          :limit="component.limit",
          :drilldown="drilldownEnabled",
          :editing="editor",
          @drilldown="handleDrilldown",
          @pageChanged="handlePageChange",
          @redraw="handleRedraw",
          @updateLimit="updateLimit"
        )

    .panel-footer.show-more(
      v-if="hasDetails",
      :class="{ collapsed: !isDetailsOpen }",
      @click="$emit('toggleDetails', component)"
    )
      svg-icon.chevron.show-new-layout(:name="isDetailsOpen ? 'chevron-wide-up' : 'chevron-wide-down'")
      i.fa.fa-2x.hide-new-layout
  heatmap-settings-modal(
    ref="heatmapSettingsModal",
    :component="component",
    :filter-configuration="effectiveFilterConfiguration",
    @update="updateChartOptions($event)"
  )
</template>

<script lang="ts">
import Vue from "vue";
import zoinedSnippet from "../zoined-snippet/zoined-snippet.vue";
import zoinedChart from "../zoined-chart/zoined-chart.vue";
import trendBlock from "../dashboard/blocks/trend.vue";
import dashboardSnippet from "../dashboard/dashboard-snippet/dashboard-snippet.vue";
import * as _ from "lodash";
import {
  refreshFilterTimes,
  removeSpecialAndExtraFiltersFromFilterConfiguration,
  componentFilters,
} from "../lib/filter-util";
import I18n from "../i18n";
import TranslationService from "../core/translation.service";
import proportionsSelector from "../components/proportions-selector.vue";
import legends from "../components/legends.vue";
import tableColumnSelector from "../components/table-column-selector.vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import chartTypeSelector from "../components/chart-type-selector.vue";
import dataLabelsSelector from "../components/data-labels-selector.vue";
import roundValuesSelector from "../components/round-values-selector.vue";
import insightChart from "../charts/insight-chart.vue";
import heatmapSettingsModal from "../components/heatmap-settings-modal.vue";
import crossTabSettingsModal from "../components/cross-tab-settings-modal.vue";
import i18n from "../i18n";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import FilterConfigurationItem from "../model/filter-configuration-item";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";
import { asArray } from "../lib/array-utils";
import SvgIcon from "../components/svg-icon.vue";
import DrilldownDropdown from "../drilldown/drilldown-dropdown.vue";
import DrilldownProvider from "../drilldown/drilldown-provider.vue";
import Actions from "../store/actions";
import TrendSortSelector from "@/components/trend-sort-selector.vue";
import TrendDirectionSelector from "@/components/trend-direction-selector.vue";
import TrendChartVariantSelector from "@/components/trend-chart-variant-selector.vue";
import TrendInsightsChart from "@/charts/trend-insights-chart.vue";
import ExcelButton from "@/components/excel-button.vue";
import ComponentFilters from "@/analytics/component-filters.vue";

function metricFromComponentName(name) {
  if (name.startsWith("top_")) {
    return name.substring(4);
  }
  if (name.endsWith("_col") || name.endsWith("_bar")) {
    return name.substring(0, name.length - 4);
  }
  return name;
}

@Component({
  components: {
    zoinedSnippet,
    zoinedChart,
    trendBlock,
    dashboardSnippet,
    proportionsSelector,
    legends,
    tableColumnSelector,
    chartTypeSelector,
    dataLabelsSelector,
    roundValuesSelector,
    insightChart,
    heatmapSettingsModal,
    crossTabSettingsModal,
    SvgIcon,
    DrilldownDropdown,
    DrilldownProvider,
    TrendSortSelector,
    TrendDirectionSelector,
    TrendChartVariantSelector,
    TrendInsightsChart,
    ExcelButton,
    ComponentFilters,
  },
})
export default class ZoinedReportComponent extends Vue {
  @Prop()
  component: any;

  @Prop()
  filterConfiguration: FilterConfigurationItem;

  @Prop()
  chartOptions: ChartOptions;

  @Prop()
  tableConfig: TableConfig;

  @Prop()
  highchartOptions: any;

  @Prop()
  remove: Function;

  @Prop()
  duplicate: Function;

  @Prop()
  index: Number;

  @Prop()
  isDetailsOpen: Boolean;

  @Prop()
  editor: boolean;

  @Prop()
  preview: boolean;

  @Prop()
  showEditComponentModal: Function;

  @Prop()
  showFilterConfiguration: boolean;

  @Prop()
  custom: boolean;

  @Prop()
  dashboard: boolean;

  @Prop({ default: false })
  public: boolean;

  selectedItem = null;
  chartData = null;
  detailChartData = null;
  fullScreen = false;

  get chartType() {
    return this.effectiveChartOptions.inverted ? "bar" : "column";
  }

  get isNotification() {
    return this.component.name.includes("_notifications");
  }

  get componentClass() {
    return {
      dashboard_snippet: "is-metric-snippet",
      metric_chart: "is-metric-chart",
      snippet: "is-table",
      trend: "is-trend",
      chart: "is-chart",
      trend_insights: "is-chart",
    }[this.blockToUse];
  }

  get metricClass() {
    if (this.blockToUse == "dashboard_snippet") {
      const metric = this.effectiveFilters.metrics[0];
      return `metric-${metric}`;
    }
    return null;
  }

  get isIndex() {
    return this.effectiveFilters.metric_type == "index";
  }

  get blockToUse() {
    if (this.component.type == "snippet") {
      return "snippet";
    }
    if (this.component.name == "dashboard_snippet") {
      return "dashboard_snippet";
    }
    if (this.component.name == "metric_chart") {
      return "metric_chart";
    }
    if (this.component.name == "insight") {
      return "insight_chart";
    }
    if (this.component.name.indexOf("_trend") >= 0) {
      return "trend";
    }
    if (this.component.name == "trend_insights") {
      return "trend_insights";
    }
    return "chart";
  }

  get showRoundValuesSelector() {
    return this.component.name == "cross_table" || this.component.name == "cross_tab_custom";
  }

  get showTableColumnSelector() {
    return this.component.name == "cross_table" || this.component.name == "cross_tab_custom";
  }

  get showDataLabelsSelector() {
    const { name } = this.component;
    return (
      name == "metric_chart" ||
      name == "dual_axis" ||
      name == "dual_axis_with_weather" ||
      name == "heatmap" ||
      name == "line"
    );
  }

  get showProportionsSelector() {
    const { name } = this.component;
    return (
      name == "metric_chart" ||
      name == "dual_axis" ||
      name == "dual_axis_with_weather" ||
      name == "stacked" ||
      name == "heatmap" ||
      name == "line"
    );
  }

  get showChartTypeSelector() {
    return (
      this.component.name == "dual_axis" ||
      this.component.name == "dual_axis_with_weather" ||
      this.component.name == "stacked"
    );
  }

  get showHeatmapSettings() {
    return this.component.name == "heatmap";
  }

  get showTableSettings() {
    return this.component.name == "cross_tab_custom";
  }

  get showTrendSortSelector() {
    return this.component.name === "trend_insights";
  }

  get fullScreenEnabled() {
    return this.component.name == "cross_table" || this.component.name == "cross_tab_custom";
  }

  get hasDetails() {
    return (
      !this.preview &&
      !this.public &&
      (this.blockToUse == "dashboard_snippet" ||
        this.blockToUse == "metric_chart" ||
        (this.blockToUse == "snippet" && this.component.name.includes("_notifications")))
    );
  }

  get effectiveChartOptions() {
    return { ...this.chartOptions, ...this.component.chartOptions };
  }

  get effectiveFilters() {
    const { currency } = window.zoinedContext.currency;
    return {
      ...componentFilters(this.filterConfiguration, this.component),
      currency,
    };
  }

  get effectiveFilterConfiguration() {
    const filterConfiguration = {
      ...this.filterConfiguration,
      ...this.component.filterConfiguration,
    };

    return refreshFilterTimes(filterConfiguration);
  }

  get style() {
    const style: any = {};
    if (this.component.height != null) {
      style.height = this.component.height + (this.component.height == "auto" ? "" : "px");
    }
    return style;
  }

  get title() {
    if (this.component.custom_title) {
      return this.component.custom_title;
    } else if (this.component.show_title) {
      const metric_id = metricFromComponentName(this.component.name);
      const metric_label = this.metrics?.find((item) => menuItemKey(item) === metric_id)?.name;
      let title_key = (() => {
        if (this.component.title != null && this.component.title.match("\\.")) {
          return this.component.title;
        }
        if (this.component.title != null) {
          return `${this.component.type}.${this.component.name}.${this.component.title}`;
        }
        return `${this.component.type}.${this.component.name}.title`;
      })();
      return I18n.t(title_key, {
        metric: metric_label,
        defaultValue: metric_label,
      });
    } else {
      return this.metricTitle(this.effectiveFilters.metrics);
    }
  }

  get secondaryTitle() {
    if (!this.component.custom_title && !this.component.show_title) {
      return this.metricTitle(this.effectiveFilters.metrics2);
    }
  }

  metricTitle(metrics) {
    if (!metrics || metrics.length === 0) {
      return null;
    }

    const grouping = asArray(this.effectiveFilters.grouping);

    if (grouping.length > 0) {
      const metricLabel = metrics
        .map((metric) => {
          return this.metrics?.find((item) => menuItemKey(item) === metric)?.name || metric;
        })
        .join(", ");
      const groupingLabel = this.groupings?.find((item) => menuItemKey(item) === grouping[0])?.name;
      if (metricLabel && groupingLabel) {
        return I18n.t("filter.config.metric_by_grouping", { metric: metricLabel, grouping: groupingLabel });
      }
    }

    return this.metrics?.find((item) => menuItemKey(item) === metrics[0])?.name;
  }

  get tableColumnConfig() {
    const { show_percentual_change = false, show_absolute_change = false, show_index = false, proportions = false } = {
      ...this.defaultTableColumnConfig,
      ...this.component.filterConfiguration,
    };

    return {
      changePercent: !!show_percentual_change,
      absoluteChange: !!show_absolute_change,
      changeIndex: !!show_index,
      proportions: !!proportions,
    };
  }

  get defaultTableColumnConfig() {
    return {
      show_percentual_change: this.component.name == "cross_table",
      show_absolute_change: false,
      show_index: false,
      proportions: false,
    };
  }

  get paginationEnabled() {
    return this.component.pagination && !this.preview;
  }

  get drilldownEnabled() {
    return (
      (this.blockToUse === "chart" ||
        this.blockToUse === "trend" ||
        this.blockToUse === "metric_chart" ||
        this.blockToUse === "trend_insights") &&
      !this.component.static &&
      this.component.drilldown !== false &&
      !this.preview
    );
  }

  get showLegendSymbol() {
    return (
      this.component.name == "dual_axis" ||
      this.component.name == "dual_axis_with_weather" ||
      this.component.name == "dashboard_snippet" ||
      this.component.name == "metric_chart"
    );
  }

  get excelExportEnabled() {
    return window.zoinedContext.allowExport && this.component.name !== "dashboard_snippet";
  }

  get metrics(): MenuItem[] {
    return this.$store.getters.getParameters("metrics");
  }

  get groupings(): MenuItem[] {
    return this.$store.getters.getParameters("grouping");
  }

  get sort() {
    const sort = this.effectiveFilters.sort;
    if (_.isArray(sort)) {
      return sort[0];
    } else {
      return sort;
    }
  }

  get sortDirection() {
    return /^[\-=]/.test(this.sort) ? this.sort[0] : "";
  }

  get sortKey(): string {
    return /^[\-=+]/.test(this.sort) ? this.sort.substring(1) : this.sort;
  }

  get chartVariant() {
    return this.effectiveChartOptions.chart_variant || "value";
  }

  excelExport() {
    this.$emit("excel-export-component", this.component);
  }

  t(key) {
    return I18n.t(key);
  }

  @Emit("componentUpdated")
  tableColumnConfigUpdated(tableColumnConfig) {
    return {
      ...this.component,
      filterConfiguration: {
        ...this.component.filterConfiguration,
        proportions: !!tableColumnConfig.proportions,
        show_absolute_change: !!tableColumnConfig.absoluteChange,
        show_index: !!tableColumnConfig.changeIndex,
        show_percentual_change: !!tableColumnConfig.changePercent,
      },
    };
  }

  @Emit("componentUpdated")
  updateDataLabels(data_labels) {
    return {
      ...this.component,
      chartOptions: {
        ...this.component.chartOptions,
        data_labels,
      },
    };
  }

  @Emit("componentUpdated")
  updateProportions(proportions) {
    return {
      ...this.component,
      filterConfiguration: {
        ...this.component.filterConfiguration,
        proportions,
      },
    };
  }

  @Emit("componentUpdated")
  updateRoundValues(round_values) {
    return {
      ...this.component,
      filterConfiguration: {
        ...this.component.filterConfiguration,
        round_values,
      },
    };
  }

  @Emit("componentUpdated")
  updateChartType(type) {
    const inverted = type === "bar";

    return {
      ...this.component,
      chartOptions: {
        ...this.component.chartOptions,
        inverted,
      },
    };
  }

  @Emit("componentUpdated")
  updateChartOptions(chartOptions) {
    return {
      ...this.component,
      chartOptions,
    };
  }

  @Emit("componentUpdated")
  updateTableConfig(tableConfig) {
    return {
      ...this.component,
      tableConfig,
    };
  }

  updateLimit(limit) {
    if (this.dashboard) {
      this.$emit("componentUpdated", {
        ...this.component,
        filterConfiguration: {
          ...this.component.filterConfiguration,
          limit,
        },
      });
    } else {
      this.$emit("filter-configuration-updated", {
        ...this.filterConfiguration,
        limit,
      });
    }
  }

  @Emit("filter-configuration-updated")
  updateFilterConfiguration(filterConfiguration) {
    return {
      ...this.filterConfiguration,
      ...filterConfiguration,
    };
  }

  @Watch("fullScreen")
  onFullScreenChanged(fullScreen) {
    if (fullScreen) {
      document.body.classList.add("full-screen");
      this.updateFullscreen();
    } else {
      document.body.classList.remove("full-screen");
      this.cleanupFullscreen();
    }
  }

  onSnippetData(data) {
    this.$emit("data", data);
    if (this.fullScreen) {
      this.updateFullscreen();
    }
  }

  updateFullscreen() {
    setTimeout(() => {
      // set sticky header positions
      document.querySelectorAll(".full-screen .sticky-left").forEach((element: HTMLElement) => {
        const newLocal = element.getBoundingClientRect();
        const { x } = newLocal;
        element.style.left = `${x}px`;
      });
      document.querySelectorAll(".full-screen .sticky-top").forEach((element: HTMLElement) => {
        const { y } = element.getBoundingClientRect();
        element.style.top = `${y}px`;
      });

      // update fixed control positions so that they don't overlap with scrollbars
      const panel = window.$(".full-screen .panel");
      const panelBody = window.$(".full-screen .panel .panel-body");
      const scrollBarWidth = panel.width() - panelBody.width();
      window.$(".full-screen .panel .panel-heading").css({
        right: `${scrollBarWidth}px`,
      });
      window.$(".full-screen .pagination-control").css({
        right: `${scrollBarWidth}px`,
        bottom: `${scrollBarWidth}px`,
      });
    }, 200);
  }

  cleanupFullscreen() {
    document.querySelectorAll(".sticky-left").forEach((element: HTMLElement) => {
      element.style.removeProperty("left");
    });
    document.querySelectorAll(".sticky-top").forEach((element: HTMLElement) => {
      element.style.removeProperty("top");
    });
  }

  handleRedraw(chart) {
    const { series } = chart;
    const highchartOptions = {
      series: series.map(({ visible }) => ({ visible })),
    };
    if (this.dashboard) {
      this.$emit("componentUpdated", {
        ...this.component,
        highchartOptions,
      });
    } else {
      this.$emit("highchart-options-updated", highchartOptions);
    }
  }

  handleDrilldown(filters) {
    this.$emit("drilldown", filters);
  }
  handlePageChange(info) {
    this.$emit("pageChanged", info);
  }

  onSortDirectionChange(sortDirection) {
    this.onSortChange(sortDirection + this.sortKey);
  }

  onSortKeyChange(sortKey) {
    this.onSortChange(this.sortDirection + sortKey);
  }

  onSortChange(sort) {
    this.$emit("componentUpdated", {
      ...this.component,
      filterConfiguration: {
        ...this.component.filterConfiguration,
        sort: {
          [sort]: { enabled: true },
        },
      },
    });
  }

  onChartVariantChange(chartVariant) {
    this.$emit("componentUpdated", {
      ...this.component,
      chartOptions: {
        ...this.component.chartOptions,
        chart_variant: chartVariant,
      },
    });
  }
}
</script>

<style scoped lang="scss">
.panel-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;

  > * {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.toolbar {
  flex-grow: 1;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .toolbar {
    flex-grow: 0;
    justify-content: flex-end;
  }
  .panel-title {
    flex-direction: row;
    font-size: 14px;
  }
}

.span-3,
.span-4,
.span-6 {
  .panel-title {
    flex-direction: column;
    gap: 5px;
  }

  .toolbar {
    flex-grow: 1;
    justify-content: space-between;
  }
}

h3 {
  .pull-right {
    .remove {
      i {
        &:hover {
          color: #81e4ff;
          cursor: pointer;
        }
      }
    }
  }
}

.handle {
  &:hover {
    cursor: move;
  }
}

.remove {
  i {
    margin: 0 2px;
  }
}

.report-block {
  @media screen {
    &.full-screen {
      .panel-snippet {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0;
        z-index: 9999;
        overflow: auto;

        .panel-heading {
          position: fixed;
          top: 0;
          right: 0;
          padding: 5px 10px;
          background-color: rgba(0, 0, 0, 0.8);
          color: white;
          z-index: 10005;
          border-radius: 0px;

          .panel-title {
            display: none;
          }
        }

        .panel-body {
          padding: 0;
          min-height: 100vh;
        }
      }
    }
  }

  &.detail-open {
    .panel-snippet {
      height: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.panel-snippet {
  display: block; // was "flex"
  flex-direction: column;
  margin: 0;

  .panel-body {
    flex: 1;
  }
}

.is-table {
  .panel-snippet {
    display: block;
  }
}

.filter-configuration {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .filters {
    font-size: 11px;
  }
}

svg.chevron {
  width: 24px;
  height: auto;
}
</style>
<style lang="scss">
@media screen {
  .report-block.full-screen {
    .pagination-control {
      position: fixed;
      right: 0;
      bottom: 0;
      height: 34px;
      z-index: 10000;

      .pagination {
        max-height: none;

        a {
          background-color: rgba(0, 0, 0, 0.8);
          border: none;
          border-radius: 0px;
          max-height: none;
          font-size: 16px;
          font-weight: bold;
          color: white;
        }

        input {
          background-color: rgba(0, 0, 0, 0);
        }

        .disabled a {
          color: #999;
        }
      }

      .limit-selector {
        margin-left: 0;
        border-left: 5px solid black;

        input.form-control,
        button.btn {
          height: 34px;
          background-color: rgba(0, 0, 0, 0.8);
          border: none;
          border-radius: 0px;
          font-size: 16px;
          font-weight: bold;
          color: white;
        }
      }
    }

    .panel-snippet .panel-body .snippet {
      // Unset overflow settings to allow sticky position to work
      overflow: unset;

      .content {
        overflow: unset;
      }

      table {
        border-collapse: separate;
        border-spacing: 0px;
        margin: 0;

        thead {
          th.sticky-top {
            position: sticky;
            background-color: white;
            z-index: 10001;

            &.sticky-left {
              z-index: 10002;
            }
          }
        }

        tbody {
          td.sticky-left {
            position: sticky;
            background-color: white;
            z-index: 10000;
          }
        }
      }
    }
  }
}

body.full-screen {
  overflow: hidden;

  div#hbl-live-chat-wrapper {
    display: none !important;
  }

  .full-screen-hidden {
    display: none;
  }
}
</style>
