<template>
  <ui-button-multi-toggle
    :value="value"
    :options="options"
    button-class="btn-sm nl-btn-xs"
    @input="$emit('input', $event)"
  />
</template>

<script lang="ts" setup>
import { useI18n } from "@/composables/i18n";
import UiButtonMultiToggle, { UiButtonMultiToggleOption } from "@/ui/ui-button-multi-toggle.vue";

defineProps({
  value: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const { t } = useI18n();

const options: UiButtonMultiToggleOption[] = [
  { value: false, label: t("dashboard.show_values.values") },
  { value: true, label: t("dashboard.show_values.percentage") },
];
</script>
