import VueFilters from "./lib/vue/filters";
import Vue from "vue";
import * as uiv from "uiv";
import Vuex from "vuex";
import Vuelidate from "vuelidate";
import VueRouter from "vue-router";
import Component from "vue-class-component";

// Register base components globally
import "./base-components";
import { handleError } from "./core/error-handling";

Vue.use(VueFilters);
Vue.use(uiv, { prefix: "uiv" });
Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(VueRouter);

Vue.config.errorHandler = handleError;

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);
