<template lang="pug">
.legends(:class="`span-${span}`")
  .selection-section(v-if="selection")
    .flex-row.align-items-center.legend-item
      i.symbol(:class="selectionClass(selection)")
      span.daterange {{ dateRange(selection) }}:
      span.description {{ selectionTitle(selection) }} 
      span.detail(v-if="selection.type=='today_until_last_full_hour'") (0:00 - {{ lastFullHour }}:00)
      span.detail(v-if="selection.type=='last_full_hour'") ({{ secondLastFullHour }}:00 - {{ lastFullHour }}:00)
  .comparisons-section(v-if="comparisons && comparisons.length > 0")
    .flex-row.align-items-center.legend-item(v-show='comparisonShow(comparison)', v-for="comparison in comparisons")
      i.symbol(:class='comparisonClass(comparison)' :style="{ filter:  comparisonFilter(comparison)}")
      span.daterange(v-show='comparison.start_time') {{ dateRange(comparison) }}:
      span.description {{ comparisonTitle(comparison) }}
</template>

<script>
import TranslationService from "../core/translation.service";
import daterangeFilter from "../core/daterange.filter";
import moment from "moment";

const dateRange = daterangeFilter();
const translationService = new TranslationService();

export default {
  props: {
    selection: {
      type: Object,
      required: false,
    },
    comparisons: {
      type: Array,
      required: false,
    },
    showSymbol: {
      type: Boolean,
      default: true,
    },
    span: {
      type: Number,
      default: 12,
    },
  },
  computed: {
    lastFullHour() {
      let time = moment();
      if (window.zoinedContext.companyTimeZone) {
        time = time.tz(window.zoinedContext.companyTimeZone);
      }
      return time.format("HH");
    },
    secondLastFullHour() {
      let time = moment().subtract(1, "hour");
      if (window.zoinedContext.companyTimeZone) {
        time = time.tz(window.zoinedContext.companyTimeZone);
      }
      return time.format("HH");
    },
  },
  methods: {
    dateRange,

    selectionTitle(selection) {
      return translationService.selectionTitle(selection);
    },

    selectionClass(selection) {
      const { series } = selection;
      const type = !series || series == "actual" ? "selection" : `budget-${series}`;
      return `bg-${type}`;
    },

    comparisonTitle(comparison) {
      return translationService.comparisonTitle(comparison);
    },

    comparisonClass(comparison) {
      return "bg-" + [comparison.type, comparison.label].filter(Boolean).join("-");
    },

    comparisonShow(comparison) {
      return comparison.type && comparison.type != "no_comparison" && comparison.enabled;
    },

    comparisonFilter(comparison) {
      if (comparison.scaling_modifier) {
        const percent = Math.min(Math.max((Math.round(comparison.scaling_modifier * 100) - 100) / 2 + 100, 90), 110);
        return `brightness(${percent}%)`;
      }
    },
  },
};
</script>

<style lang="scss">
html.new-layout {
  .legends {
    background-color: var(--color-surface-neutral-1);
    color: var(--color-text-primary);
    padding: 5px 0;

    font-weight: 500;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 13px;
    }

    .legend-item {
      margin-top: 0;
      margin-bottom: 0;

      overflow: hidden;

      > *:last-child {
        flex-shrink: 1;
        /* Allow the child to shrink if necessary */
        white-space: nowrap;
        /* Prevent text from wrapping to the next line */
        overflow: hidden;
        /* Hide the overflowing text */
        text-overflow: ellipsis;
        /* Add ellipsis to the overflowing text */
        min-width: 0;
        /* Necessary for some browsers to correctly apply ellipsis */
      }
    }

    .comparisons-section {
      .legend-item {
        margin-top: 5px;
      }
    }

    i.symbol {
      width: 2px;
      height: 18px;
      margin-right: 5px;
      border: none;
    }

    &.small {
      font-size: 14px;

      @media (min-width: 768px) {
        font-size: 13px;
      }

      .legend-item {
        padding-right: 20px;
        margin-bottom: 0;
      }
    }

    .daterange {
      padding-right: 5px;
    }
  }
}
</style>

<style lang="scss" scoped>
$dashboard-legend: lighten(#000, 20%) !default; // #333

.legends {
  display: inline-block;
  text-align: left;
  color: $dashboard-legend;
  font-weight: 300;
  border-radius: 5px;
  max-width: 100%;
  white-space: nowrap;

  .legend-item {
    margin-top: 5px;
  }

  i.symbol {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    cursor: auto;
    border: 1px solid #ccc;
  }

  &.small {
    font-size: 11px;

    i {
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }

    .legend-item {
      margin-bottom: 1px;
    }
  }

  .daterange {
    padding-right: 10px;
  }

  &.span-3 {
    @media (min-width: 768px) {
      .daterange {
        display: none;
      }
    }
  }

  &.span-4 {
    @media (min-width: 768px) and (max-width: 991px) {
      .daterange {
        display: none;
      }
    }
  }
}
</style>
