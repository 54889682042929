<template lang="pug">
.report-actions(v-if="report && !report.custom")
  save-as-button.hide-new-layout
  save-report-button.show-new-layout(v-if="myReport.id && myReport.editable")
  edit-report-button.show-new-layout(v-if="myReport.id && myReport.editable")
  duplicate-report-button.show-new-layout(v-if="myReport.duplicatable")
  share-button(v-if="isAdmin", :report="report", :my-report="myReport")
  delete-report-button(v-if="myReport.id && myReport.removable")

.report-actions(v-else-if="report && report.custom")
  save-report-button(v-if="myReport.editable")
  edit-report-button(v-if="myReport.editable")
  duplicate-report-button(v-if="myReport.duplicatable")
  favorite-report-button(v-if="myReport.dashboard")
  share-button(v-if="isAdmin", :report="report", :my-report="myReport")
  delete-report-button(v-if="myReport.removable")

.report-actions(v-else-if="reportType == 'system_dashboard'")
  favorite-report-button(v-if="!company.licenses.limited")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import ShareButton from "./share/share-button.vue";
import ConfirmButton from "../components/confirm-button.vue";
import ReportContext from "../model/report-context";
import Report from "../model/report";
import MyReport from "../model/my-report";
import SaveReportButton from "./save-report-button.vue";
import FavoriteReportButton from "./favorite-report-button.vue";
import SaveAsButton from "./save-as-button.vue";
import EditReportButton from "./edit-report-button.vue";
import DuplicateReportButton from "./duplicate-report-button.vue";
import DeleteReportButton from "./delete-report-button.vue";

@Component({
  components: {
    ShareButton,
    ConfirmButton,
    SaveReportButton,
    SaveAsButton,
    FavoriteReportButton,
    EditReportButton,
    DuplicateReportButton,
    DeleteReportButton,
  },
})
export default class ReportActions extends Vue {
  get reportContext(): ReportContext {
    return this.$store.getters.getReportContext;
  }

  get reportType() {
    return this.reportContext?.report_type;
  }

  get report(): Report {
    return this.reportContext?.report;
  }

  get myReport(): MyReport {
    return this.reportContext?.my_report;
  }

  get zoinedContext() {
    return window.zoinedContext;
  }

  get isAdmin() {
    return this.zoinedContext.isAdmin;
  }

  get company() {
    return this.zoinedContext.company;
  }
}
</script>

<style lang="scss" scoped>
.report-actions {
  display: flex;
  align-items: flex-end;

  > *:not(:last-child) {
    margin-right: 5px;
  }
}
</style>

<style lang="scss">
html.new-layout {
  .btn.btn-sm.report-action-button {
    height: 35px;

    &.btn-icon {
      width: 35px;
    }
  }
}
</style>
