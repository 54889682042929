<template lang="pug">
.share-button-container
  uiv-dropdown.share-button-dropdown
    button.share-button.btn.btn-default.btn-dark-new-layout.btn-sm.btn-icon.report-action-button.dropdown-toggle(
      type="button",
      v-uiv-tooltip.hover="tooltip"
    )
      svg-icon(name="share")
    template(#dropdown)
      .share-form(v-if="!link")
        spinner
      .share-form(v-else, @click.stop)
        b {{ "public_share.title" | i18n }}
        .mt-md
          .input-group
            span.input-group-btn
              confirm-button.btn.btn-default.btn-dark-new-layout(
                :title="'public_share_regenerate_link' | i18n",
                tag="button",
                :popover-message="'public_share.regenerate_link_confirm_message' | i18n",
                @confirm="regenerateLink()"
              )
                i.fa.fa-refresh
            input.form-control.link-input(ref="textInput", type="text", :value="shareableLink", readonly)
            span.input-group-btn
              button.btn.btn-default.btn-dark-new-layout(@click="copyLink()") {{ "actions.copy" | i18n }}
        .mt-md.flex-row.justify-content-space-between.align-items-center
          label.refresh
            input(type="checkbox", v-model="refresh")
            | {{ "public_share.refresh_automatically" | i18n }}
          dropdown-select(:value="language", :available-items="languageOptions", @update="onLanguageChange")
        .mt-md
          a.cursor-pointer(@click="openFilteredLinksModal()") {{ "public_share.generate_filtered_links" | i18n }}
  filtered-links-modal(ref="filteredLinksModal", :report="report", :my-report="myReport")
</template>

<script lang="ts">
import MyReport from "../../model/my-report";
import Report from "../../model/report";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import ConfirmButton from "../confirm-button.vue";
import Spinner from "../spinner.vue";
import toastr from "toastr";
import i18n from "../../i18n";
import SvgIcon from "../svg-icon.vue";
import DropdownSelect from "../dropdown-select.vue";
import { makeApiInstance } from "../../api/instance";
import FilteredLinksModal from "./filtered-links-modal.vue";

@Component({
  components: {
    ConfirmButton,
    Spinner,
    SvgIcon,
    DropdownSelect,
    FilteredLinksModal,
  },
})
export default class ShareButton extends Vue {
  @Ref("filteredLinksModal")
  filteredLinksModal: FilteredLinksModal;

  @Ref("textInput")
  textInput: HTMLInputElement;

  @Prop()
  report: Report;

  @Prop()
  myReport: MyReport;

  refresh = true;
  link = null;
  language = "";

  get shareableLink() {
    return (
      this.link +
      (this.refresh ? "?refresh=1" : "") +
      (this.language && this.language != "" ? `&locale=${this.language}` : "")
    );
  }

  get tooltip() {
    return i18n.t("actions.share");
  }

  get languageOptions() {
    return [
      { id: "", name: i18n.t("activerecord.attributes.company.company_language") },
      ...window.zoinedContext.available_languages.map(({ code, name }) => ({
        id: code,
        name,
      })),
    ];
  }

  onLanguageChange(language) {
    this.language = language;
  }

  regenerateLink() {
    this.link = null;
    this.fetchLink(true);
  }

  copyLink() {
    navigator.clipboard.writeText(this.textInput.value);
    toastr.success(i18n.t("public_share.link_copied_to_clipboard"));
  }

  created() {
    this.fetchLink();
  }

  fetchLink(regenerate = false) {
    const url = `/api/v1/reports/${this.report.id}/public_link`;
    const params: any = {};
    if (this.myReport?.id) {
      params.my_report_id = this.myReport.id;
    }
    if (regenerate) {
      params.regenerate = "1";
    }
    makeApiInstance()
      .get(url, { params })
      .then((response) => response.data)
      .then(({ link }) => (this.link = link));
  }

  openFilteredLinksModal() {
    this.filteredLinksModal.show();
  }
}
</script>

<style lang="scss" scoped>
.share-button {
  outline: 0px !important;
  svg {
    width: 18px;
  }
}
.share-form {
  min-height: 50px;
  padding: 10px 20px;

  input.link-input {
    font-size: 12px;
    max-width: 100%;
  }
}
label.refresh {
  display: flex;
  margin-bottom: 0;
  input {
    margin-top: 0;
    margin-right: 5px;
  }
  font-weight: normal;
}
</style>
<style lang="scss">
html.new-layout {
  .share-button {
    svg {
      color: white;
    }
  }
}
html:not(.new-layout) {
  .share-button-dropdown {
    // on old layout open menu to right
    .dropdown-menu {
      transform: translateX(calc(-100% + 34px));
    }
  }
}
@media (min-width: 768px) {
  .share-form {
    width: 450px;
  }
}
@media (max-width: 767px) {
  .share-form {
    width: 100%;
  }
  .share-button-dropdown {
    .dropdown-menu {
      position: fixed;
      top: 50%;
      margin: 0 10px;
      transform: translateY(-50%);
    }
  }
}
</style>
