<template lang="pug">
span
  dropdown(:open="open")
    template(slot="toggle")
      .btn-group
        button.btn.btn-primary.mr-md(type="primary" @click="open=!open" @close="open=false") 
          span.mr-md {{ 'newsletter.definition.deliver_all' | i18n }}
          span.caret
    template(slot="menu")
      li(v-for="frequency in frequencies")
        confirm-button(@confirm="massSendByFrequency(frequency)" tag="a" :popover-message="confirmMessage(frequency)")
          span {{ 'newsletter.definition.send_now' | i18n }}: {{ 'newsletter.frequency.' + frequency | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import dropdown from "../components/dropdown.vue";
import Component from "vue-class-component";
import ConfirmButton from "../components/confirm-button.vue";
import i18n from "../i18n";
import newsletterDefinitionsApi from "../api/admin-newsletter-definitions-api";
import toastr from "toastr";

@Component({
  components: {
    dropdown,
    ConfirmButton,
  },
})
export default class MassSendNewslettersButton extends Vue {
  open = false;

  get frequencies() {
    return ["daily", "weekly", "monthly", "quarterly", "yearly"];
  }

  get companyId() {
    return window.zoinedContext.companyId;
  }

  massSendByFrequency(frequency) {
    this.open = false;
    newsletterDefinitionsApi.deliver({ company_id: this.companyId, id: frequency }).then(({ delivery_count }) => {
      toastr.success(i18n.t("newsletter.definition.delivered_successfully_message", { delivery_count }));
    });
  }

  confirmMessage(frequencyId) {
    const frequency = i18n.t("newsletter.frequency." + frequencyId).toLowerCase();
    return i18n.t("newsletter.definition.deliver_all_confirmation_message", { frequency });
  }
}
</script>
