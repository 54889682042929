import { render, staticRenderFns } from "./cross-tab-settings-modal.vue?vue&type=template&id=5dd5d0f6&scoped=true&lang=pug"
import script from "./cross-tab-settings-modal.vue?vue&type=script&lang=ts"
export * from "./cross-tab-settings-modal.vue?vue&type=script&lang=ts"
import style0 from "./cross-tab-settings-modal.vue?vue&type=style&index=0&id=5dd5d0f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd5d0f6",
  null
  
)

export default component.exports