import { render, staticRenderFns } from "./filterset-selector.vue?vue&type=template&id=2cdb629c&scoped=true&lang=pug"
import script from "./filterset-selector.vue?vue&type=script&lang=ts"
export * from "./filterset-selector.vue?vue&type=script&lang=ts"
import style0 from "./filterset-selector.vue?vue&type=style&index=0&id=2cdb629c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cdb629c",
  null
  
)

export default component.exports