<template lang="pug">
.sidenav-category
  sidenav-category-title(:title="category.name" :open="open" :manage-folders-enabled="manageFoldersEnabled" @manage-folders="manageReportsModal.show()" @click.native="open = !open")
  .folders(:class="{hidden: !open}")
    sidenav-folder(v-bind="item" v-for="item in category.folders" :key="item.name")
  manage-reports-modal(ref="manageReportsModal", :navigation-item="category")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import SidenavFolder from "./sidenav-folder.vue";
import SidenavCategoryTitle from "./sidenav-category-title.vue";
import ManageReportsModal from "../manage-reports/manage-reports-modal.vue";

@Component({
  components: { SidenavCategoryTitle, SidenavFolder, ManageReportsModal },
})
export default class SidenavCategory extends Vue {
  @Ref()
  manageReportsModal: ManageReportsModal;

  @Prop()
  category: any;

  @Prop()
  forceOpen: boolean;

  open = false;

  get isActive() {
    return this.category.folders.some((folder) => folder.reports.some((report) => report.path === location.pathname));
  }

  get manageFoldersEnabled() {
    return (
      this.category.type === "my_analytics" ||
      (this.category.type === "company_analytics" && this.isAdmin) ||
      (this.category.type === "team_analytics" && (this.isAdmin || this.isTeamAdmin(this.category.team_id)))
    );
  }

  get isAdmin() {
    return window.zoinedContext.isAdmin;
  }

  get teamsMembershipsAsAdmin(): any[] {
    return window.zoinedContext.current_role.team_memberships.filter(({ role }) => role === "admin");
  }

  isTeamAdmin(teamId) {
    return this.teamsMembershipsAsAdmin.some((teamMembership) => teamMembership.team_id === teamId);
  }

  created() {
    this.open = this.forceOpen || this.isActive;
  }
}
</script>

<style lang="scss" scoped>
.sidenav-category {
  margin-bottom: 30px;

  .folders {
    padding-left: 15px;
  }
}
</style>