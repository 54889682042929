<template lang="pug">
.newsletter-templates-list
  .pull-right
    dropdown(:open="addMenuOpen")
      template(slot="toggle")
        .btn-group
          button.btn.btn-primary(type="primary" @click="newNewsletterTemplate()") {{ 'newsletter_templates.new_newsletter_template' | i18n }}
          button.btn.btn-primary(type="primary" @click="addMenuOpen=!addMenuOpen" @close="addMenuOpen=false")
            span.caret
      template(slot="menu")
        li
          a(@click="newNewsletterTemplate()") {{ 'newsletter_templates.empty_template' | i18n }}
          a(@click="newTemplateFromNewsletter()") {{ 'newsletter_templates.from_newsletter' | i18n }}
  h3.hide-new-layout {{ 'newsletter_templates.title' | i18n }}
  .filters
    span.badge(v-if="partnerFilter")
      | {{ partnerFilter.name }} 
      i.fa.fa-times(@click="partnerFilter = null")
  table.table.table-hover
    thead
      tr
        th {{ 'attributes.name' | i18n }}
        th {{ 'attributes.visibility' | i18n }}
        th
    tbody
      tr(v-for="template in filteredTemplates")
        td {{ template.title }}
        td
          a.site(v-for="site in visibleSites(template)" @click.stop="setFilter(site)") {{ site.name }}
        td.actions
          .action
           a(@click="editTemplate(template)") {{ 'actions.edit' | i18n }}
          .action
            a(@click="duplicateTemplate(template)") {{ 'actions.duplicate' | i18n }}
          .action
            confirm-button(@confirm="deleteTemplate(template)")
              a {{ 'actions.delete' | i18n }}
  newsletter-template-from-newsletter-modal(ref="newsletterTemplateFromNewsletterModal" @select="onSelectNewsletter")
</template>

<script lang="ts">
import { makeApiInstance } from "../../api/instance";
import Vue from "vue";
import Component from "vue-class-component";
import Partner from "../../model/partner";
import NewsletterTemplate from "../../model/newsletter-template";
import confirmButton from "../../components/confirm-button.vue";
import { Ref } from "vue-property-decorator";
import NewsletterTemplateFromNewsletterModal from "./newsletter-template-from-newsletter-modal.vue";
import dropdown from "../../components/dropdown.vue";

@Component({
  components: { confirmButton, dropdown, NewsletterTemplateFromNewsletterModal },
})
export default class NewsletterTemplatesList extends Vue {
  @Ref("newsletterTemplateFromNewsletterModal")
  newsletterTemplateFromNewsletterModal: NewsletterTemplateFromNewsletterModal;

  newsletterTemplates: NewsletterTemplate[] = null;

  addMenuOpen = false;

  partnerFilter: Partner = null;

  get api() {
    return makeApiInstance({ baseURL: "/api/zoined_admin/newsletter_templates" });
  }

  get filteredTemplates(): NewsletterTemplate[] {
    if (!this.partnerFilter) {
      return this.newsletterTemplates;
    }

    return this.newsletterTemplates.filter((template) =>
      this.partnerFilter.id === "zoined"
        ? template.visible_in_zoined
        : template.partners.some(({ id }) => id === this.partnerFilter.id)
    );
  }

  setFilter(filter: Partner) {
    this.partnerFilter = filter;
  }

  visibleSites(template: NewsletterTemplate): Partner[] {
    const sites = [];
    if (template.visible_in_zoined) {
      sites.push({ id: "zoined", name: "Zoined" });
    }
    template.partners.forEach(({ id, name }) => sites.push({ id, name }));
    return sites;
  }

  newNewsletterTemplate() {
    this.$router.push({ name: "new_newsletter_template" });
  }

  newTemplateFromNewsletter() {
    this.addMenuOpen = false;
    this.newsletterTemplateFromNewsletterModal.show();
  }

  onSelectNewsletter(id) {
    this.$router.push({ name: "new_newsletter_template", query: { definitionId: id } });
  }

  editTemplate(template: NewsletterTemplate) {
    this.$router.push({ name: "edit_newsletter_template", params: { id: template.id.toString() } });
  }

  duplicateTemplate(template: NewsletterTemplate) {
    this.$router.push({ name: "new_newsletter_template", query: { templateId: template.id.toString() } });
  }

  async deleteTemplate(template: NewsletterTemplate) {
    await this.api.delete(template.id.toString());
    this.newsletterTemplates = this.newsletterTemplates.filter((it) => it !== template);
  }

  created() {
    this.fetchNewsletterTemplates();
  }

  async fetchNewsletterTemplates() {
    const result = await this.api.get("");
    this.newsletterTemplates = result.data;
  }
}
</script>

<style lang="scss" scoped>
a.site {
  cursor: pointer;
  margin-right: 5px;
}

.actions {
  .action {
    display: inline-block;
    margin: 0 10px;
    a {
      cursor: pointer;
    }
    a:not(:hover) {
      color: gray;
    }
  }
}
</style>
