<template lang="pug">
dropdown.dropdown-select(:open="menuOpen", @close="menuOpen = false")
  template(slot="toggle")
    slot(name="toggle" v-bind:menuOpen="menuOpen" v-bind:toggleMenu="toggleMenu")
      dropdown-select-button(:class="btnClasses" :button-style="buttonStyle" :menu-open="menuOpen" @click="toggleMenu") {{ label }}
  template(slot="menu")
    .simple-spinner(v-if="!availableItems")
      .spinner
    .dropdown-items
      .dropdown-item(@click="addItem(item)", v-for="item in availableItems")
        i.fa.mr-sm(v-if="item.icon" :class="item.icon")
        | {{ item.name }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Dropdown from "./dropdown.vue";
import DropdownSelectButton from "./dropdown-select-button.vue";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import _ from "lodash";

@Component({
  components: {
    Dropdown,
    DropdownSelectButton,
  },
})
export default class DropdownSelect extends Vue {
  @Prop()
  availableItems: MenuItem[];

  @Prop()
  default: any;

  @Prop()
  value: any;

  @Prop()
  selectLabel: string;

  @Prop({ default: "default" })
  buttonStyle: string;

  menuOpen = false;

  get btnClasses() {
    return {
      default: this.default && !this.value,
    };
  }

  get label() {
    if (this.value !== null && this.value !== undefined) {
      return this.getLabel(this.value);
    } else if (this.default) {
      return this.getLabel(this.default);
    } else if (this.selectLabel) {
      return this.selectLabel;
    } else {
      return "Select";
    }
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  addItem(item: MenuItem) {
    this.$emit("update", menuItemKey(item));
    this.menuOpen = false;
  }

  getLabel(key) {
    const item = _.find(this.availableItems, (item) => menuItemKey(item) === key);
    if (item) {
      return item.name;
    } else {
      return key;
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-select {
  .btn.default {
    opacity: 0.5;
  }
  .dropdown-items {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;

    .dropdown-item {
      padding: 7px 10px;
      border-radius: 5px;
      color: #333;
      white-space: nowrap;
      margin: 4px;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-top: 0;
      }

      &:hover {
        background-color: #cceeff;
        cursor: pointer;
      }
    }
  }
}
</style>
