<template lang="pug">
.report-templates-list
  .pull-right
    dropdown(:open="addMenuOpen")
      template(slot="toggle")
        .btn-group
          button.btn.btn-primary(type="primary" @click="newReportTemplate()") {{ 'report_templates.new_report_template' | i18n }}
          button.btn.btn-primary(type="primary" @click="addMenuOpen=!addMenuOpen" @close="addMenuOpen=false")
            span.caret
      template(slot="menu")
        li
          a(@click="newReportTemplate()") {{ 'dashboard_templates.empty_template' | i18n }}
          a(@click="newTemplateFromReport()") {{ 'report_templates.from_report' | i18n }}
  h3.hide-new-layout {{ 'report_templates.title' | i18n }}
  .filters
    span.badge(v-if="partnerFilter")
      | {{ partnerFilter.name }} 
      i.fa.fa-times(@click="partnerFilter = null")
  table.table.table-hover
    thead
      tr
        th {{ 'attributes.name' | i18n }}
        th {{ 'attributes.visibility' | i18n }}
        th
    tbody
      tr(v-for="template in filteredTemplates")
        td {{ template.name }}
        td
          a.site(v-for="site in visibleSites(template)" @click.stop="setFilter(site)") {{ site.name }}
        td.actions
          .action
            a(@click="editTemplate(template)") {{ 'actions.edit' | i18n }}
          .action
            a(@click.stop="duplicateTemplate(template)") {{ 'actions.duplicate' | i18n }}
          .action
            confirm-button(@confirm="deleteTemplate(template)")
              a {{ 'actions.delete' | i18n }}
  report-template-from-report-modal(ref="reportTemplateFromReportModal" @select="onSelectReport")
</template>

<script lang="ts">
import { makeApiInstance } from "../../api/instance";
import AnalyticsTemplate from "../../model/analytics-template";
import ReportTemplateFromReportModal from "./report-template-from-report-modal.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";
import dropdown from "../../components/dropdown.vue";
import Partner from "../../model/partner";
import confirmButton from "../../components/confirm-button.vue";

@Component({
  components: {
    ReportTemplateFromReportModal,
    dropdown,
    confirmButton,
  },
})
export default class ReportTemplatesList extends Vue {
  @Ref("reportTemplateFromReportModal")
  reportTemplateFromReportModal: ReportTemplateFromReportModal;

  reportTemplates: AnalyticsTemplate[] = null;

  addMenuOpen = false;

  partnerFilter: Partner = null;

  get api() {
    return makeApiInstance({ baseURL: "/api/zoined_admin/analytics_templates/" });
  }

  get filteredTemplates(): AnalyticsTemplate[] {
    if (!this.partnerFilter) {
      return this.reportTemplates;
    }

    return this.reportTemplates.filter((template) =>
      this.partnerFilter.id === "zoined"
        ? template.visible_in_zoined
        : template.partners.some(({ id }) => id === this.partnerFilter.id)
    );
  }
  setFilter(filter: Partner) {
    this.partnerFilter = filter;
  }

  visibleSites(template: AnalyticsTemplate): Partner[] {
    const sites = [];
    if (template.visible_in_zoined) {
      sites.push({ id: "zoined", name: "Zoined" });
    }
    template.partners.forEach(({ id, name }) => sites.push({ id, name }));
    return sites;
  }

  newReportTemplate() {
    this.$router.push({ name: "new_report_template" });
  }

  newTemplateFromReport() {
    this.addMenuOpen = false;
    this.reportTemplateFromReportModal.show();
  }

  onSelectReport(id) {
    this.$router.push({ name: "new_report_template", query: { reportId: id } });
  }

  editTemplate(template: AnalyticsTemplate) {
    this.$router.push({ name: "edit_report_template", params: { id: template.id.toString() } });
  }

  duplicateTemplate(template: AnalyticsTemplate) {
    this.$router.push({ name: "new_report_template", query: { templateId: template.id.toString() } });
  }

  async deleteTemplate(template: AnalyticsTemplate) {
    await this.api.delete(template.id.toString());
    this.reportTemplates = this.reportTemplates.filter((it) => it !== template);
  }

  created() {
    this.fetchReportTemplates();
  }

  async fetchReportTemplates() {
    const result = await this.api.get("", { params: { analytics_type: "report" } });
    this.reportTemplates = result.data;
  }
}
</script>

<style lang="scss" scoped>
a.site {
  cursor: pointer;
  margin-right: 5px;
}
.actions {
  .action {
    display: inline-block;
    margin: 0 10px;
    a {
      cursor: pointer;
    }
    a:not(:hover) {
      color: gray;
    }
  }
}
</style>
