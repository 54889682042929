import { makeApiInstance } from "./instance";

const buildUrl = ({ company_id, id, q }) => {
  let url = `/api/admin/companies/${company_id}/newsletter_definitions`;
  if (id) {
    url += `/${id}`;
  }
  if (q) {
    url += `?q=${q}`;
  }
  return url;
};

const apiInstance = makeApiInstance();

const api = {
  get: (params) => apiInstance.get(buildUrl(params)).then((response) => response.data),
  post: (params) => apiInstance.post(buildUrl(params), params).then((response) => response.data),
  put: (params) => apiInstance.put(buildUrl(params), params).then((response) => response.data),
  delete: (params) => apiInstance.delete(buildUrl(params), params).then((response) => response.data),
  preview: (params, data) => apiInstance.post(buildUrl(params) + "/preview", data).then((response) => response.data),
  deliver: (params) => apiInstance.post(buildUrl(params) + `/deliver`).then((response) => response.data),
};

export default api;
