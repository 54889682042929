import Vue from "vue";
import Component from "vue-class-component";
import store, { fetchInitialParameters } from "../store/store";
import router from "./router";
import UpdateTimesContainer from "../components/update-times-container.vue";
import UpdateTimesDropdown from "../components/update-times-dropdown.vue";
import ImportantMessage from "../components/important-message.vue";
import FlyoverFiltersContainer from "../flyover-filters/flyover-filters-container.vue";
import FlyoverFiltersHandle from "../flyover-filters/flyover-filters-handle.vue";
import Sidenav from "../sidenav/sidenav.vue";
import ReportView from "../analytics/report-view.vue";
import CompanySelector from "../top-bar/company-selector.vue";
import LanguageSelector from "../top-bar/language-selector.vue";
import CurrencySelector from "../top-bar/currency-selector.vue";
import NewDashboardButton from "../components/new-dashboard-button.vue";
import NavbarTitle from "../navbar/navbar-title.vue";
import ReportActions from "../components/report-actions.vue";
import NavbarDropdownButton from "../components/navbar-dropdown-button.vue";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    UpdateTimesContainer,
    UpdateTimesDropdown,
    FlyoverFiltersContainer,
    FlyoverFiltersHandle,
    Sidenav,
    ImportantMessage,
    ReportView,
    CompanySelector,
    LanguageSelector,
    CurrencySelector,
    NewDashboardButton,
    NavbarTitle,
    ReportActions,
    NavbarDropdownButton,
  },
  router,
  store,
  template: "#vue-app-template",
})
export default class VueApp extends Vue {
  get showFlyoverFilters() {
    const reportContext = this.$store.getters.getReportContext;
    return reportContext?.active_filters?.length > 0;
  }

  get vueAppClasses() {
    const classes = [];
    if (this.$store.state.flyoverFilters.open) {
      classes.push("flyover-filters-open");
    }
    return classes;
  }

  get pageTitle() {
    return this.$route.meta?.title || this.$store.getters.pageTitle;
  }

  created() {
    if (window.chart_token && !window.publicReportConfig) {
      return fetchInitialParameters();
    }
  }

  @Watch("pageTitle", { immediate: true })
  onReportNameChange() {
    const siteTitle = window.zoinedContext.siteTitle;
    if (this.pageTitle) {
      document.title = `${this.pageTitle} - ${siteTitle}`;
    } else {
      document.title = siteTitle;
    }
  }
}
