<template lang="pug">
.subscriptions-selector
  pill-list(:items="items", :available-items="availableItems", :togglable="false", @update="onUpdate")
</template>

<script lang="ts">
import _ from "lodash";
import MenuItem from "@/interfaces/menu-item";
import PillItem from "@/interfaces/pill-item";
import NewsletterDefinition from "@/model/newsletter-definition";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PillList from "@/components/pill-list.vue";

@Component({
  components: {
    PillList,
  },
})
export default class SubscriptionsSelector extends Vue {
  @Prop()
  value: number[];

  @Prop()
  definitions: NewsletterDefinition[];

  get items(): PillItem[] {
    return this.value
      .filter((elem, pos, arr) => arr.indexOf(elem) == pos) // uniq
      .map((id) => {
        const definition = _.find(this.definitions, (d) => id === d.id);
        return {
          value: id.toString(),
          name: definition.title,
        };
      });
  }

  get availableItems(): MenuItem[] {
    return (
      this.definitions?.map((definition) => {
        return {
          id: definition.id.toString(),
          name: definition.title,
        };
      }) || []
    );
  }

  onUpdate({ items }: { items: PillItem[] }) {
    const value = items.map((item) => parseInt(item.value));
    this.$emit("input", value);
  }
}
</script>
