<template lang="pug">
.report-owner-selector
  .form-group
    div 
      label {{ reportTypeLabel | i18n }}
    .btn-group
      .btn.btn-primary(:class="{active: userReport}", @click="selectUserReport()") {{ userReportLabel | i18n }}
      .btn.btn-primary(v-if="teams.length > 0" :class="{active: teamReport}", @click="selectTeamReport()") {{ teamReportLabel | i18n }}
      .btn.btn-primary(v-if="isAdmin" :class="{active: companyReport}", @click="selectCompanyReport()") {{ companyReportLabel | i18n }}

  .form-group(v-if="teamReport")
    label {{ 'newsletter.definition.team' | i18n }}
    select(@change="selectTeamReport($event.target.value)")
      option(:value="team.id" :selected="myReport.team_id == team.id" v-for="team in teams") {{ team.name }}
</template>

<script lang="ts">
import TeamsApiService from "../api/teams-api-service";
import MyReport from "../model/my-report";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ReportOwnerSelector extends Vue {
  @Prop()
  myReport: MyReport;

  teams: any[] = [];

  get isAdmin() {
    return window.zoinedContext.isAdmin;
  }

  get userReport() {
    return !!this.myReport.user_id;
  }

  get teamReport() {
    return !!this.myReport.team_id;
  }

  get companyReport() {
    return !this.userReport && !this.teamReport;
  }

  get reportTypeLabel() {
    return this.myReport.dashboard ? "dashboard_custom.dashboard_type" : "custom_report.report_type";
  }

  get userReportLabel() {
    return this.myReport.dashboard ? "dashboard_custom.user_dashboard" : "custom_report.user_report";
  }

  get companyReportLabel() {
    return this.myReport.dashboard ? "dashboard_custom.company_dashboard" : "custom_report.company_report";
  }

  get teamReportLabel() {
    return this.myReport.dashboard ? "dashboard_custom.team_dashboard" : "custom_report.team_report";
  }

  selectUserReport() {
    this.$emit("update-my-report", {
      ...this.myReport,
      user_id: window.zoinedContext.userId,
      team_id: null,
    });
  }

  selectTeamReport(team_id) {
    team_id = team_id || this.teams[0].id;
    this.$emit("update-my-report", {
      ...this.myReport,
      user_id: null,
      team_id,
    });
  }

  selectCompanyReport() {
    this.$emit("update-my-report", {
      ...this.myReport,
      user_id: null,
      team_id: null,
    });
  }

  created() {
    if (this.isAdmin) {
      new TeamsApiService().getTeams().then((teams) => (this.teams = teams));
    } else {
      // teams as admin
      this.teams = window.zoinedContext.current_role.team_memberships
        .filter(({ role }) => role === "admin")
        .map(({ team_id, name }) => ({ id: team_id, name }));
    }
  }
}
</script>
