<template lang="pug">
button.btn.btn-default.btn-dark-new-layout.btn-sm.save-as-button.report-action-button(@click="saveMyReport()") {{ 'actions.save_as' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import EventBus from "../events/event-bus";
import Events from "../events/events";

@Component({})
export default class SaveAsButton extends Vue {
  @Prop()
  confirm: boolean;

  saveMyReport() {
    EventBus.emit(Events.saveMyReport);
  }
}
</script>
