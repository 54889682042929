<template lang="pug">
uiv-modal(v-if="config" v-model="open")
  div(slot="header")
    code.pull-right.small {{ dbKey }}
    h4.modal-title {{ title }}

  .btn-group
    .btn.btn-default(:class='{active: !config.invisible}', @click='setVisible(true)') {{ 'company_admin.configuration.label.visible' | i18n }}
    .btn.btn-default(:class='{active: config.invisible}', @click='setVisible(false)') {{ 'company_admin.configuration.label.hidden' | i18n }}

  table.table.table-hover
    thead
      tr
        th {{ 'company_admin.configuration.label.locale' | i18n }}
        th {{ 'company_admin.configuration.label.current' | i18n }}
        th {{ 'company_admin.configuration.label.original' | i18n }}
    tbody
      tr(v-for='(value, lang) in config.label')
        td {{lang}}
        td
          input(v-model='config.label[lang]')
        td {{config.original_label[lang]}}


  div(slot="footer")
    button.btn.btn-default(@click='reset()') {{ 'company_admin.configuration.actions.reset' | i18n }}
    button.btn.btn-default(@click='cancel()') {{ 'actions.cancel' | i18n }}
    button.btn.btn-primary(@click='ok()') {{ 'actions.save' | i18n }}
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import i18n from '../i18n';
import _ from 'lodash';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class MetricDialog extends Vue {
  @Prop()
  type: string;

  dbKey = null;
  config = null;
  open = false;

  get title() {
    return i18n.t("company_admin.configuration.edit_" + this.type);
  }

  show({ key, config }) {
    this.dbKey = key;
    this.config = _.cloneDeep(config);
    this.open = true;
  }

  ok() {
    this.$emit("save", { key: this.dbKey, config: this.config });
    this.open = false;
  }

  cancel() {
    this.open = false;
  }

  setVisible(value) {
    return this.config.invisible = !value;
  }

  reset() {
    return this.config.label = _.cloneDeep(this.config.original_label);
  }
}
</script>
