<template lang="pug">
div
	h3.section-title {{"profile.newsletter_section_title" | i18n }}
	button.btn.btn-primary.add-subscription(v-b-modal="'add-subscription-modal'")
		i.fa.fa-plus.profile-i
		| {{ "profile.add_subscription" | i18n }}

	spinner(v-if="loading")
	table.table.table-hover(v-if="!!subscriptions.length")
		thead
			tr
				th {{"activerecord.attributes.source/vend/connection.company_name" | i18n }}
				th {{"newsletter.title" | i18n }}
				th {{"newsletter.definition.frequency" | i18n }}
				th
		tbody#subscriptions
			tr(v-for="subscription in subscriptions")
				td {{ subscription.company_name }}
				td {{ subscription.title }}
				td {{ subscription.frequency }}
				td
					a.unsubscribe(@click="unsubscribe(subscription.id, subscription.company_id)") {{ "newsletter.subscription.unsubscribe" | i18n}}

	.no-results(v-else)
		p {{ "table.no_results" | i18n }}
			a.unsubscribe(v-b-modal="'add-subscription-modal'") {{ "profile.add_subscription" | i18n }}

	addSubscriptionModal(v-bind="{company}")

</template>

<script>
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'
import spinner from '../components/spinner.vue';
import { unsubscribe } from '../lib/data/profile';

import addSubscriptionModal from './profile-add-newsletter-subscription-modal'

export default {
	components: {
		spinner,
		addSubscriptionModal
	},
	directives: {
		'b-modal': bModalDirective
	},
  props: {
		subscriptions: {
			type: Array,
			required: true
		},
		company: {
			type: Number,
			required: true
		},
  },
	data(){
		return {
      loading: false
		}
  },
  methods: {
		unsubscribe: function(id, company_id) {
			this.loading = true;
			unsubscribe(id,company_id).then((res) => {
        this.$root.$emit('getAlert', res.success)
        this.$root.$emit('fetchData')
				this.loading = false;
			}).catch(error => {
        this.error = error;
        this.loading = false;
      })
		}
	}
};
</script>

<style scoped>
	.section-title {
		display: inline;
	}

	.add-subscription {
		padding-bottom: 10px;
		margin-left: 10px;
		float: right;
	}

	i.fa.profile-i {
		margin-right: 4px;
	}

	a.unsubscribe {
		cursor: pointer;
	}

	.no-results p {
		margin: 20px 0 0 0;
		font-size: 120%;
		opacity: 0.7;
	}

	.no-results p a::before {
		content: "(";
	}

	.no-results p a::after {
		content: ")";
	}

	.no-results p a {
		font-size: 90%;
		text-decoration: underline;
		font-style: italic;
		opacity: 1;
		margin-left: 6px;
	}
</style>
