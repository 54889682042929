<template>
  <div class="component-filters-item flex-row gap-sm align-items-center">
    <div class="flex-row align-items-center justify-content-center rounded icon-container" :class="iconClasses[type]">
      <icon :icon="iconNames[type]" />
    </div>
    <span>{{ itemsText }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Icon } from "@iconify/vue2";

export type ComponenFiltersItemProps = {
  items: string[];
  type: "include" | "exclude" | "range";
  separator?: string;
};

const iconNames = {
  include: "fluent:add-16-filled",
  exclude: "fluent:subtract-16-filled",
  range: "fluent:arrow-fit-16-filled",
};

const iconClasses = {
  include: ["bg-text-emphasis", "fg-text-primary-inverse", "text-xs"],
  exclude: ["bg-semantic-error", "fg-text-primary-inverse", "text-xs"],
  range: ["bg-transparent", "fg-text-emphasis", "text-base"],
};

const props = defineProps<ComponenFiltersItemProps>();

const itemsText = computed(() => props.items.join(props.separator || ", "));
</script>

<style scoped>
.component-filters-item {
  flex-shrink: 0;
}
.icon-container {
  width: 16px;
  height: 16px;
}
</style>
