<template lang="pug">
.sidenav-icon-btn(:class="{ active }", @click="$emit('click')")
  .content(v-uiv-tooltip.right="title")
    slot
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class IconBtn extends Vue {
  @Prop()
  active: boolean;

  @Prop()
  title: string;
}
</script>

<style lang="scss">
.sidenav-icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-color: transparent;
  border-style: solid;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 4px;
  border-bottom-width: 4px;

  @media (min-width: 768px) {
    border-top-width: 0px;
    border-bottom-width: 0px;
    height: 30px;
    margin: 13px 0;
    overflow: visible;
  }

  &.logo-container {
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  i.fa {
    font-size: 20px;
  }

  .content {
    color: white;
    background-color: var(--color-sidenav-icon-btn-background);
    border-radius: 9999px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
      width: 46px;
      height: 46px;
    }
  }

  &:hover:not(.active) {
    .content {
      color: var(--color-primary);
    }
  }

  &.active {
    .content {
      background-color: var(--color-sidenav-icon-btn-active-background);
      color: var(--color-sidenav-icon-btn-text);
    }

    border-bottom-color: var(--color-primary);

    @media (min-width: 768px) {
      border-left-width: 4px;
      border-right-width: 4px;
      border-top-width: 0px;
      border-bottom-width: 0px;
      border-bottom-color: transparent;
      border-left-color: var(--color-sidenav-icon-btn-active-background);
    }
  }
}</style>
