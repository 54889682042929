<template lang="pug">
.edit-custom-dashboard-page(v-if="loaded")
  h2.hide-new-layout {{ title }}
  .mt-md.show-new-layout
  custom-dashboard-editor(ref="editor" :default-report="report" :default-my-report="myReport")
spinner(v-else)
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import spinner from "../components/spinner.vue";
import i18n from "../i18n";
import CustomDashboardEditor from "./custom-dashboard-editor.vue";
import Report from "../model/report";
import MyReport from "../model/my-report";
import { makeApiInstance } from "../api/instance";
import ReportContext from "../model/report-context";
import Mutations from "../store/mutations";
import _ from "lodash";
import FilterConfiguration from "../model/filter-configuration";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";

@Component({
  components: {
    spinner,
    CustomDashboardEditor,
  },
})
export default class EditCustomDashboardPage extends Vue {
  @Ref("editor")
  editor: CustomDashboardEditor;

  @Prop()
  id: string;

  @Prop()
  templateId: string;

  @Prop()
  duplicateId: string;

  @Prop()
  config: {
    filter_configuration?: FilterConfiguration;
    chart_options?: ChartOptions;
    table_config?: TableConfig;
  };

  report: Report = null;
  myReport: MyReport = null;

  get title() {
    if (this.id) {
      return this.myReport.name;
    } else {
      return i18n.t("dashboard_custom.new_dashboard");
    }
  }

  get loaded() {
    return this.myReport && this.report;
  }

  async created() {
    if (this.id) {
      const reportContext: ReportContext = await makeApiInstance()
        .get("/api/v1/reports/report_context", { params: { report_id: this.id } })
        .then((response) => response.data);
      this.report = reportContext.report;
      this.myReport = reportContext.my_report;
    } else if (this.duplicateId) {
      const reportContext: ReportContext = await makeApiInstance()
        .get("/api/v1/reports/report_context", { params: { report_id: this.duplicateId } })
        .then((response) => response.data);
      this.report = _.pick(reportContext.report, "metadata", "config") as Report;
      this.report.metadata.title = `Copy of ${this.report.metadata.title}`;
      this.myReport = {
        dashboard: true,
        user_id: window.zoinedContext.userId,
      } as MyReport;
    } else if (this.templateId) {
      const { title, config } = await makeApiInstance()
        .get(`/api/v1/analytics_templates/${this.templateId}`)
        .then((response) => response.data);
      this.report = {
        metadata: {
          title,
        },
        config,
      } as Report;
      this.myReport = {
        dashboard: true,
        user_id: window.zoinedContext.userId,
      } as MyReport;
    } else {
      this.report = {
        metadata: {
          title: "",
        },
        config: {
          components: [],
        },
      } as Report;
      this.myReport = {
        dashboard: true,
        user_id: window.zoinedContext.userId,
      } as MyReport;
    }

    // Overrides
    if (this.config?.filter_configuration) {
      this.myReport.filters = this.config.filter_configuration;
    }
    if (this.config?.chart_options) {
      this.myReport.control_state = this.config.chart_options;
    }

    this.$store.commit(Mutations.setTitle, this.title);
  }

  beforeRouteUpdate(to, from, next) {
    this.handleRouteChange(next);
  }

  beforeRouteLeave(to, from, next) {
    this.handleRouteChange(next);
  }

  handleRouteChange(next) {
    let confirmed = true;

    if (this.editor?.hasChanges) {
      confirmed = confirm(i18n.t("confirm.unsaved_changes_confirmation"));
    }

    if (confirmed) {
      this.$store.commit(Mutations.clearTitle);
      next();
    } else {
      next(false);
    }
  }
}
</script>
