<template lang="pug">
a.navbar-dropdown-button.flex-row.align-items-center
  .content
    slot(name="content")
      .flex-column.hidden-sm
        .title {{ title }}
        .subtitle {{ subtitle }}
  .trailing
    slot(name="icon")
      svg-icon.show-new-layout(:name="icon")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SvgIcon from "./svg-icon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class NavbarDropdownButton extends Vue {
  @Prop()
  title: string;

  @Prop()
  subtitle: string;

  @Prop({ default: "chevron-down" })
  icon: string;
}
</script>

<style lang="scss">
html.new-layout {
  .nav .open > a.navbar-dropdown-button {
    background-color: transparent;
  }

  a.navbar-dropdown-button {
    margin-left: 20px;
    
    .title {
      font-size: 12px;
      font-weight: 600;
      color: var(--color-black-90);
      white-space: nowrap;
    }
    .subtitle {
      font-size: 10px;
      color: var(--color-muted);
      white-space: nowrap;
    }
    .trailing {
      margin-left: 10px;
      background-color: var(--color-black-90);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 9999px;
      height: 35px;
      width: 35px;

      svg {
        max-width: 20px;
        max-height: 20px;
      }
    }

    &:hover {
      filter: opacity(0.8);
    }
  }
}

</style>
