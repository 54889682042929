import { render, staticRenderFns } from "./profile-newsletter-subscriptions.vue?vue&type=template&id=ae30a58e&scoped=true&lang=pug"
import script from "./profile-newsletter-subscriptions.vue?vue&type=script&lang=js"
export * from "./profile-newsletter-subscriptions.vue?vue&type=script&lang=js"
import style0 from "./profile-newsletter-subscriptions.vue?vue&type=style&index=0&id=ae30a58e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae30a58e",
  null
  
)

export default component.exports