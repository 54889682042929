<template lang="pug">
#filters.fly-over.selectors(:class="{open}")
  .body(v-if="open")
    .container.filters-toggle-container.hide-filters.hide-new-layout
      a.fo-handle.hide-filters(@click="hide")
        span.hide-text {{ 'filters.hide_filters' | i18n }}
        i.fa
    flyover-filters
</template>

<script lang="ts">
import Mutations from "../store/mutations";
import Vue from "vue";
import Component from "vue-class-component";
import flyoverFilters from "./flyover-filters.vue";

@Component({
  components: { flyoverFilters },
})
export default class FlyoverFiltersContainer extends Vue {
  get open() {
    return this.$store.state.flyoverFilters.open;
  }

  hide() {
    this.$store.commit(Mutations.closeFlyoverFilters);
  }
}
</script>
