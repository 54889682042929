<template lang="pug">
.user-listing
  h3.hide-new-layout {{ "company_admin.users" | i18n }}

  div
    .pull-right
      button.btn.btn-primary(@click="inviteUser()") {{ "company_admin.invite_users.invite_user" | i18n }}
      button.btn.btn-primary(@click="exportUsers()", style="margin-left: 5px") {{ "filters.export_xlsx" | i18n }}
      button.btn.btn-primary(@click="importUsers()", style="margin-left: 5px") {{ "company_admin.import_users.title" | i18n }}

    .table-controls
      search-bar(v-model="search")

    user-table(:users="users", @resend="resend", @delete="deleteUser" @updated="fetchUsers")
    .centered(v-if="total > perPage")
      uiv-pagination(v-model="page", :total-page="Math.ceil(total / perPage)")
  user-invite-dialog(ref="userInviteDialog", :teams="teams", :definitions="newsletterDefinitions", @add="fetchUsers")
  user-import-dialog(ref="userImportDialog", @add-users="fetchUsers")
</template>

<script lang="ts">
import UserCompanyRole from "@/model/user-company-role";
import Vue from "vue";
import Component from "vue-class-component";
import UserDialog from "./user-dialog.vue";
import UserTable from "./user-table.vue";
import adminNewsletterDefinitions from "@/api/admin-newsletter-definitions-api";
import adminTeamsApi from "@/api/admin-teams-api";
import adminUsersApi from "@/api/admin-users-api";
import NewsletterDefinition from "@/model/newsletter-definition";
import Team from "@/model/team";
import toastr from "toastr";
import { v4 as uuidv4 } from "uuid";
import { downloadFile } from "../lib/download";
import { getDownloadUrl } from "@/channel/file-download-channel";
import i18n from "@/i18n";
import { Ref, Watch } from "vue-property-decorator";
import SearchBar from "@/components/search-bar.vue";
import UserInviteDialog from "./user-invite-dialog.vue";
import UserImportDialog from "./user-import-dialog.vue";
import { hideOverlay, showOverlay } from "@/ui/overlay";

@Component({
  components: {
    UserTable,
    UserDialog,
    SearchBar,
    UserInviteDialog,
    UserImportDialog,
  },
})
export default class UserListing extends Vue {
  @Ref("userInviteDialog")
  userInviteDialog: UserInviteDialog;

  @Ref("userImportDialog")
  userImportDialog: UserImportDialog;

  page = 1;
  perPage = 15;
  search = "";
  total = 0;

  users: UserCompanyRole[] = null;
  newsletterDefinitions: NewsletterDefinition[] = null;
  teams: Team[] = null;

  get companyId() {
    return window.zoinedContext.companyId;
  }

  get params() {
    const params = {
      company_id: this.companyId,
      page: this.page,
      per_page: this.perPage,
    };

    if (this.search.length > 0) {
      params["search"] = this.search;
    }

    return params;
  }

  created() {
    adminNewsletterDefinitions.get({ company_id: this.companyId }).then((newsletterDefinitions) => {
      this.newsletterDefinitions = newsletterDefinitions;
    });
    adminTeamsApi.get({ company_id: this.companyId }).then((teams) => {
      this.teams = teams;
    });
  }

  @Watch("search")
  onSearchChange() {
    this.page = 1;
  }

  @Watch("params", { immediate: true })
  fetchUsers() {
    adminUsersApi.get(this.params).then((users) => {
      this.users = users;
      this.total = users.total_count;
    });
  }

  resend(user) {
    return adminUsersApi.resendInvite({ company_id: this.companyId, id: user.id }).then(() => {
      toastr.success(i18n.t("company_admin.resend_invite.invite_resent"));
    });
  }

  inviteUser() {
    this.userInviteDialog.show();
  }

  deleteUser(user) {
    adminUsersApi
      .delete({ company_id: this.companyId, id: user.id })
      .then(() => this.fetchUsers())
      .catch((err) => {
        const message = (err && err.error) || "Failed to delete user.";
        toastr.error(message, { timeOut: 0 });
      });
  }

  async exportUsers() {
    const download_id = uuidv4();

    showOverlay(i18n.t("processing_export_file"));
    try {
      // Listen for export finish
      const url = await getDownloadUrl(download_id, {
        connected: () => {
          // Start export
          const data: any = {
            download_id,
          };
          fetch(`/api/admin/companies/${this.companyId}/users/export`, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
        },
      });

      // Download file for user
      await downloadFile(url);
    } catch (error) {
      (window.Rollbar || console).error("Export failed. Download id: " + download_id);
      toastr.error(i18n.t("errors.export_fail"), { timeOut: 0 });
    } finally {
      hideOverlay();
    }
  }

  importUsers() {
    this.userImportDialog.show();
  }
}
</script>
