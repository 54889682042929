<template lang="pug">
.item-menu-container
  .search-control.input-group(v-if="showSearch")
    input.form-control(type="text" :placeholder="'actions.search_for' | i18n" :value="search" @input="onSearch($event.target.value)")
    span.input-group-addon
      i.fa.fa-search
  .simple-spinner(v-if="!items && !isWildcard")
    .spinner
  .item-menu(v-if="showItemMenu" :class="{'with-search': showSearch}")
    .item(v-if="isWildcard" :class="{active: isItemSelected(wildcardItem)}" @click.stop="onClickItem(wildcardItem)")
      | {{ wildcardLabel }}
    template(v-if="!isWildcard" v-for="(groupItems, category) in groupedItems")
      .category-header(v-if="category && category !== 'undefined' && groupItems.length > 0") {{ category }}
      .items
        .item(:class="{active: isItemSelected(item)}" @click.stop="onClickItem(item)", v-for="item in groupItems")
          | {{ item.name }}
          .pull-right
            confirm-button.delete-button(v-if="deleteable && item.deletable !== false", @confirm="$emit('delete',item)")
              i.delete.fa.fa-trash-o(v-on:click.stop="1==1")
  .footer(v-if="$slots.footer")
    slot(name="footer")
</template>

<script lang="ts">
import _ from "lodash";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import confirmButton from "./confirm-button.vue";
import i18n from "../i18n";
import PillItem from "@/interfaces/pill-item";

@Component({
  components: {
    confirmButton,
  },
})
export default class ItemMenu extends Vue {
  @Prop()
  items: MenuItem[];

  @Prop()
  selectedItems: PillItem[];

  @Prop({ default: false })
  typeahead: boolean;

  @Prop({ default: true })
  searchable: boolean;

  @Prop({ default: false })
  deleteable: boolean;

  @Prop()
  menuOpen: boolean;

  @Prop({ default: "" })
  searchQuery: string;

  @Prop({ default: false })
  wildcard: boolean;

  search = "";

  get filteredItems() {
    if (this.typeahead) {
      return this.items;
    }

    // Filter by search
    const search = this.search.trim().toLowerCase();

    return search.length > 0
      ? _.filter(this.items, (item) => item.name.toLowerCase().indexOf(search) >= 0)
      : this.items;
  }

  get groupedItems() {
    return _.groupBy(this.filteredItems, "category");
  }

  get showSearch() {
    return this.typeahead || (this.searchable && this.items);
  }

  get showItemMenu() {
    return this.isWildcard || (this.filteredItems && this.filteredItems.length > 0);
  }

  get isWildcard() {
    return (this.wildcard && this.search && this.search.includes("%")) || this.search.includes("_");
  }

  get wildcardItem(): MenuItem {
    return { key: this.search, name: this.search, wildcard: true };
  }

  get wildcardLabel(): string {
    return i18n.t("filters.all_matching", { query: this.search });
  }

  isItemSelected(item: MenuItem) {
    return (
      this.selectedItems &&
      this.selectedItems.some(
        (pillItem) => pillItem.value === menuItemKey(item) && !!pillItem.wildcard === !!item.wildcard
      )
    );
  }

  onClickItem(item: MenuItem) {
    if (this.isItemSelected(item)) {
      this.$emit("deselect", item);
    } else {
      this.$emit("select", item);
    }
  }

  onSearch(q = "") {
    this.search = q;
    if (!this.isWildcard) {
      this.$emit("search", q);
    }
  }

  @Watch("searchQuery")
  onSearchQuery(q) {
    if (this.search !== q) {
      this.onSearch(q);
    }
  }
}
</script>

<style lang="scss" scoped>
.search-control {
  *,
  *:active,
  *:focus {
    border: none;
    background-color: white;
    box-shadow: none;
  }
}
.item-menu {
  &.with-search {
    margin-top: 4px;
    border-top: 1px solid #ccc;
  }

  min-width: 300px;
  max-height: 376px;
  overflow-y: auto;
  padding-top: 4px;

  .category-header {
    text-transform: uppercase;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: #999999;
  }
}

.item {
  padding: 7px 10px;
  border-radius: 5px;
  color: #333;

  margin: 4px;

  &:first-child,
  &:last-child {
    margin: 0 4px;
  }

  &.active {
    background-color: #4bcdf0;
  }

  &:hover {
    &:not(.active) {
      background-color: #cceeff;
    }
    cursor: pointer;

    .delete-button {
      display: block;
    }
  }

  .delete-button {
    display: none;

    &:hover * {
      font-weight: bold;
    }
  }
}

.wildcard-item {
  display: inline-block;
  padding: 7px 10px;
  margin: 4px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background-color: #4bcdf0;
  }
}

.footer {
  margin-top: 7px;
}
</style>
