<template lang="pug">
span
  dropdown(:open="open")
    template(slot="toggle")
      .btn-group
        button.btn.btn-primary(type="primary" @click="newNewsletter()") {{ 'newsletter.definition.new_form_title' | i18n }}
        button.btn.btn-primary(type="primary" @click="open=!open" @close="open=false" v-if="enableTemplates && newsletterTemplates && newsletterTemplates.length > 0")
          span.caret
    template(slot="menu")
      li
        a(@click="newNewsletter()") {{ 'newsletter.definition.create_empty' | i18n }}
        a(@click="newNewsletterFromTemplate()") {{ 'newsletter.definition.create_from_template' | i18n }}
  newsletter-definition-from-template-modal(ref="newsletterDefinitionFromTemplateModal" :newsletter-templates="newsletterTemplates" @new-from-template="onNewFromTemplate")
</template>

<script lang="ts">
import Vue from "vue";
import dropdown from "../components/dropdown.vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import NewsletterDefinitionFromTemplateModal from "./newsletter-definition-from-template-modal.vue";
import newsletterTemplatesApi from "../api/newsletter-templates-api";
import _ from "lodash";

@Component({
  components: {
    dropdown,
    NewsletterDefinitionFromTemplateModal,
  },
})
export default class NewNewsletterButton extends Vue {
  @Ref("newsletterDefinitionFromTemplateModal")
  newsletterDefinitionFromTemplateModal: NewsletterDefinitionFromTemplateModal;

  @Prop({ default: false })
  enableTemplates: boolean;

  open = false;
  newsletterTemplates = null;

  created() {
    this.fetchNewsletterTemplates();
  }

  newNewsletter() {
    this.open = false;
    if (this.$listeners["new-newsletter"]) {
      this.$emit("new-newsletter");
    } else {
      location.hash = "#!/definitions/";
    }
  }

  onNewFromTemplate(id) {
    this.open = false;
    if (this.$listeners["new-newsletter-from-template"]) {
      this.$emit("new-newsletter-from-template", id);
    } else {
      location.hash = `#!/definitions/?templateId=${id}`;
    }
  }

  newNewsletterFromTemplate() {
    this.open = false;
    this.newsletterDefinitionFromTemplateModal.show();
  }

  fetchNewsletterTemplates() {
    newsletterTemplatesApi.getAll().then(
      (templates) =>
        (this.newsletterTemplates = templates.map((template) => {
          const localizedTitle = _.get(template, `merged_translations_hash.title.${window.zoinedContext.locale}`);
          if (localizedTitle) {
            template = { ...template, title: localizedTitle };
          }
          return template;
        }))
    );
  }
}
</script>
