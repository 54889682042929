<template lang="pug">
confirm-button.btn.btn-default.btn-dark-new-layout.btn-sm.btn-icon.save-report-button.report-action-button(
  :disabled="disabled" 
  tag="button"
  :popover-message="popoverMessage"
  v-uiv-tooltip.hover="saveTooltip" 
  @confirm="saveReport"
) 
  i.fa.fa-save.fa-lg
</template>

<script lang="ts">
import i18n from "../i18n";
import Vue from "vue";
import Component from "vue-class-component";
import ConfirmButton from "./confirm-button.vue";
import EventBus from "../events/event-bus";
import Events from "../events/events";
import ReportContext from "../model/report-context";
import MyReport from "../model/my-report";

@Component({
  components: { ConfirmButton },
})
export default class SaveReportButton extends Vue {
  get reportContext(): ReportContext {
    return this.$store.getters.getReportContext;
  }

  get myReport(): MyReport {
    return this.reportContext?.my_report;
  }

  get popoverMessage() {
    if (this.myReport.dashboard) {
      return i18n.t("dashboard_custom.save_confirm_message");
    } else {
      return i18n.t("custom_report.save_confirm_message");
    }
  }

  get hasChanges(): boolean {
    return this.$store.getters.hasChanges;
  }

  get disabled() {
    return !this.hasChanges;
  }

  get saveTooltip() {
    return this.disabled ? null : i18n.t("actions.save");
  }

  saveReport() {
    EventBus.emit(Events.saveReport);
  }
}
</script>
