<template lang="pug">
ul.selector.list-inline
  li.title {{ 'filter.config.span' | i18n }}
  li(style="padding-right: 0px;")
    btn-group
      btn.btn-sm(type="primary" input-type="radio", :input-value="6", v-model="model", @click="update(6)") 1/2
      btn.btn-sm(type="primary" input-type="radio", :input-value="4", v-model="model", @click="update(4)") 1/3
      btn.btn-sm(type="primary" input-type="radio", :input-value="3", v-model="model", @click="update(3)") 1/4
</template>

<script lang="ts">
import Vue from "vue";
import { Btn, BtnGroup } from "uiv";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    Btn,
    BtnGroup,
  },
})
export default class ComponentSpanSelector extends Vue {
  @Prop()
  value;

  model = 6;

  update(value) {
    this.$emit("input", value);
  }

  @Watch("value", { immediate: true })
  setModel() {
    this.model = this.value || 6;
  }
}
</script>
