<template lang="pug">
.widget-filters
  .main-widget-filters
    widget-filter-selector(
      :name="filter" :config="filterConfiguration.widgets[filter]" 
      :radio="isRadio(filter)" 
      :available-items="optionsFor(filter)"
      @update="updateWidget(filter, $event)"
      v-for="filter in widgetFilters"
      :key="filter"
    )
  .extra-filters
    component-span-selector(v-if="isActive('span')" :value="filterConfiguration.raw_filters.span" @input="updateRawFilters('span', $event)")
    filter-dropdown(v-if="isActive('variant')", :title="'table.show' | i18n", :available-items="optionsFor('variant')", :value="filterConfiguration.raw_filters.variant", @update="updateRawFilters('variant', $event)")
    filter-dropdown(v-if="isActive('limit')", :title="'newsletter.definition.sections.common.entries_to_show' | i18n", :available-items="optionsFor('limit')", :value="filterConfiguration.raw_filters.limit", @update="updateRawFilters('limit', $event)")
    filter-dropdown(v-if="isActive('limit_y')", :title="'newsletter.definition.sections.common.entries_to_show' | i18n", :available-items="optionsFor('limit_y')", :value="filterConfiguration.raw_filters.limit_y", @update="updateRawFilters('limit_y', $event)")
    filter-selector(v-if="isActive('selected_items')", :excludable="false", :config="filterConfiguration.widgets.selected_items", :filter="optionsFor('selected_items')", @update="updateWidget('selected_items', $event)")
    filter-dropdown(v-if="isActive('proportions_set')", :title="'filter.config.proportions_set' | i18n", :available-items="optionsFor('proportions_set')", :value="filterConfiguration.raw_filters.proportions_set", @update="updateRawFilters('proportions_set', $event)")
    filter-dropdown(v-if="isActive('trend')", :title="'filter.config.trend' | i18n", :available-items="optionsFor('trend')", :value="filterConfiguration.raw_filters.trend", @update="updateRawFilters('trend', $event)")
    filter-dropdown(v-if="isActive('show_values')", :title="'filter.config.show_values' | i18n", :available-items="optionsFor('show_values')", :value="filterConfiguration.raw_filters.show_values", @update="updateRawFilters('show_values', $event)")
    filter-dropdown(v-if="isActive('metric_type')", :title="'filter.config.metric_type' | i18n", :available-items="optionsFor('metric_type')", :value="filterConfiguration.raw_filters.metric_type", @update="updateRawFilters('metric_type', $event)")
    .metric-range(v-if="isActive('metric_range')")
      metricRangeSelector(:items="filterConfiguration.widgets.metric_range", @update="updateWidget('metric_range', $event)")
      rangefilterUnionSelector(v-if="filterConfiguration.widgets.metric_range && filterConfiguration.widgets.metric_range.length >= 2" :value="filterConfiguration.raw_filters.rangefilter_union"  @input="updateRawFilters('rangefilter_union', $event)")
    likeForLikeSelector(v-if="isActive('like_for_like')", :config="filterConfiguration.widgets.like_for_like", @update="updateWidget('like_for_like', $event)")
    zerofillSelector(v-if="isActive('zerofill')" :value="filterConfiguration.raw_filters.zerofill" @input="updateRawFilters('zerofill', $event)")
</template>

<script lang="ts">
import _ from "lodash";
import FilterConfiguration from "../model/filter-configuration";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { reportTypes } from "../custom-report/report-types";
import WidgetFilterSelector from "../components/widget-filter-selector.vue";
import metricRangeSelector from "../components/metric-range-selector.vue";
import rangefilterUnionSelector from "../components/rangefilter-union-selector.vue";
import likeForLikeSelector from "../components/like-for-like-selector.vue";
import zerofillSelector from "../components/zerofill-selector.vue";
import FilterDropdown from "../components/filter-dropdown.vue";
import filterSelector from "../components/filter-selector.vue";
import { flatFilterConfiguration, WIDGET_FILTERS } from "../lib/filter-util";
import ReportConfig from "../model/report-config";
import ReportType from "../model/report-type";
import ComponentSpanSelector from "../components/component-span-selector.vue";

@Component({
  components: {
    WidgetFilterSelector,
    metricRangeSelector,
    rangefilterUnionSelector,
    likeForLikeSelector,
    zerofillSelector,
    FilterDropdown,
    filterSelector,
    ComponentSpanSelector,
  },
})
export default class WidgetFiltersSection extends Vue {
  @Prop()
  filterConfiguration: FilterConfiguration;

  @Prop()
  reportConfig: ReportConfig;

  get reportTypeName() {
    return this.reportConfig?.report_type;
  }

  get reportTypesByName() {
    return reportTypes.reduce(
      (result, reportType) => ({
        ...result,
        [reportType.config.report_type]: reportType,
      }),
      {}
    );
  }

  get reportType(): ReportType {
    if (this.reportTypeName) {
      return this.reportTypesByName[this.reportTypeName];
    } else {
      return {
        config: this.reportConfig,
      };
    }
  }

  get flatFilterConfiguration() {
    return flatFilterConfiguration(this.filterConfiguration);
  }

  get activeFilters() {
    return this.reportType.config.filters.filter((filter) => {
      const enabled = _.get(this.reportType, `filters.${filter}.enabled`, () => true);
      return enabled(this.flatFilterConfiguration);
    });
  }

  get widgetFilters() {
    return this.activeFilters.filter((filter) => WIDGET_FILTERS.includes(filter));
  }

  isActive(filter) {
    return this.activeFilters.includes(filter);
  }

  optionsFor(key) {
    const options = _.get(this.reportType, `filters.${key}.options`);
    return _.isFunction(options) ? options(this.flatFilterConfiguration) : options;
  }

  isRadio(filter) {
    return this.reportType.config.radio_selectors?.includes(filter);
  }

  updateWidget(widget, config) {
    const widgets = {
      ...this.filterConfiguration.widgets,
      [widget]: config,
    };

    this.$emit("update", {
      ...this.filterConfiguration,
      widgets,
    });
  }

  updateRawFilters(key, value) {
    const raw_filters =
      _.isNull(value) || _.isUndefined(value)
        ? _.omit(this.filterConfiguration.raw_filters, key)
        : {
            ...(this.filterConfiguration.raw_filters || {}),
            [key]: value,
          };

    this.$emit("update", {
      ...this.filterConfiguration,
      raw_filters,
    });
  }
}
</script>

<style lang="scss" scoped>
.widget-filters {
  .item {
    display: inline;
  }
}
</style>
