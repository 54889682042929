<template lang="pug">
uiv-modal(v-if="role", v-model="open", :title="'company_admin.invite_users.invite_user' | i18n", :backdrop="false")
  spinner(v-if="saving")
  .alert.alert-danger(v-if="error") {{ error }}
  .row
    .col-md-6
      label.control-label(for="invite_email") {{ "activerecord.attributes.user.email" | i18n }}
      input#invite_email.form-control(
        autofocus,
        type="email",
        v-model="role.email",
        required,
        :placeholder="'activerecord.attributes.user.email' | i18n"
      )
    .col-md-6
      label.control-label(for="invite_role") {{ "activerecord.attributes.user.role" | i18n }}
      select#invite_role.form-control(v-model="role.role")
        option(:value="role.id", v-for="role in roleOptions") {{ role.name }}
    .col-md-6
      label.control-label(for="invite_first_name") {{ "activerecord.attributes.user.first_name" | i18n }}
      input#invite_first_name.form-control(
        type="text",
        v-model="role.first_name",
        required,
        :placeholder="'activerecord.attributes.user.first_name' | i18n"
      )
    .col-md-6
      label.control-label(for="invite_last_name") {{ "activerecord.attributes.user.last_name" | i18n }}
      input#invite_last_name.form-control(
        type="text",
        v-model="role.last_name",
        required,
        :placeholder="'activerecord.attributes.user.last_name' | i18n"
      )
    .col-md-6
      label.control-label(for="invite_language") {{ "activerecord.attributes.user.language" | i18n }}
      select#invite_language.form-control(v-model="role.locale")
        option(:value="locale.id", v-for="locale in localeOptions") {{ locale.name }}
    .col-md-6(v-if="currencyOptions.length > 1")
      label.control-label(for="invite_newsletter_currency") {{ "activerecord.attributes.user.newsletter_currency" | i18n }}
      select#invite_newsletter_currency.form-control(v-model="role.newsletter_currency")
        option(:value="currency.currency", v-for="currency in currencyOptions") {{ currency.label }}

  .row
    .col-md-12
      label.control-label
        | {{ "filter.config.allowFileExport" | i18n }}
        span.info-bubble
      .allow-options
        .btn-group(data-toggle="buttons")
          label.btn.btn-primary(:class="{ active: !role.deny_export }", @click="updateExport(false)") {{ "permissions.allow" | i18n }}
          label.btn.btn-primary(:class="{ active: role.deny_export }", @click="updateExport(true)") {{ "permissions.deny" | i18n }}

  .row(v-if="teams.length > 0")
    .col-md-12
      label.control-label(v-uiv-tooltip.top="teamsTooltip")
        | {{ "company_admin.teams.title" | i18n }}
        span.info-bubble
      teamMembershipTable(:team-memberships="role.team_memberships", :teams="teams", @update="updateTeamMemberships")

  .row
    .col-md-12
      label.control-label(v-uiv-tooltip.top="permissionsTooltip")
        | {{ "profile.user_permissions" | i18n }}
        span.info-bubble
      permissions-editor(
        :permissions="role.permissions",
        :effective-permissions="role.effective_permissions",
        @update="updatePermissions"
      )
  .row(v-if="restrictedDashboards.length > 0")
    .col-md-12
      label.control-label(v-uiv-tooltip.top="allowedDashboardsTooltip")
        | {{ "profile.allowed_restricted_dashboards" | i18n }}
        span.info-bubble
      allowed-reports(
        :title="'dashboard_custom.dashboards' | i18n",
        :restricted-reports="restrictedDashboards",
        :allowed-reports="role.permitted_dashboards",
        @change="updatePermittedDashboards"
      )

  .row(v-if="restrictedReports.length > 0")
    .col-md-12
      label.control-label(v-uiv-tooltip.top="allowedReportsTooltip")
        | {{ "profile.allowed_restricted_reports" | i18n }}
        span.info-bubble
      allowed-reports(
        :title="'analytics.reports' | i18n",
        :restricted-reports="restrictedReports",
        :allowed-reports="role.permitted_reports",
        @change="updatePermittedReports"
      )
  .row(v-if="companyDashboards.length > 0")
    .col-md-12
      label.control-label(v-uiv-tooltip.top="defaultDashboardTooltip")
        | {{ "dashboard_custom.default_dashboard" | i18n }}
        span.info-bubble
      select#invite_default_dashboard.form-control(v-model="role.default_dashboard_id")
        option(:value="null") {{ "dashboard.system_dashboard" | i18n }}
        option(:value="dashboard.report.id", v-for="dashboard in companyDashboards") {{ dashboard.name }}
  .row
    .col-md-12
      label.control-label(v-uiv-tooltip.top="defaultParametersTooltip")
        | {{ "profile.default_params" | i18n }}
        span.info-bubble
      defaultParameterSelector(v-model="role.default_parameters", :permissions="role.effective_permissions")
  .row(v-if="definitions.length > 0")
    .col-md-12
      label.control-label(v-uiv-tooltip.top="newsletterSubscriptionsTooltip")
        | {{ "newsletter.subscription.list_title" | i18n }}
        span.info-bubble
      subscriptions-selector(v-model="role.definition_ids", :definitions="definitions")

  div(slot="footer")
    button.btn(@click="cancel()") {{ "actions.cancel" | i18n }}
    button.btn.btn-primary(type="submit", @click="invite()") {{ "company_admin.invite_users.invite" | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import TeamMembershipTable from "./team-membership-table.vue";
import FilterSelector from "@/components/filter-selector.vue";
import ConfirmButton from "@/components/confirm-button.vue";
import AllowedReports from "@/permissions/allowed_reports.vue";
import PermissionsEditor from "@/permissions/permissions-editor.vue";
import PillList from "@/components/pill-list.vue";
import Team from "@/model/team";
import NewsletterDefinition from "@/model/newsletter-definition";
import i18n from "@/i18n";
import Actions from "@/store/actions";
import MenuItem from "@/interfaces/menu-item";
import adminCompaniesApi from "@/api/admin-companies-api";
import adminUsersApi from "@/api/admin-users-api";
import UserCompanyRole from "@/model/user-company-role";
import DashboardsApiService from "@/api/dashboards-api-service";
import { calculateEffectivePermissions } from "@/permissions/utils";
import _ from "lodash";
import PillItem from "@/interfaces/pill-item";
import DefaultParameterSelector from "./default-parameter-selector.vue";
import SubscriptionsSelector from "./subscriptions-selector.vue";
import Spinner from "@/components/spinner.vue";

@Component({
  components: {
    TeamMembershipTable,
    FilterSelector,
    ConfirmButton,
    AllowedReports,
    PermissionsEditor,
    PillList,
    DefaultParameterSelector,
    SubscriptionsSelector,
    Spinner,
  },
})
export default class UserInviteDialog extends Vue {
  @Prop()
  teams: Team[];

  @Prop()
  definitions: NewsletterDefinition[];

  open = false;
  saving = false;
  error: string = null;
  role: UserCompanyRole = null;
  restrictedDashboards: any[] = [];
  restrictedReports: any[] = [];
  companyDashboards: any[] = [];
  subscriptions: PillItem[] = null;

  get permissionsTooltip() {
    return i18n.t("setup.users.tooltips.permissions");
  }

  get defaultDashboardTooltip() {
    return i18n.t("setup.users.tooltips.default_dashboard");
  }

  get allowedDashboardsTooltip() {
    return i18n.t("setup.users.tooltips.allowed_dashboards");
  }

  get allowedReportsTooltip() {
    return i18n.t("setup.users.tooltips.allowed_reports");
  }

  get defaultParametersTooltip() {
    return i18n.t("setup.users.tooltips.default_parameters");
  }

  get newsletterSubscriptionsTooltip() {
    return i18n.t("setup.users.tooltips.newsletter_subscriptions");
  }

  get teamsTooltip() {
    return i18n.t("setup.users.tooltips.teams");
  }

  get companyId() {
    return window.zoinedContext.companyId;
  }

  get company() {
    return window.zoinedContext.company;
  }

  get currencyOptions() {
    return [
      { currency: "", label: i18n.t("activerecord.attributes.company.company_currency") },
      ...window.zoinedContext.currencies.map(({ currency, symbol }) => ({
        currency,
        label: `${currency} (${symbol})`,
      })),
    ];
  }

  get roleOptions() {
    return ["email", "user", "admin"].map((id) => {
      return { id, name: i18n.t(`roles.${id}`) };
    });
  }

  get localeOptions() {
    return this.$store.getters.getParameters("locales");
  }

  get availableSubcriptions(): MenuItem[] {
    return (
      this.definitions?.map((definition) => {
        return {
          id: definition.id.toString(),
          name: definition.title,
        };
      }) || []
    );
  }

  get userTeams() {
    return this.role.team_memberships.map(({ team_id }) => _.find(this.teams, (team) => team.id === team_id));
  }

  defaultRoleOptions(): Partial<UserCompanyRole> {
    return {
      locale: "",
      role: "user",
      permissions: {
        dimensions: { store: { allow: "all" } },
        metrics: { allow: "all" },
        groupings: { allow: "all" },
      },
      deny_export: false,
      newsletter_currency: "",
      default_dashboard_id: null,
      permitted_reports: [],
      permitted_dashboards: [],
      team_memberships: [],
      default_parameters: {},
      definition_ids: [],
    };
  }

  created() {
    this.$store.dispatch(Actions.fetchParameters("locales"));

    adminCompaniesApi.getRestrictedDashboards({ id: this.companyId }).then((r) => {
      this.restrictedDashboards = r;
    });

    adminCompaniesApi.getRestrictedReports({ id: this.companyId }).then((r) => {
      this.restrictedReports = r;
    });

    new DashboardsApiService().getCompanyDashboards().then((dashboards) => (this.companyDashboards = dashboards));
  }

  show() {
    this.role = this.defaultRoleOptions() as UserCompanyRole;
    this.updateEffectivePermissions();

    this.open = true;
  }

  updateTeamMemberships(teamMemberships) {
    this.role.team_memberships = teamMemberships;
    this.updateEffectivePermissions();
  }

  updateExport(denyExport) {
    this.role.deny_export = denyExport;
  }

  updatePermissions(permissions) {
    this.role.permissions = permissions;
    this.updateEffectivePermissions();
  }

  updatePermittedReports(reports) {
    this.role.permitted_reports = reports;
  }

  updatePermittedDashboards(dashboards) {
    this.role.permitted_dashboards = dashboards;
  }

  updateEffectivePermissions() {
    this.role.effective_permissions = calculateEffectivePermissions(this.role, this.userTeams);
  }

  invite() {
    this.saving = true;
    this.role.permitted_dashboard_ids = this.role.permitted_dashboards.map(({ id }) => id);
    this.role.permitted_report_ids = this.role.permitted_reports.map(({ id }) => id);
    adminUsersApi
      .invite({ company_id: this.companyId, user: this.role })
      .then((role) => {
        this.error = null;
        this.open = false;
        this.$emit("add", role);
      })
      .catch((err) => {
        this.error = err.response.data.error;
      })
      .finally(() => {
        this.saving = false;
      });
  }

  cancel() {
    this.open = false;
  }
}
</script>

<style lang="scss" scoped>
label.control-label {
  margin-top: 15px;
}
</style>
