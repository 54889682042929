<template lang="pug">
component.btn.btn-default.btn-dark-new-layout.btn-sm.btn-icon.edit-report-button.report-action-button(
  :is="component"
  v-bind="componentAttrs" 
  v-uiv-tooltip.hover="editTooltip" 
  @click="editReport"
  @confirm="editReport"
) 
  i.fa.fa-edit.fa-lg
</template>

<script lang="ts">
import i18n from "../i18n";
import Vue from "vue";
import Component from "vue-class-component";
import ConfirmButton from "./confirm-button.vue";
import EventBus from "../events/event-bus";
import Events from "../events/events";

@Component({
  components: { ConfirmButton },
})
export default class EditReportButton extends Vue {
  get hasChanges(): boolean {
    return this.$store.getters.hasChanges;
  }

  get confirm() {
    return this.hasChanges;
  }

  get component() {
    return this.confirm ? ConfirmButton : "button";
  }

  get componentAttrs() {
    return this.confirm
      ? {
          tag: "button",
          popoverMessage: i18n.t("dashboard_custom.edit_confirm_message"),
        }
      : {};
  }

  get editTooltip() {
    return i18n.t("actions.edit");
  }

  editReport() {
    EventBus.emit(Events.editReport);
  }
}
</script>
