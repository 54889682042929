<template lang="pug">
ul.selector.list-inline
  li.title(v-if="title") {{ 'filter.config.limit_to_business_hours' | i18n }}
  li(style="padding-right: 0px;")
    btn-group(:class="{shaded: isDefault}")
      btn.btn-sm(type="primary" input-type="radio", :input-value="true", v-model="model", @click="update(true)") {{ 'options.yes' | i18n }}
      btn.btn-sm(type="primary" input-type="radio", :input-value="false", v-model="model", @click="update(false)") {{ 'options.no' | i18n }}
</template>

<script lang="ts">
import _ from "lodash";
import { Btn, BtnGroup } from "uiv";
import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Btn,
    BtnGroup,
  },
})
export default class BusinessHoursSelector extends Vue {
  @Prop({ default: null })
  value: boolean;

  @Prop({ default: null })
  default: boolean;

  @Prop({ default: true })
  title: boolean;

  model: boolean = false;

  get isDefault(): boolean {
    return !_.isBoolean(this.value) && _.isBoolean(this.default);
  }

  update(value) {
    this.$emit("update", value);
  }

  created() {
    if (_.isBoolean(this.value)) {
      this.model = this.value;
    } else if (_.isBoolean(this.default)) {
      this.model = this.default;
    }
  }
}
</script>
<style lang="scss" scoped>
.shaded {
  opacity: 0.5;
}
</style>
