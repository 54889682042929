<template lang="pug">
.min-amount-selector
  .title {{ title }}
  pill-button(
    :enabled="item.enabled", 
    :label="item.value",
    @toggle="toggleItem(item)",
    @remove="removeItem(item)",
    v-for="item in items", :key="item.value"
    )
  dropdown(ref="dropdown", v-model="open", :not-close-elements="notCloseElements")
    pill-button.add-button(:removable="false")
      i.fa.fa-lg.fa-plus
    div(slot="dropdown")
      form(novalidate, @submit.prevent="addItem()")
        label {{ 'actions.set' | i18n }} {{ title }}
        input.form-control(type="number" v-model="minamount")
        .form-buttons
          button.btn.btn-default(type="button", @click="open = false") {{ 'actions.cancel' | i18n }}
          button.btn.btn-primary(type="submit", :disabled="!isFormValid") {{ 'actions.add' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Dropdown } from "uiv";
import pillButton from "./pill-button.vue";
import { Prop, Ref, Watch } from "vue-property-decorator";
import FilterConfigurationItemMap, {
  filterConfigurationItemMapFromPillItemList,
} from "../model/filter-configuration-item-map";
import PillItem, { pillItemListFromFilterConfigurationItemMap } from "../interfaces/pill-item";
import i18n from "../i18n";

@Component({
  components: {
    Dropdown,
    pillButton,
  },
})
export default class MinAmountSelector extends Vue {
  @Ref()
  dropdown: Dropdown;

  @Prop()
  config: FilterConfigurationItemMap;

  @Prop({ default: () => i18n.t("filter.config.minamount") })
  title: string;

  open = false;
  notCloseElements = [];
  minamount: number = null;

  get isFormValid() {
    return this.minamount !== null;
  }

  get items(): PillItem[] {
    return pillItemListFromFilterConfigurationItemMap(this.config);
  }

  addItem() {
    if (!this.isFormValid) {
      return;
    }

    const value = this.minamount.toString();
    const items: PillItem[] = [
      ...this.items.map((item) => ({ ...item, enabled: false })),
      { value, name: value, enabled: true },
    ];
    const config = filterConfigurationItemMapFromPillItemList(items);

    this.$emit("update", config);

    this.open = false;
  }

  removeItem(item: PillItem) {
    const config = { ...this.config };
    delete config[item.value];
    this.$emit("update", config);
  }

  toggleItem(item: PillItem) {
    const items = this.items.map((each) =>
      each === item ? { ...item, enabled: !item.enabled } : { ...each, enabled: false }
    );
    const config = filterConfigurationItemMapFromPillItemList(items);
    this.$emit("update", config);
  }

  mounted() {
    console.log(this.dropdown.$el);
    this.notCloseElements.push(this.dropdown.$el);
  }

  @Watch("open")
  onChangeOpen() {
    if (this.open) {
      this.minamount = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: inline-block;
  margin-right: 10px;
}
.add-button {
  padding: 4px 7px !important;
}

form {
  padding: 6px;
  width: 300px;

  .form-buttons {
    padding-top: 10px;
    text-align: right;

    .btn + .btn {
      margin-left: 5px;
    }
  }
}
</style>
