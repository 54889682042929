<template lang="pug">
.show-filters.flex-row.align-items-center.overflow-hidden(v-if="!open")
  .filters-display.show-new-layout
    flyover-filters-display(@click="show")
  a.fo-handle.show-filters.collapsed(@click="show")
    .show-text.flex-row.align-items-center
      flyover-handle-list.hide-new-layout
      svg-icon.show-new-layout.controls(name="controls")
      span.text.show-new-layout {{ 'filters.title' | i18n }}
      span.text.hide-new-layout {{ 'filters.show_filters' | i18n }}
      svg-icon.show-new-layout.chevron(name="chevron-down")
      i.fa.hide-new-layout
.hide-filters.show-new-layout(v-else)
  a.fo-handle.hide-filters(@click="hide")
    .flex-row.align-items-center
      svg-icon.controls(name="controls")
      span.text {{ 'filters.title' | i18n }}
      svg-icon.chevron(name="chevron-up")
</template>

<script lang="ts">
import Mutations from "../store/mutations";
import Vue from "vue";
import Component from "vue-class-component";
import FlyoverHandleList from "./flyover-handle-list.vue";
import FlyoverFiltersDisplay from "./flyover-filters-display.vue";
import SvgIcon from "../components/svg-icon.vue";

@Component({
  components: {
    FlyoverHandleList,
    FlyoverFiltersDisplay,
    SvgIcon,
  },
})
export default class FlyoverFiltersHandle extends Vue {
  get open() {
    return this.$store.state.flyoverFilters.open;
  }

  show() {
    this.$store.commit(Mutations.openFlyoverFilters);
    this.$nextTick(() => {
      $("html,body").animate(
        {
          scrollTop: 0,
        },
        200
      );
    });
  }

  hide() {
    this.$store.commit(Mutations.closeFlyoverFilters);
  }
}
</script>

<style lang="scss" scoped>
.filters-display {
  overflow: hidden;

  .flyover-handle-list {
    background-color: #656566;
    border-radius: 9999px;
    padding: 0px 15px;
    height: 25px;
    color: white;
    font-size: 10px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.fo-handle {
  svg.controls {
    margin-right: 10px;
  }
  svg.chevron {
    margin-left: 10px;
  }
}
</style>
