<!-- Pill list component with (+) button and modal -->
<template lang="pug">
.pill-list(v-if="disabled")
  .title(v-if="title") {{ title }}
  pill-button.shaded(v-if="!items" :enabled="item.enabled", :exclude="item.exclude" :label="itemLabel(item)", :pill-style="pillStyle", v-for="item in defaults", :key="item.value")
  pill-button.shaded(:enabled="item.enabled", :exclude="item.exclude", :label="itemLabel(item)", :pill-style="pillStyle", v-for="item in model", :key="item.value")
  pill-button.shaded.add-button(:removable="false")
    i.fa.fa-lg.fa-plus
  pill-button.shaded.exclude-button(v-if="excludable", :removable="false")
    i.fa.fa-lg.fa-minus
.pill-list(v-else)
  .title(v-if="title") {{ title }}
  pill-button.shaded(v-if="!items" :enabled="item.enabled", :exclude="item.exclude" :label="itemLabel(item)", :pill-style="pillStyle", @remove="removeDefaultItem(item)" v-for="item in defaults", :key="item.value")
  draggable.draggable(v-model="model", :delay="100", :disabled="!draggable", @update="onOrderUpdated")
    pill-button(:enabled="item.enabled", :exclude="item.exclude", :label="itemLabel(item)", :pill-style="pillStyle", @toggle="toggleItem(item)", @remove="removeItem(item.value)" v-for="item in model", :key="item.value")
  item-menu-dropdown(
    :menu-open="menuOpen", 
    :items="menuItems", 
    :typeahead="typeahead", 
    :searchable="searchable", 
    :multi-select="multiSelect", 
    :deleteable="deleteable", 
    :title="title", 
    :selected-items="selectedItems",
    :wildcard="wildcard",
    @select="addItem", 
    @deselect="removeItem($event.key)", 
    @search="onSearch($event)", 
    @delete="$emit('delete',$event.key)"
    @update:menu-open="$emit('update:menu-open', $event)")
    pill-button.add-button(:removable="false", @toggle="onToggleMenu")
      i.fa.fa-lg.fa-plus
    template(slot="footer" v-if="$slots['menu-footer']")
      slot(name="menu-footer")
  item-menu-dropdown(v-if="excludable", 
    :items="excludeMenuItems", 
    :typeahead="typeahead", 
    :searchable="searchable", 
    :multi-select="multiSelect", 
    :selected-items="excludedItems", 
    :wildcard="wildcard",
    @select="excludeItem", 
    @deselect="removeItem($event.key)", 
    @search="onSearch($event)")
    pill-button.exclude-button(:removable="false", @toggle="onToggleMenu")
      i.fa.fa-lg.fa-minus
</template>

<script lang="ts">
import pillButton from "./pill-button.vue";
import itemMenuDropdown from "./item-menu-dropdown.vue";
import draggable from "vuedraggable";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import _ from "lodash";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import PillItem from "../interfaces/pill-item";

@Component({
  components: {
    pillButton,
    itemMenuDropdown,
    draggable,
  },
})
export default class PillList extends Vue {
  @Prop()
  title: string;

  @Prop()
  defaults: PillItem[];

  @Prop()
  items: PillItem[];

  @Prop()
  availableItems: MenuItem[];

  @Prop({ default: true })
  togglable: boolean;

  @Prop()
  itemLabelFn: Function;

  @Prop({ default: false })
  excludable: boolean;

  @Prop({ default: false })
  radio: boolean;

  @Prop({ default: false })
  typeahead: boolean;

  @Prop({ default: true })
  searchable: boolean;

  @Prop({ default: false })
  multiSelect: boolean;

  @Prop({ default: false })
  menuOpen: boolean;

  @Prop({ default: false })
  deleteable: boolean;

  @Prop({ default: false })
  draggable: boolean;

  @Prop({ default: "pill" })
  pillStyle: string;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: false })
  wildcard: boolean;

  model: PillItem[] = [];

  get selectedItems(): PillItem[] {
    return (this.items || []).filter(({ exclude }) => !exclude);
  }

  get excludedItems(): PillItem[] {
    return (this.items || []).filter(({ exclude }) => exclude);
  }

  get menuItems(): MenuItem[] {
    return (
      this.availableItems &&
      this.availableItems.filter((availableItem) =>
        this.multiSelect
          ? !this.excludedItems.some((item) => item.value === menuItemKey(availableItem))
          : !_.some(this.items, (item) => item.value === menuItemKey(availableItem))
      )
    );
  }

  get excludeMenuItems(): MenuItem[] {
    return (
      this.availableItems &&
      this.availableItems.filter((availableItem) =>
        this.multiSelect
          ? !this.selectedItems.some((item) => item.value === menuItemKey(availableItem))
          : !_.some(this.items, (item) => item.value === menuItemKey(availableItem))
      )
    );
  }

  itemLabel(item: PillItem) {
    return (
      (this.itemLabelFn && this.itemLabelFn(item)) ||
      _.get(
        _.find(this.availableItems, (menuItem) => menuItemKey(menuItem) == item.value),
        "name"
      ) ||
      item.name ||
      item.value
    );
  }

  onToggleMenu() {
    this.$emit("toggleMenu");
  }

  onToggleExcludeMenu() {
    this.$emit("toggleMenu");
  }

  onSearch(str) {
    this.$emit("search", str);
  }

  onOrderUpdated() {
    this.$emit("update", { items: this.model });
  }

  addItem(item: MenuItem) {
    const currentItems = (this.items || []).map((item) => ({
      ...item,
      enabled: this.radio ? false : item.enabled,
    }));
    const items: PillItem[] = [
      ...currentItems,
      { value: menuItemKey(item), name: item.name, enabled: true, wildcard: item.wildcard },
    ];
    this.$emit("update", { items });
  }

  excludeItem(item: MenuItem) {
    const items: PillItem[] = [
      ...(this.items || []),
      { value: menuItemKey(item), name: item.name, enabled: true, exclude: true, wildcard: item.wildcard },
    ];
    this.$emit("update", { items });
  }

  removeItem(value: string) {
    const items: PillItem[] = this.items.filter((item) => item.value !== value);

    this.$emit("update", { items });
  }

  removeDefaultItem(item: PillItem) {
    const items: PillItem[] = this.defaults.filter((each) => each.value !== item.value);
    this.$emit("update", { items });
  }

  toggleItem(item: PillItem) {
    if (!this.togglable) {
      return;
    }
    const items: PillItem[] = this.items.map((each) => {
      const enabled = each === item ? !each.enabled : this.radio ? false : each.enabled;
      return { ...each, enabled };
    });
    this.$emit("update", { items });
  }

  created() {
    this.updateModel();
  }

  @Watch("items")
  updateModel() {
    this.model = [].concat(this.items || []);
  }
}
</script>

<style lang="scss" scoped>
.pill-list {
  display: inline-block;
  margin-bottom: 10px;
}
.pill-list:not(:last-child) {
  margin-right: 15px;
}
.title {
  display: inline-block;
  margin-right: 10px;
}

.add-button,
.exclude-button {
  padding: 4px 7px !important;
}

.shaded {
  opacity: 0.5;
}

.draggable {
  display: inline-block;
}
</style>
