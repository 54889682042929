<template lang="pug">
.newsletter-section-alert-conditions(v-if="newCondition")
  .conditions
    span.condition(v-for="(condition, index) in conditions")
      b(v-if="index != 0") {{ "common.or" | i18n }}
      pill-button(:label="conditionText(condition)", @remove="onRemove(condition)")
    pill-button.add-button(:removable="false", @toggle="modalOpen = true")
      i.fa.fa-lg.fa-plus
  modal(v-model="modalOpen", :title="'newsletter.definition.alert.alert_condition' | i18n", @show="onOpenModal")
    div(slot="default")
      .add-condition-form(v-if="newCondition")
        h4 {{ "filter.config.metrics" | i18n }}
        filter-dropdown(
          :value="newCondition.metric",
          :default="defaultMetric",
          :select-label="'Default metric'",
          :available-items="availableMetrics",
          @update="newCondition.metric = $event"
        )
        h4 {{ "newsletter.definition.alert.comparison_left" | i18n }}
        filter-dropdown(
          :value="comparisonToMenuKey(newCondition.comparison_left)",
          :available-items="availableComparisons",
          @update="newCondition.comparison_left = menuKeyToComparison($event)"
        )
        h4 {{ "newsletter.definition.alert.comparison_right" | i18n }}
        filter-dropdown(
          :value="comparisonToMenuKey(newCondition.comparison_right)",
          :available-items="availableComparisons",
          @update="newCondition.comparison_right = menuKeyToComparison($event)"
        )
        h4 {{ "newsletter.definition.alert.condition" | i18n }}
        .input-group(v-if="newCondition.comparison_right.type != 'no_comparison'")
          b {{ comparisonLabel(newCondition.comparison_left) }}
          b {{ "common.is" | i18n }}
          input.value(type="number", v-model="newCondition.value")
          filter-dropdown(
            :value="newCondition.condition_type",
            :available-items="conditionTypes",
            @update="newCondition.condition_type = $event"
          )
          filter-dropdown(
            :value="newCondition.compare",
            :available-items="compareTypes",
            @update="newCondition.compare = $event"
          )
          b {{ comparisonLabel(newCondition.comparison_right) }}
        .input-group(v-if="newCondition.comparison_right.type == 'no_comparison'")
          b {{ comparisonLabel(newCondition.comparison_left) }}
          b {{ "common.is" | i18n }}
          filter-dropdown(
            :value="newCondition.compare",
            :available-items="compareTypes",
            @update="newCondition.compare = $event"
          )
          input.value(type="number", v-model="newCondition.value")

    div(slot="footer")
      button.btn.btn-primary(:disabled="!isValid", @click="onAdd") {{ "actions.add" | i18n }}
      button.btn.btn-default(@click="modalOpen = false") {{ "actions.cancel" | i18n }}
</template>
<script>
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import pillButton from "../components/pill-button";
import { comparisonToMenuKey, menuKeyToComparison } from "../lib/menu-helper.ts";
import { Modal } from "uiv";
import FilterDropdown from "../components/filter-dropdown";
import i18n from "@/i18n";

const CONDITION_TYPE_PERCENT = "percent";
const CONDITION_TYPE_UNIT = "unit";

@Component({
  components: {
    pillButton,
    FilterDropdown,
    Modal,
  },
  props: {
    config: Object,
    defaultMetric: String,
  },
})
export default class NewsletterSectionAlertConditions extends Vue {
  modalOpen = false;
  newCondition = null;

  get loaded() {
    return this.availableMetrics && this.availableComparisons;
  }

  get availableMetrics() {
    return this.$store.getters.getParameters("metrics");
  }

  get conditionTypes() {
    return [CONDITION_TYPE_PERCENT, CONDITION_TYPE_UNIT].map((key) => ({
      key,
      name: i18n.t(`newsletter.definition.alert.condition_types.${key}`),
    }));
  }

  get compareTypes() {
    return ["gt", "lt"].map((key) => ({
      key,
      name: i18n.t(`newsletter.definition.alert.compare_options.${key}`),
    }));
  }

  get availableComparisons() {
    const comparisons = this.$store.getters.getParameters("comparisons");
    return (
      comparisons && [
        {
          key: "no_comparison",
          name: i18n.t("filters.benchmark.no_comparison"),
        },

        { key: "actual", name: i18n.t("filters.date_selector.actual") },
        ...comparisons.map(({ type, label, time_period, name }) => ({
          key: comparisonToMenuKey({ type, label, time_period }),
          name,
        })),
      ]
    );
  }

  get conditions() {
    return this.config ? this.config.conditions : [];
  }

  comparisonToMenuKey(comparison) {
    return comparisonToMenuKey(comparison);
  }

  menuKeyToComparison(menuKey) {
    return menuKeyToComparison(menuKey);
  }

  conditionText(condition) {
    if (!this.loaded) {
      return;
    }
    const { metric, comparison_left, value, condition_type, compare, comparison_right } = condition;

    const metricLabel = metric ? this.availableMetrics.find(({ key }) => key == metric).name : "";
    const comparisonLeftLabel = this.comparisonLabel(comparison_left);
    const compareLabel = i18n.t(`newsletter.definition.alert.compare_options.${compare}`);

    if (comparison_right && comparison_right.type != "no_comparison") {
      const conditionTypeLabel = i18n.t(`newsletter.definition.alert.condition_types.${condition_type}`);

      const comparisonRightLabel = this.comparisonLabel(comparison_right);

      return i18n.t("newsletter.definition.alert.alert_description", {
        metric: metricLabel,
        comparison_left: comparisonLeftLabel,
        value,
        condition_type: conditionTypeLabel,
        compare: compareLabel,
        comparison_right: comparisonRightLabel,
      });
    } else {
      return i18n.t("newsletter.definition.alert.alert_description_single_comparison", {
        metric: metricLabel,
        comparison_left: comparisonLeftLabel,
        value,
        compare: compareLabel,
      });
    }
  }

  comparisonLabel(comparison) {
    return this.availableComparisons.find(({ key }) => key == comparisonToMenuKey(comparison)).name;
  }

  get isValid() {
    const { value } = this.newCondition;
    return Number(value) >= 0;
  }

  onAdd() {
    const conditions = [...this.conditions, this.newCondition];
    this.$emit("update", { conditions });
    this.modalOpen = false;
  }

  onRemove(condition) {
    const conditions = this.conditions.filter((c) => c !== condition);
    this.$emit("update", { conditions });
  }

  onOpenModal() {
    if (this.loaded) {
      this.clearCondition();
    }
  }

  clearCondition() {
    this.newCondition = {
      metric: null,
      comparison_left: menuKeyToComparison(this.availableComparisons[1].key),
      condition_type: CONDITION_TYPE_UNIT,
      compare: "gt",
      comparison_right: menuKeyToComparison(this.availableComparisons[0].key),
    };
  }

  @Watch("newCondition.comparison_right")
  onUpdateComparisonRight(comparisonRight) {
    if (comparisonRight.type == "no_comparison") {
      this.newCondition.condition_type = CONDITION_TYPE_UNIT;
    } else {
      this.newCondition.condition_type = CONDITION_TYPE_PERCENT;
    }
  }

  @Watch("loaded", { immediate: true })
  onLoaded(loaded) {
    if (loaded) {
      this.clearCondition();
    }
  }
}
</script>
<style lang="scss" scoped>
.add-button {
  padding: 4px 7px !important;
}
.condition {
  > *:not(:last-child) {
    margin-right: 5px;
  }
}
.add-condition-form {
  input.value {
    width: 80px;
    display: inline-block;
  }
  .input-group > * {
    margin-right: 10px !important;
  }

  .actions {
    > * {
      margin-right: 5px;
    }
  }
}
</style>
