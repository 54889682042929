import TranslationService from "../core/translation.service";
import ErrorReportingService from "../core/error-reporting.service";
import moment, { Moment } from "moment";

declare const I18n: any;
declare const $: any;

const errorReportingService = new ErrorReportingService();

export const defaultTimePeriodIds = [
  "4_weeks",
  "yesterday",
  "12_months",
  "today",
  "last_7_days",
  "last_14_days",
  "last_30_days",
  "last_90_days",
  "last_365_days",
  "last_and_next_7_days",
  "rolling_12_weeks",
  "rolling_3_months",
  "current_year",
  "current_month",
  "current_week",
  "current_full_year",
  "current_full_month",
  "current_full_week",
  "last_year",
  "last_month",
  "last_week",
  "next_30_days",
  "next_3_months",
  "next_6_months",
];

export const defaultComparisonTypes = [
  "prev_year_corresponding",
  "last_year",
  "2_years_back_corresponding",
  "3_years_back_corresponding",
  "prev_year_corresponding_same_weekday_ending",
  "prev_year_full_month",
  "previous_corresponding_period",
  "previous_period",
  "previous_year_lfl",
  "2_years_back_lfl",
  "3_years_back_lfl",
];

export const timeComparisonTypes = [
  "prev_year_corresponding",
  "last_year",
  "2_years_back_corresponding",
  "3_years_back_corresponding",
  "prev_year_corresponding_same_weekday_ending",
  "prev_year_same_days",
  "prev_year_full_month",
  "previous_corresponding_period",
  "previous_period",
  "previous_year_lfl",
  "2_years_back_lfl",
  "3_years_back_lfl",
  "custom",
];

// Helper for fast date to string conversions.
function _pad2(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}

// Fastest way to stringify just the date.
// Operate in local time.
function _dateToDB(date) {
  if (date instanceof Date) {
    if (Number.isNaN(date.valueOf())) {
      return null;
    }
    return date.getFullYear() + "-" + _pad2(date.getMonth() + 1) + "-" + _pad2(date.getDate());
  } else if (typeof date === "string") {
    return date;
  } else {
    return null;
  }
}

// Copied code from filters-datepicker

/*
Calculate corresponding previous period for given date
@param date - Date for which corresponding previous period is to be calculated
@param period - any period string compatible to moment like day, week, month, year etc
@param number - number of previous periods to advance
@returns {Array}
 */

function _lastPeriodRange(date, period, number) {
  var date1, date2;
  if (period === "tertile") {
    date1 = _startOfTertile(moment(date).subtract(number * 4, "month"));
    date2 = _endOfTertile(moment(date).subtract(number * 4, "month"));
  } else {
    date1 = moment(date)
      .subtract(number, period)
      .startOf(period);
    date2 = moment(date)
      .subtract(number, period)
      .endOf(period);
  }
  return [date1.toDate(), date2.toDate()];
}

function _rollingTertiles(date, number) {
  var date1, date2;
  date1 = _startOfTertile(moment(date).subtract(number * 4, "month"));
  date2 = _endOfTertile(moment(date).subtract(4, "month"));
  return [date1.toDate(), date2.toDate()];
}

function _startOfTertile(date) {
  return moment(date)
    .set("month", Math.floor(moment(date).month() / 4) * 4)
    .startOf("month");
}

function _endOfTertile(date) {
  return moment(date)
    .set("month", Math.floor(moment(date).month() / 4) * 4 + 3)
    .endOf("month");
}

/*
Calculate current period till the day before for given date
@param date - Date for which corresponding current period is to be calculated
@param period - any period string compatible to moment like day, week, month, year etc
@returns {Array}
 */

function _currentPeriodRange(date, period) {
  var end, start;
  if (period === "half_year") {
    start =
      moment(date).month() < 6
        ? moment(date).startOf("year")
        : moment(date)
            .startOf("year")
            .add(6, "month");
  } else if (period === "tertile") {
    start =
      moment(date).month() < 4
        ? moment(date).startOf("year")
        : moment(date).month() < 8
        ? moment(date)
            .startOf("year")
            .add(4, "month")
        : moment(date)
            .startOf("year")
            .add(8, "month");
  } else {
    start = moment(date).startOf(period);
  }
  end = _getEndOfYesterday(date);
  if (start.isAfter(end)) {
    end = start;
  }
  return [start.toDate(), end.toDate()];
}

function _currentFullPeriodRange(date, period) {
  var date1, date2;
  date1 = moment(date).startOf(period);
  date2 = moment(date).endOf(period);
  return [date1.toDate(), date2.toDate()];
}

function _getEndOfYesterday(date) {
  return moment(date)
    .subtract(1, "day")
    .endOf("day");
}

function _previousSameLengthToPeriodEnd(start_time, end_time, period) {
  const diff = moment(end_time).diff(moment(start_time), "days");
  const end = moment(end_time)
    .startOf(period)
    .subtract(1, "day");
  const start = moment(end).subtract(diff, "days");
  return [start.toDate(), end.toDate()];
}

function _getDateRange(selectedRangeOption: string, timeRange: any, today?: any) {
  var end, start, weekago, tomorrow;
  if (today == null) {
    today = new Date();
  }
  switch (selectedRangeOption) {
    case "4_weeks":
      return [
        moment(today)
          .subtract(4, "week")
          .startOf("week")
          .toDate(),
        moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate(),
      ];
    case "rolling_3_weeks":
      return [
        moment(today)
          .subtract(3, "week")
          .startOf("week")
          .toDate(),
        moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate(),
      ];
    case "last_52_weeks":
      return [
        moment(today)
          .subtract(52, "week")
          .startOf("week")
          .toDate(),
        moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate(),
      ];
    case "yesterday":
      return [
        moment(today)
          .subtract(1, "day")
          .startOf("day")
          .toDate(),
        moment(today)
          .subtract(1, "day")
          .endOf("day")
          .toDate(),
      ];
    case "12_months":
      return [
        moment(today)
          .subtract(12, "month")
          .startOf("month")
          .toDate(),
        moment(today)
          .subtract(1, "month")
          .endOf("month")
          .toDate(),
      ];
    case "rolling_6_months":
      return [
        moment(today)
          .subtract(6, "month")
          .startOf("month")
          .toDate(),
        moment(today)
          .subtract(1, "month")
          .endOf("month")
          .toDate(),
      ];
    case "rolling_2_tertiles":
      return _rollingTertiles(today, 2);
    case "rolling_3_tertiles":
      return _rollingTertiles(today, 3);
    case "rolling_2_quarters":
      return [
        moment(today)
          .subtract(2, "quarter")
          .startOf("quarter")
          .toDate(),
        moment(today)
          .subtract(1, "quarter")
          .endOf("quarter")
          .toDate(),
      ];
    case "rolling_3_quarters":
      return [
        moment(today)
          .subtract(3, "quarter")
          .startOf("quarter")
          .toDate(),
        moment(today)
          .subtract(1, "quarter")
          .endOf("quarter")
          .toDate(),
      ];
    case "rolling_4_quarters":
      return [
        moment(today)
          .subtract(4, "quarter")
          .startOf("quarter")
          .toDate(),
        moment(today)
          .subtract(1, "quarter")
          .endOf("quarter")
          .toDate(),
      ];
    case "today":
    case "today_until_last_full_hour":
    case "last_full_hour":
      return [
        moment(today)
          .startOf("day")
          .toDate(),
        moment(today)
          .endOf("day")
          .toDate(),
      ];
    case "last_7_days":
      return [
        moment(today)
          .subtract(7, "days")
          .startOf("day")
          .toDate(),
        _getEndOfYesterday(today).toDate(),
      ];
    case "last_14_days":
      return [
        moment(today)
          .subtract(14, "days")
          .startOf("day")
          .toDate(),
        _getEndOfYesterday(today).toDate(),
      ];
    case "last_28_days":
      return [
        moment(today)
          .subtract(28, "days")
          .startOf("day")
          .toDate(),
        _getEndOfYesterday(today).toDate(),
      ];
    case "last_30_days":
      return [
        moment(today)
          .subtract(30, "days")
          .startOf("day")
          .toDate(),
        _getEndOfYesterday(today).toDate(),
      ];
    case "last_90_days":
      return [
        moment(today)
          .subtract(90, "days")
          .startOf("day")
          .toDate(),
        _getEndOfYesterday(today).toDate(),
      ];
    case "last_180_days":
      return [
        moment(today)
          .subtract(180, "days")
          .startOf("day")
          .toDate(),
        _getEndOfYesterday(today).toDate(),
      ];
    case "last_365_days":
      return [
        moment(today)
          .subtract(365, "days")
          .startOf("day")
          .toDate(),
        _getEndOfYesterday(today).toDate(),
      ];
    case "last_7_days_until_today":
      return [
        moment(today)
          .subtract(6, "days")
          .startOf("day")
          .toDate(),
        moment(today).toDate(),
      ];
    case "last_14_days_until_today":
      return [
        moment(today)
          .subtract(13, "days")
          .startOf("day")
          .toDate(),
        moment(today).toDate(),
      ];
    case "last_30_days_until_today":
      return [
        moment(today)
          .subtract(29, "days")
          .startOf("day")
          .toDate(),
        moment(today).toDate(),
      ];
    case "last_90_days_until_today":
      return [
        moment(today)
          .subtract(89, "days")
          .startOf("day")
          .toDate(),
        moment(today).toDate(),
      ];
    case "last_365_days_until_today":
      return [
        moment(today)
          .subtract(364, "days")
          .startOf("day")
          .toDate(),
        moment(today).toDate(),
      ];
    case "last_and_next_7_days":
      return [
        moment(today)
          .subtract(7, "days")
          .startOf("day")
          .toDate(),
        moment(today)
          .add(7, "days")
          .endOf("day")
          .toDate(),
      ];
    case "last_2_years":
      return [
        moment(today)
          .subtract(2, "years")
          .startOf("day")
          .toDate(),
        _getEndOfYesterday(today).toDate(),
      ];
    case "rolling_12_weeks":
      return [
        moment(today)
          .subtract(12, "week")
          .startOf("week")
          .toDate(),
        moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate(),
      ];
    case "rolling_3_months":
      return [
        moment(today)
          .subtract(3, "month")
          .startOf("month")
          .toDate(),
        moment(today)
          .subtract(1, "month")
          .endOf("month")
          .toDate(),
      ];
    case "current_year":
      return _currentPeriodRange(today, "year");
    case "current_month":
      return _currentPeriodRange(today, "month");
    case "current_half_year":
      return _currentPeriodRange(today, "half_year");
    case "current_quarter":
      return _currentPeriodRange(today, "quarter");
    case "current_tertile":
      return _currentPeriodRange(today, "tertile");
    case "current_week":
      return _currentPeriodRange(today, "week");
    case "current_full_year":
      return _currentFullPeriodRange(today, "year");
    case "current_full_month":
      return _currentFullPeriodRange(today, "month");
    case "current_full_week":
      return _currentFullPeriodRange(today, "week");
    case "last_year":
      return _lastPeriodRange(today, "year", 1);
    case "last_month":
      return _lastPeriodRange(today, "month", 1);
    case "last_week":
      weekago = moment(today).subtract(1, "week");
      return [
        moment(weekago)
          .startOf("week")
          .toDate(),
        moment(weekago)
          .endOf("week")
          .toDate(),
      ];
    case "last_tertile":
      return _lastPeriodRange(today, "tertile", 1);
    case "last_quarter":
      return _lastPeriodRange(today, "quarter", 1);
    case "custom":
      return timeRange;
    case "custom_until_today":
      return [timeRange[0], moment(today).toDate()];
    case "custom_until_yesterday":
      return [timeRange[0], _getEndOfYesterday(today).toDate()];
    case "whole_history":
      return [
        moment("2000-01-01").toDate(),
        _getEndOfYesterday(today)
          .endOf("day")
          .toDate(),
      ];
    case "custom_current_full_year":
    case "custom_current_full_month":
    case "custom_current_full_season":
    case "custom_current_full_week":
      return _getPeriodAmount(selectedRangeOption, 0);
    case "custom_current_year":
    case "custom_current_month":
    case "custom_current_season":
    case "custom_current_week":
      start = _getPeriodAmount(selectedRangeOption, 0)[0];
      end = _getEndOfYesterday(today).toDate();
      if (start > end) {
        end = start;
      }
      return [start, end];
    case "custom_last_month":
    case "custom_last_season":
    case "custom_last_week":
      return _getPeriodAmount(selectedRangeOption, -1);
    case "custom_current_retail_week_until_today":
      return [
        _getPeriodAmount(selectedRangeOption, 0)[0],
        _getEndOfYesterday(today)
          .endOf("day")
          .toDate(),
      ];
    case "custom_last_year":
      return _getYearAmount(selectedRangeOption, -1);
    case "custom_rolling_12_weeks":
    case "custom_rolling_12_months":
      return [_getPeriodAmount(selectedRangeOption, -12)[0], _getPeriodAmount(selectedRangeOption, -1)[1]];
    case "custom_rolling_3_months":
      return [_getPeriodAmount(selectedRangeOption, -3)[0], _getPeriodAmount(selectedRangeOption, -1)[1]];
    case "custom_rolling_4_weeks":
      return [_getPeriodAmount(selectedRangeOption, -4)[0], _getPeriodAmount(selectedRangeOption, -1)[1]];
    case "next_30_days":
      return [
        moment(today)
          .add(1, "days")
          .startOf("day")
          .toDate(),
        moment(today)
          .add(30, "days")
          .endOf("day")
          .toDate(),
      ];
    case "next_3_months":
      return [
        moment(today)
          .add(1, "month")
          .startOf("month")
          .toDate(),
        moment(today)
          .add(3, "month")
          .endOf("month")
          .toDate(),
      ];
    case "next_6_months":
      return [
        moment(today)
          .add(1, "month")
          .startOf("month")
          .toDate(),
        moment(today)
          .add(6, "month")
          .endOf("month")
          .toDate(),
      ];
    case "to_end_of_year":
      return [
        moment(today)
          .add(1, "day")
          .toDate(),
        moment(today)
          .endOf("year")
          .toDate(),
      ];
    case "to_end_of_month":
      return [
        moment(today)
          .add(1, "day")
          .toDate(),
        moment(today)
          .endOf("month")
          .toDate(),
      ];
    case "to_end_of_week":
      return [
        moment(today)
          .add(1, "day")
          .toDate(),
        moment(today)
          .endOf("week")
          .toDate(),
      ];
    case "to_end_of_year_from_today":
      return [
        moment(today).toDate(),
        moment(today)
          .endOf("year")
          .toDate(),
      ];
    case "to_end_of_month_from_today":
      return [
        moment(today).toDate(),
        moment(today)
          .endOf("month")
          .toDate(),
      ];
    case "to_end_of_week_from_today":
      return [
        moment(today).toDate(),
        moment(today)
          .endOf("week")
          .toDate(),
      ];
    case "current_year_until_today":
      return [
        moment(today)
          .startOf("year")
          .toDate(),
        moment(today).toDate(),
      ];
    case "current_month_until_today":
      return [
        moment(today)
          .startOf("month")
          .toDate(),
        moment(today).toDate(),
      ];
    case "current_week_until_today":
      return [
        moment(today)
          .startOf("week")
          .toDate(),
        moment(today).toDate(),
      ];
    case "current_year_until_end_of_last_month":
      if (moment(today).month() === 0) {
        return [
          moment(today)
            .subtract(1, "year")
            .startOf("year")
            .toDate(),
          moment(today)
            .subtract(1, "year")
            .endOf("year")
            .toDate(),
        ];
      } else {
        return [
          moment(today)
            .startOf("year")
            .toDate(),
          moment(today)
            .subtract(1, "month")
            .endOf("month")
            .toDate(),
        ];
      }
    case "next_week":
      return [
        moment(today)
          .add(1, "week")
          .startOf("week")
          .toDate(),
        moment(today)
          .add(1, "week")
          .endOf("week")
          .toDate(),
      ];
    case "next_month":
      return [
        moment(today)
          .add(1, "month")
          .startOf("month")
          .toDate(),
        moment(today)
          .add(1, "month")
          .endOf("month")
          .toDate(),
      ];
    case "next_year":
      return [
        moment(today)
          .add(1, "year")
          .startOf("year")
          .toDate(),
        moment(today)
          .add(1, "year")
          .endOf("year")
          .toDate(),
      ];
    case "tomorrow":
      tomorrow = moment(today).add(1, "day");
      return [tomorrow.toDate(), tomorrow.toDate()];
    case "next_7_days":
      return [
        moment(today)
          .add(1, "day")
          .toDate(),
        moment(today)
          .add(7, "days")
          .toDate(),
      ];
    case "next_7_days_from_today":
      return [
        moment(today).toDate(),
        moment(today)
          .add(6, "days")
          .toDate(),
      ];
    case "next_546_days":
      return [
        moment(today)
          .add(1, "day")
          .toDate(),
        moment(today)
          .add(546, "days")
          .toDate(),
      ];
    case "next_546_days_from_today":
      return [
        moment(today).toDate(),
        moment(today)
          .add(545, "days")
          .toDate(),
      ];
    case "last_year_7_days":
      return _getDayRange(
        7,
        moment(today)
          .subtract(52, "weeks")
          .add(1, "day")
      );
    case "last_year_14_days":
      return _getDayRange(
        14,
        moment(today)
          .subtract(52, "weeks")
          .add(1, "day")
      );
    case "last_year_30_days":
      return _getDayRange(
        30,
        moment(today)
          .subtract(52, "weeks")
          .add(1, "day")
      );
    case "last_year_today":
      return _getDayRange(1, moment(today).subtract(52, "weeks"));
    case "last_year_tomorrow":
      return _getDayRange(
        1,
        moment(today)
          .subtract(52, "weeks")
          .add(1, "day")
      );
    case "last_year_4_weeks":
      return _getWeekRange(4, moment(today).subtract(51, "weeks"));
    case "last_year_current_week":
      return _getWeekRange(1, moment(today).subtract(52, "weeks"));
    case "last_year_next_week":
      return _getWeekRange(1, moment(today).subtract(51, "weeks"));
    case "last_year_next_month":
      return _getMonthRange(1, moment(today).subtract(11, "month"));
    default:
      return [];
  }
}

function convertRangeOption(selectedRangeOption: string): string {
  switch (selectedRangeOption) {
    case "custom_current_year":
    case "custom_current_full_year":
    case "custom_last_year":
      return "custom_year";
    case "custom_current_month":
    case "custom_current_full_month":
    case "custom_last_month":
    case "custom_rolling_12_months":
    case "custom_rolling_3_months":
      return "custom_period";
    case "custom_current_season":
    case "custom_current_full_season":
    case "custom_last_season":
      return "custom_season";
    case "custom_last_week":
    case "custom_current_week":
    case "custom_current_full_week":
    case "custom_rolling_4_weeks":
    case "custom_rolling_12_weeks":
    case "custom_current_retail_week_until_today":
      return "custom_week";
    default:
      return selectedRangeOption;
  }
}

export function normalizeComparisonType(comparison) {
  if (comparison == "previous_period") {
    return "previous_corresponding_period";
  } else if (comparison == "last_year") {
    return "prev_year_corresponding";
  } else {
    return comparison;
  }
}

function _getPreviousCorrespondingPeriod(selectedRangeOption: string, dateRange: any) {
  var days, end, endTime, start, startTime, weekago;
  startTime = dateRange[0];
  endTime = dateRange[1];
  switch (selectedRangeOption) {
    case "4_weeks":
    case "last_year_4_weeks":
      return [
        moment(startTime)
          .subtract(4, "week")
          .startOf("week")
          .toDate(),
        moment(startTime)
          .subtract(1, "week")
          .endOf("week")
          .toDate(),
      ];
    case "rolling_3_weeks":
      return [
        moment(startTime)
          .subtract(3, "week")
          .startOf("week")
          .toDate(),
        moment(startTime)
          .subtract(1, "week")
          .endOf("week")
          .toDate(),
      ];
    case "last_52_weeks":
      return [
        moment(startTime)
          .subtract(52, "week")
          .startOf("week")
          .toDate(),
        moment(startTime)
          .subtract(1, "week")
          .endOf("week")
          .toDate(),
      ];
    case "yesterday":
    case "today":
    case "today_until_last_full_hour":
    case "last_full_hour":
    case "tomorrow":
    case "last_year_today":
    case "last_year_tomorrow":
      return [
        moment(startTime)
          .subtract(7, "days")
          .toDate(),
        moment(endTime)
          .subtract(7, "days")
          .toDate(),
      ];
    case "12_months":
      return [
        moment(startTime)
          .subtract(12, "month")
          .startOf("month")
          .toDate(),
        moment(startTime)
          .subtract(1, "month")
          .endOf("month")
          .toDate(),
      ];
    case "rolling_6_months":
      return [
        moment(startTime)
          .subtract(6, "month")
          .startOf("month")
          .toDate(),
        moment(startTime)
          .subtract(1, "month")
          .endOf("month")
          .toDate(),
      ];
    case "rolling_2_tertiles":
      return [
        _startOfTertile(moment(startTime).subtract(8, "month")).toDate(),
        _endOfTertile(moment(startTime).subtract(4, "month")).toDate(),
      ];
    case "rolling_3_tertiles":
      return [
        _startOfTertile(moment(startTime).subtract(12, "month")).toDate(),
        _endOfTertile(moment(startTime).subtract(4, "month")).toDate(),
      ];
    case "rolling_2_quarters":
      return [
        moment(startTime)
          .subtract(2, "quarter")
          .startOf("quarter")
          .toDate(),
        moment(startTime)
          .subtract(1, "quarter")
          .endOf("quarter")
          .toDate(),
      ];
    case "rolling_3_quarters":
      return [
        moment(startTime)
          .subtract(3, "quarter")
          .startOf("quarter")
          .toDate(),
        moment(startTime)
          .subtract(1, "quarter")
          .endOf("quarter")
          .toDate(),
      ];
    case "rolling_4_quarters":
      return [
        moment(startTime)
          .subtract(4, "quarter")
          .startOf("quarter")
          .toDate(),
        moment(startTime)
          .subtract(1, "quarter")
          .endOf("quarter")
          .toDate(),
      ];
    case "last_7_days":
    case "next_7_days":
    case "next_7_days_from_today":
    case "last_7_days_until_today":
    case "last_year_7_days":
      return [
        moment(startTime)
          .subtract(7, "days")
          .toDate(),
        moment(startTime)
          .subtract(1, "days")
          .toDate(),
      ];
    case "next_546_days":
    case "next_546_days_from_today":
      return [
        moment(startTime)
          .subtract(546, "days")
          .toDate(),
        moment(startTime)
          .subtract(1, "days")
          .toDate(),
      ];
    case "last_14_days":
    case "last_14_days_until_today":
    case "last_year_14_days":
      return [
        moment(startTime)
          .subtract(14, "days")
          .toDate(),
        moment(startTime)
          .subtract(1, "days")
          .toDate(),
      ];
    case "last_28_days":
      return [
        moment(startTime)
          .subtract(28, "days")
          .toDate(),
        moment(startTime)
          .subtract(1, "days")
          .toDate(),
      ];
    case "last_30_days":
    case "last_30_days_until_today":
    case "last_year_30_days":
      return [
        moment(startTime)
          .subtract(30, "days")
          .toDate(),
        moment(startTime)
          .subtract(1, "days")
          .toDate(),
      ];
    case "last_90_days":
    case "last_90_days_until_today":
      return [
        moment(startTime)
          .subtract(90, "days")
          .toDate(),
        moment(startTime)
          .subtract(1, "days")
          .toDate(),
      ];
    case "last_180_days":
      return [
        moment(startTime)
          .subtract(180, "days")
          .toDate(),
        moment(startTime)
          .subtract(1, "days")
          .toDate(),
      ];
    case "last_365_days":
    case "last_365_days_until_today":
      return [
        moment(startTime)
          .subtract(365, "days")
          .toDate(),
        moment(startTime)
          .subtract(1, "days")
          .toDate(),
      ];
    case "last_and_next_7_days":
      return [
        moment(startTime)
          .subtract(15, "days")
          .toDate(),
        moment(startTime)
          .subtract(1, "days")
          .toDate(),
      ];
    case "rolling_12_weeks":
      return [
        moment(startTime)
          .subtract(12, "week")
          .startOf("week")
          .toDate(),
        moment(startTime)
          .subtract(1, "week")
          .endOf("week")
          .toDate(),
      ];
    case "rolling_3_months":
    case "next_3_months":
      return [
        moment(startTime)
          .subtract(3, "month")
          .startOf("month")
          .toDate(),
        moment(startTime)
          .subtract(1, "month")
          .endOf("month")
          .toDate(),
      ];
    case "next_6_months":
      return [
        moment(startTime)
          .subtract(6, "month")
          .startOf("month")
          .toDate(),
        moment(startTime)
          .subtract(1, "month")
          .endOf("month")
          .toDate(),
      ];
    case "current_year":
    case "current_year_until_today":
    case "current_year_until_end_of_last_month":
      return [
        moment(startTime)
          .subtract(1, "year")
          .startOf("year")
          .toDate(),
        moment(endTime)
          .subtract(1, "year")
          .toDate(),
      ];
    case "current_half_year":
      return [
        moment(startTime)
          .subtract(6, "month")
          .startOf("month")
          .toDate(),
        moment(endTime)
          .subtract(6, "month")
          .toDate(),
      ];
    case "current_tertile":
      return [
        moment(startTime)
          .subtract(4, "month")
          .startOf("month")
          .toDate(),
        moment(endTime)
          .subtract(4, "month")
          .toDate(),
      ];
    case "current_quarter":
      return [
        moment(startTime)
          .subtract(3, "month")
          .startOf("month")
          .toDate(),
        moment(endTime)
          .subtract(3, "month")
          .toDate(),
      ];
    case "current_month":
    case "current_month_until_today":
      return [
        moment(startTime)
          .subtract(1, "month")
          .startOf("month")
          .toDate(),
        moment(endTime)
          .subtract(1, "month")
          .toDate(),
      ];
    case "current_week":
    case "current_week_until_today":
      return [
        moment(startTime)
          .subtract(1, "week")
          .startOf("week")
          .toDate(),
        moment(endTime)
          .subtract(1, "week")
          .toDate(),
      ];
    case "last_year":
    case "current_full_year":
    case "next_year":
      return _lastPeriodRange(moment(startTime), "year", 1);
    case "last_month":
    case "current_full_month":
    case "next_month":
    case "last_year_next_month":
      return _lastPeriodRange(moment(startTime), "month", 1);
    case "last_week":
    case "current_full_week":
    case "next_week":
    case "last_year_current_week":
    case "last_year_next_week":
      return _lastPeriodRange(moment(startTime), "week", 1);
    case "last_tertile":
      return _lastPeriodRange(moment(startTime), "tertile", 1);
    case "last_quarter":
      return _lastPeriodRange(moment(startTime), "quarter", 1);
    case "custom":
    case "custom_until_today":
    case "custom_until_yesterday":
      start = moment(startTime);
      end = moment(endTime);
      days = end.diff(start, "days");

      const previousEnd = moment(start)
        .isoWeekday(end.isoWeekday())
        .subtract(1, "week");

      const previousStart = previousEnd.clone().subtract(days, "day");

      return [previousStart.toDate(), previousEnd.toDate()];
    case "custom_current_full_year":
    case "custom_current_full_month":
    case "custom_current_full_season":
    case "custom_current_full_week":
      return _getPeriodAmount(selectedRangeOption, -1);
    case "custom_current_year":
    case "custom_current_month":
    case "custom_current_season":
    case "custom_current_week":
    case "custom_current_retail_week_until_today":
      return _getAmountToYesterday(selectedRangeOption, -1);
    case "custom_last_month":
    case "custom_last_season":
    case "custom_last_week":
      return _getPeriodAmount(selectedRangeOption, -2);
    case "custom_last_year":
      return _getYearAmount(selectedRangeOption, -2);
    case "custom_rolling_12_weeks":
    case "custom_rolling_12_months":
      return [_getPeriodAmount(selectedRangeOption, -24)[0], _getPeriodAmount(selectedRangeOption, -13)[1]];
    case "custom_rolling_3_months":
      return [_getPeriodAmount(selectedRangeOption, -6)[0], _getPeriodAmount(selectedRangeOption, -4)[1]];
    case "custom_rolling_4_weeks":
      return [_getPeriodAmount(selectedRangeOption, -8)[0], _getPeriodAmount(selectedRangeOption, -5)[1]];
    case "next_30_days":
      return [
        moment(startTime)
          .subtract(30, "days")
          .toDate(),
        moment(startTime)
          .subtract(1, "days")
          .toDate(),
      ];
    case "to_end_of_year":
    case "to_end_of_year_from_today":
      return _previousSameLengthToPeriodEnd(startTime, endTime, "year");
    case "to_end_of_month":
    case "to_end_of_month_from_today":
      return _previousSameLengthToPeriodEnd(startTime, endTime, "month");
    case "to_end_of_week":
    case "to_end_of_week_from_today":
      return _previousSameLengthToPeriodEnd(startTime, endTime, "week");
    default:
      return [];
  }
}

function _getPreviousYearCorresponding(selectedRangeOption, range, yearsBack = 1) {
  switch (selectedRangeOption) {
    case "4_weeks":
    case "rolling_3_weeks":
    case "last_52_weeks":
    case "yesterday":
    case "today":
    case "today_until_last_full_hour":
    case "last_full_hour":
    case "current_week":
    case "last_week":
    case "last_7_days":
    case "last_14_days":
    case "last_28_days":
    case "last_30_days":
    case "last_90_days":
    case "last_180_days":
    case "last_365_days":
    case "last_7_days_until_today":
    case "last_14_days_until_today":
    case "last_30_days_until_today":
    case "last_90_days_until_today":
    case "last_365_days_until_today":
    case "last_and_next_7_days":
    case "rolling_12_weeks":
    case "current_full_week":
    case "custom":
    case "next_30_days":
    case "to_end_of_week":
    case "to_end_of_week_from_today":
    case "current_week_until_today":
    case "next_week":
    case "tomorrow":
    case "next_7_days":
    case "next_7_days_from_today":
    case "next_546_days":
    case "next_546_days_from_today":
    case "last_year_7_days":
    case "last_year_14_days":
    case "last_year_30_days":
    case "last_year_4_weeks":
    case "last_year_today":
    case "last_year_tomorrow":
    case "last_year_current_week":
    case "last_year_next_week":
      return [_weekdayOnSameWeekPreviousYear(range[0], yearsBack), _weekdayOnSameWeekPreviousYear(range[1], yearsBack)];
    case "rolling_3_months":
    case "12_months":
    case "rolling_6_months":
    case "last_year":
    case "last_month":
    case "current_full_month":
    case "current_full_year":
    case "next_3_months":
    case "next_6_months":
    case "to_end_of_year":
    case "to_end_of_year_from_today":
    case "to_end_of_month":
    case "to_end_of_month_from_today":
    case "last_tertile":
    case "last_quarter":
    case "rolling_2_tertiles":
    case "rolling_3_tertiles":
    case "rolling_2_quarters":
    case "rolling_3_quarters":
    case "rolling_4_quarters":
    case "last_year_next_month":
      return [_sameDatePreviousYear(range[0], yearsBack), _sameMonthEndPreviousYear(range[1], yearsBack)];
    case "current_year":
    case "current_half_year":
    case "current_quarter":
    case "current_tertile":
    case "current_month":
    case "current_year_until_today":
    case "current_year_until_end_of_last_month":
    case "current_month_until_today":
    case "next_month":
    case "next_year":
    case "custom_until_today":
    case "custom_until_yesterday":
      return [_sameDatePreviousYear(range[0], yearsBack), _sameDatePreviousYear(range[1], yearsBack)];
    case "custom_current_full_year":
    case "custom_current_full_month":
    case "custom_current_full_season":
      return _getYearAmount(selectedRangeOption, -yearsBack);
    case "custom_current_full_week":
      return _getPeriodAmount(selectedRangeOption, -52 * yearsBack);
    case "custom_last_month":
      return _getPeriodAmount(selectedRangeOption, -12 * yearsBack - 1);
    case "custom_last_week":
      return _getPeriodAmount(selectedRangeOption, -52 * yearsBack - 1);
    case "custom_last_season":
      return _getPeriodAmount(selectedRangeOption, -2 * yearsBack - 1);
    case "custom_current_year":
    case "custom_current_month":
    case "custom_current_season":
    case "custom_current_week":
    case "custom_current_retail_week_until_today":
      return _getAmountToYesterday(selectedRangeOption, -yearsBack, true);
    case "custom_last_year":
      return _getYearAmount(selectedRangeOption, -(1 + yearsBack));
    case "custom_rolling_12_months":
      return [
        _getPeriodAmount(selectedRangeOption, -12 * (yearsBack + 1))[0],
        _getPeriodAmount(selectedRangeOption, -12 * yearsBack - 1)[1],
      ];
    case "custom_rolling_3_months":
      return [
        _getPeriodAmount(selectedRangeOption, -12 * yearsBack - 3)[0],
        _getPeriodAmount(selectedRangeOption, -12 * yearsBack - 1)[1],
      ];
    case "custom_rolling_4_weeks":
      return [
        _getPeriodAmount(selectedRangeOption, -52 * yearsBack - 4)[0],
        _getPeriodAmount(selectedRangeOption, -52 * yearsBack - 1)[1],
      ];
    case "custom_rolling_12_weeks":
      return [
        _getPeriodAmount(selectedRangeOption, -52 * yearsBack - 12)[0],
        _getPeriodAmount(selectedRangeOption, -52 * yearsBack - 1)[1],
      ];
    default:
      return [];
  }
}
function _getPreviousYearSameDays(range) {
  return [_sameDatePreviousYear(range[0]), _sameDatePreviousYear(range[1])];
}

function _getDayRange(amount: number, start: Moment | Date) {
  const startDate = moment(start);
  const endDate = moment(startDate).add(amount - 1, "days");
  return [startDate.toDate(), endDate.toDate()];
}

function _getWeekRange(amount: number, start: Moment | Date) {
  const startDate = moment(start).startOf("week");
  const endDate = moment(startDate)
    .add(amount - 1, "weeks")
    .endOf("week");
  return [startDate.toDate(), endDate.toDate()];
}

function _getMonthRange(amount: number, start: Moment | Date) {
  const startDate = moment(start).startOf("month");
  const endDate = moment(startDate)
    .add(amount - 1, "months")
    .endOf("month");
  return [startDate.toDate(), endDate.toDate()];
}

function _getAmountToThisDate(selectedRangeOption: string, period: number, year: boolean = false) {
  const start_date = moment(
    year ? _getYearAmount(selectedRangeOption, period)[0] : _getPeriodAmount(selectedRangeOption, period)[0]
  );
  const end_date = moment(
    year ? _getYearAmount(selectedRangeOption, period)[1] : _getPeriodAmount(selectedRangeOption, period)[1]
  );
  const delta = moment().diff(
    moment(_getFlatlist(selectedRangeOption)[currentPeriod(selectedRangeOption)].start_date),
    "days"
  );
  const dates_added = moment(start_date.clone().add(delta, "days"));

  if (dates_added > end_date) {
    return [start_date.toDate(), end_date.toDate()];
  } else {
    return [start_date.toDate(), dates_added.toDate()];
  }
}

function _getAmountToYesterday(selectedRangeOption: string, period: number, year: boolean = false) {
  const start = moment(
    year
      ? convertRangeOption(selectedRangeOption) == "custom_week"
        ? _getPeriodAmount(selectedRangeOption, period * 52)[0]
        : _getYearAmount(selectedRangeOption, period)[0]
      : _getPeriodAmount(selectedRangeOption, period)[0]
  );
  const delta = moment()
    .subtract(1, "day")
    .diff(moment(_getFlatlist(selectedRangeOption)[currentPeriod(selectedRangeOption)].start_date), "days");
  let end = moment(start.clone().add(delta, "days"));
  if (start > end) {
    end = start;
  }
  return [start.toDate(), end.toDate()];
}

// Function eats a custom period and reduced given number of it
function _getPeriodAmount(selectedRangeOption: string, period: number) {
  let index = currentPeriod(selectedRangeOption) + period;
  if (index < 0) {
    index = 0;
  }
  let item: any = _getFlatlist(selectedRangeOption)[index];

  return [moment(item.start_date).toDate(), moment(item.end_date).toDate()];
}

function currentPeriod(selectedRangeOption): number {
  return _getFlatlist(selectedRangeOption).findIndex((x) => moment().isBetween(x.start_date, x.end_date, "days", "[]"));
}

function _getYearAmount(selectedRangeOption: string, yearDelta: number) {
  let flatlist = _getFlatlist(selectedRangeOption);
  let item: any = {};
  const currentPeriodIndex = currentPeriod(selectedRangeOption);
  const currentPeriodItem = flatlist[currentPeriodIndex];

  if (convertRangeOption(selectedRangeOption) == "custom_year") {
    item = flatlist.find((x: any) => currentPeriodItem.period + yearDelta === x.period);
  } else {
    item = flatlist.find(
      (x: any) => currentPeriodItem.year + yearDelta === x.year && currentPeriodItem.period === x.period
    );
  }

  item = item || currentPeriodItem;

  return [moment(item.start_date).toDate(), moment(item.end_date).toDate()];
}

// Converts the raw data structure to flat array where you can play with indexes
function _getFlatlist(selectedRangeOption: string): any[] {
  // Get current period
  let period_items: any = (window as any).zoinedContext.custom_periods[convertRangeOption(selectedRangeOption)];
  let flatlist: any = [];

  Object.values(period_items).map((x) => Object.values(x as any).map((y) => flatlist.push(y)));

  let sorted_flatlist = flatlist.sort((a, b) => {
    return a.year - b.year || a.period - b.period;
  });

  return sorted_flatlist;
}

function _getLfl(range) {
  let res = $.ajax(
    {
      type: "POST",
      async: false,
      dataType: "json",
      url: "/dashboard/lfl_periods",
      contentType: "application/json; charset=utf-8",
      timeout: 3000,
      data: JSON.stringify({ dates: [moment(range[0]).format("YYYY-MM-DD"), moment(range[1]).format("YYYY-MM-DD")] }),
    },
    true
  ).responseJSON;
  return [res.start_date, res.end_date];
}

function _getPreviousYearCorrespondingSameWeekdayEnding(selectedRangeOption, range) {
  switch (selectedRangeOption) {
    case "current_year":
      return [_sameDatePreviousYear(range[0]), _weekdayOnSameWeekPreviousYear(range[1])];
    default:
      return _getPreviousYearCorresponding(selectedRangeOption, range);
  }
}

function _weekdayOnSameWeekPreviousYear(date, yearsBack = 1) {
  return moment(date)
    .subtract(52 * yearsBack, "weeks")
    .toDate();
}

function _sameDatePreviousYear(date, yearsBack = 1) {
  return moment(date)
    .subtract(yearsBack, "years")
    .toDate();
}

function _sameMonthEndPreviousYear(date, yearsBack = 1) {
  return moment(date)
    .subtract(yearsBack, "years")
    .endOf("month")
    .toDate();
}

function _getCompareRangeFromPastWeeks(pastWeeksOption, dateRange) {
  var endDate, startDate;
  endDate = dateRange[1];
  startDate = moment(endDate)
    .subtract(parseInt(pastWeeksOption), "w")
    .add(1, "d")
    .toDate();
  return [startDate, endDate];
}

function _getComparisonPeriod(compareRangeOption, selectedRangeOption, timeRange, compareRange) {
  if (timeRange == null) {
    timeRange = _getDateRange(selectedRangeOption, timeRange);
  }
  switch (compareRangeOption) {
    case "custom":
      return compareRange || timeRange;
    case "prev_year_corresponding":
      return _getPreviousYearCorresponding(selectedRangeOption, timeRange);
    case "2_years_back_corresponding":
      return _getPreviousYearCorresponding(selectedRangeOption, timeRange, 2);
    case "3_years_back_corresponding":
      return _getPreviousYearCorresponding(selectedRangeOption, timeRange, 3);
    case "prev_year_corresponding_same_weekday_ending":
      return _getPreviousYearCorrespondingSameWeekdayEnding(selectedRangeOption, timeRange);
    case "prev_year_same_days":
      return _getPreviousYearSameDays(timeRange);
    case "previous_corresponding_period":
      return _getPreviousCorrespondingPeriod(selectedRangeOption, timeRange);
    case "previous_year_lfl":
      return _getLfl(timeRange);
    case "2_years_back_lfl":
      return _getLfl(_getLfl(timeRange));
    case "3_years_back_lfl":
      return _getLfl(_getLfl(_getLfl(timeRange)));
    case "budget":
      return timeRange;
    case "no_comparison":
      return [];
    case "avg_chain":
    case "best_store":
    case "avg_salesperson":
    case "best_salesperson":
      return [];
    default:
      errorReportingService.error("Unknown comparison", { type: compareRangeOption });
      return [];
  }
}

function _translateSelection(key) {
  switch (key) {
    case "4_weeks":
    case "12_months":
      return I18n.t("filters.date_selector.rolling_" + key);
    default:
      return I18n.t("filters.date_selector." + key);
  }
}

function _translateComparison({
  type,
  range = [],
  label,
  time_period,
}: {
  type: string;
  range?: any;
  label: string;
  time_period: string;
}) {
  const [start_time, end_time] = range;
  return new TranslationService().comparisonTitle({ type, start_time, end_time, label, time_period });
}

function _calculateModelWithDates(model, today) {
  if (model && model.selection && model.selection.type) {
    const selectionType = model.selection.type;
    const series = model.selection.series;
    const oldTimeRange = [model.selection.start_time, model.selection.end_time];

    const selectionRange = _getDateRange(selectionType, oldTimeRange, today);

    if (selectionRange.length == 2) {
      const selection: any = {
        type: selectionType,
        text: _translateSelection(selectionType),
        start_time: _dateToDB(selectionRange[0]),
        end_time: _dateToDB(selectionRange[1]),
      };
      if (series) {
        selection.series = series;
      }

      if (model.comparison) {
        const comparisonType = normalizeComparisonType(model.comparison.type);
        const oldComparisonRange = [model.comparison.start_time, model.comparison.end_time];
        if (comparisonType === undefined && model.comparison.text) {
          // Text contains the number of weeks.
          const comparisonRange = _getCompareRangeFromPastWeeks(model.comparison.text, selectionRange);
          const comparison = {
            text: model.comparison.text,
            start_time: _dateToDB(comparisonRange[0]),
            end_time: _dateToDB(comparisonRange[1]),
          };
          return { selection, comparison };
        }

        const comparisonRange =
          comparisonType == "time_period"
            ? _getDateRange(model.comparison.time_period, null, today)
            : _getComparisonPeriod(comparisonType, selectionType, selectionRange, oldComparisonRange);

        const { label, time_period } = model.comparison;
        if (comparisonRange.length == 2) {
          const comparison = {
            type: comparisonType,
            text: _translateComparison({ type: comparisonType, range: comparisonRange, label, time_period }),
            start_time: _dateToDB(comparisonRange[0]),
            end_time: _dateToDB(comparisonRange[1]),
          };

          return { selection, comparison };
        } else {
          const comparison = {
            type: comparisonType,
            text: _translateComparison({ type: comparisonType, label, time_period }),
          };

          return { selection, comparison };
        }
      }

      return { selection };
    }
  }

  return {};
}

export default class TimePeriod {
  _originalModel: any;
  _today: any;

  constructor(filterModel: any, today?: string) {
    this._originalModel = filterModel;
    this._today = today || new Date();
  }

  update(parameter, value) {
    var override;
    switch (parameter) {
      case "date":
      case "sale_date":
        override = { selection: value };
        break;
      case "compare_date":
        override = { comparison: value };
        break;
      case "no_of_past_weeks":
        override = { comparison: { text: value.type } };
        break;
      default:
        throw "error in update, unknown parameter";
    }
    const newModel = Object.assign(this._originalModel, override);
    return new TimePeriod(newModel, this._today);
  }

  getFilterModel() {
    return _calculateModelWithDates(this._originalModel, this._today);
  }
}
