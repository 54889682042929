<template lang="pug">
.selectors
  pill-button(:enabled="!obj.config.invisible", :removable="false", v-for="obj in collection", :key="obj.key", @toggle='editObject(obj)')
    | {{obj.label}} &nbsp;&nbsp;
    i.fa.fa-edit
  metric-dialog(ref="metricDialog" :type="type" @save="onSave")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import _ from "lodash";
import MetricDialog from "./metric-dialog.vue";
import pillButton from "@/components/pill-button.vue";

@Component({
  components: {
    MetricDialog,
    pillButton,
  },
})
export default class ConfigurationPillList extends Vue {
  @Ref("metricDialog")
  metricDialog: MetricDialog;

  @Prop()
  objects: any[];

  @Prop()
  locale: string;

  @Prop()
  type: string;

  get collection() {
    return _.sortBy(
      _.map(this.objects, (v, k) => {
        return { key: k, label: v.label[this.locale] || k, config: v };
      }),
      "label"
    );
  }

  editObject(obj) {
    this.metricDialog.show(obj);
  }

  onSave(obj) {
    this.$emit("save", obj);
  }
}
</script>
