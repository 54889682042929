<template lang="pug">
.filter-dropdown
  .title(v-if="title") {{ title }}
  dropdown-select(button-style="primary" v-bind="$attrs" v-on="$listeners")
</template>

<script>
import DropdownSelect from "./dropdown-select.vue";

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  components: {
    DropdownSelect,
  },
};
</script>

<style lang="scss" scoped>
.filter-dropdown {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 20px;

  .title {
    display: inline-block;
    margin-right: 15px;
  }
}
</style>
