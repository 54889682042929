<template lang="pug">
uiv-modal(v-model="open" :title="'newsletter.definition.messages.select_filter' | i18n")
  div(v-if="model")
    filtersetSelector(:config='model' @update="updateFiltersConfig")
    div(v-if="availableFilters" style="margin-top: 15px;")
      filterSelector(:config="model.filters[filter.id]" :filter="filter" @update="updateFilter(filter.id, $event)" v-for="filter in availableFilters" :key="filter.id")
  div(slot="footer")
    button.btn.btn-default(@click='cancel()') {{ 'actions.cancel' | i18n }}
    button.btn.btn-primary(@click='ok()') {{ 'actions.save' | i18n }}
</template>

<script lang="ts">
import Filter from "../model/filter";
import FiltersConfiguration from "../model/filters-configuration";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import _ from "lodash";
import filtersetSelector from "../components/filterset-selector.vue";
import filterSelector from "../components/filter-selector.vue";
import Actions from "../store/actions";

@Component({
  components: {
    filtersetSelector,
    filterSelector,
  },
})
export default class NewsletterSectionFiltersModal extends Vue {
  @Prop({
    default: (): FiltersConfiguration => ({
      v: 2,
      sets: [],
      filters: {},
    }),
  })
  filters: FiltersConfiguration;

  open = false;
  model: FiltersConfiguration = null;

  get availableFilters(): Filter[] {
    return this.$store.state.parameters.filters.all;
  }

  show() {
    this.model = _.cloneDeep(this.filters);
    this.open = true;
  }

  updateFilter(filter, filters) {
    this.model.filters = {
      ...this.model.filters,
      [filter]: filters,
    };
  }

  updateFiltersConfig(config) {
    this.model = config;
  }

  ok() {
    this.$emit("update", this.model);
    this.open = false;
  }

  cancel() {
    this.open = false;
  }
}
</script>
