<template lang="pug">
  b-modal(id="add-subscription-modal",
    ref="subscriptionModal",
    no-fade=true,
    lazy
    :ok-title='"actions.save" | i18n',
    :cancel-title='"actions.cancel" | i18n',
    :title='"newsletter.subscription.subscribe_description" | i18n',
    title-tag="h4",
    @ok="handleOk",
    ok-variant="primary save",
    @show="show")

    b-alert(variant="danger" v-if="error" show dismissible) {{error}}
    spinner(v-if="loading")
    form(v-else)
      .company(v-for="company in companies" v-if="!!company.definitions.length")
        h4 {{company.company_name}}
        .checkbox(v-for="definition in company.definitions")
          label
            input(type="checkbox" v-bind:value="definition.id" v-model="subscriptions")
            | {{ definition.title }}

</template>

<script>
import bModal from 'bootstrap-vue/es/components/modal/modal'
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'
import bAlert from 'bootstrap-vue/es/components/alert/alert';

import { fetchData, updateSubscriptions } from '../lib/data/profile';

import spinner from '../components/spinner.vue';

export default {
	components: {
    spinner,
    'b-modal': bModal,
    'b-alert': bAlert,

  },
	directives: {
		'b-modal': bModalDirective
	},
  props: {
  },
	data(){
		return {
      loading: false,
      error: null,
      companies: [],
      subscriptions: [],
		}
  },
  methods: {
    handleOk: function(e) {
      e.preventDefault()
      this.handleSubmit();
    },
    handleSubmit: function(){
      updateSubscriptions(this.subscriptions)
      .then(response => {
        this.$root.$emit('fetchData')
        this.$refs.subscriptionModal.hide()
      })
      .catch(err => {
        this.error = error
      })
    },
    fetchNewsletterDefinitions: function() {
      fetchData(`/profile/newsletter_subscriptions/new`).then((res) => {
        this.companies = res.companies
        this.subscriptions = res.subscriptions
      }).catch(error => {
        this.error = error
      })
    },
    show: function() {
      this.fetchNewsletterDefinitions()
    }
	}
};
</script>
