<template lang="pug">
.audit-log
  h3.hide-new-layout {{ 'company_admin.audit_log.title' | i18n }}
  div
    .table-controls
      search-bar(v-model="search")
    div
      spinner(v-if='!audits')
      table.table.table-hover
        thead
          tr
            th {{ 'audit_log.description' | i18n }}
            th {{ 'audit_log.time' | i18n }}
            th {{ 'audit_log.initiator' | i18n }}
        tbody(v-if="audits")
          tr(v-if="audits.length==0")
            td(colspan=4) {{ "table.no_results" | i18n }}
          tr(v-for="audit in audits")
            td {{audit.description}}
            td {{audit.time | dateTime }}
            td {{audit.initiator.email}}

      .centered(v-if="total > perPage")
        uiv-pagination(v-model="page", :total-page="Math.ceil(total / perPage)")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import SearchBar from "@/components/search-bar.vue";
import spinner from "@/components/spinner.vue";
import adminAuditsApi from "@/api/admin-audits-api";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    SearchBar,
    spinner,
  },
})
export default class AuditLog extends Vue {
  page = 1;
  perPage = 10;
  search = "";
  total = 0;
  audits = null;

  get companyId() {
    return window.zoinedContext.companyId;
  }

  get params() {
    const params = {
      page: this.page,
      per_page: this.perPage,
      company_id: this.companyId,
    };

    if (this.search) {
      params["search"] = this.search;
    }

    return params;
  }

  @Watch("params", { immediate: true })
  async fetchAudits() {
    return adminAuditsApi.get(this.params).then((audits) => {
      console.log(audits.total_count);
      this.audits = audits;
      this.total = audits.total_count;
    });
  }

  @Watch("search")
  onSearchChange() {
    this.page = 1;
  }
}
</script>
