<template lang="pug">
  ul(:id="'selector_' + filter.filter", class="selector list-inline" :data-id="filter.filter") 
    li(class="title") {{ filterName }}    
    li.pill.default.btn(v-if="!filters", v-for="(item, key) in defaults")
      span.text {{ getLabel(key) }}
      span.del
        i.fa.fa-circle.border
        i.fa.fa-times-circle(@click="removeDefaultFilter(key)")
    li.pill.btn(v-for="(item, key) in filters")
      span.text {{ getLabel(key) }}
      span.del
        i.fa.fa-circle.border
        i.fa.fa-times-circle(@click="removeItem(key)")
    li(class="add btn" title="Include only selected values in the results")
      i(class="fa fa-lg fa-plus", @click="toggleMenu")
      ul.dropdown-menu.context-menu(v-if="showMenu", v-click-outside="onClickOutside") 
        .search-control.input-group
          span.input-group-addon
            .fa.fa-search
          input.form-control(v-model="filterQuery", ref="filterQuery", type='text', placeholder='Search for...', value='')
        .no-results
        spinner(v-if="loading")
        li.action(v-else, v-for="(item) in filteredOptions")
          a.add(@click="addItem(item)") {{item.name}}
    li(class="remove btn" title="Include only selected values in the results")
      i(class="fa fa-lg fa-minus")      
</template>

<script>
import vSelect from "vue-multiselect";
import Vue from "vue";
import spinner from "../components/spinner.vue";
import vClickOutside from "v-click-outside";
import I18n from "../i18n";

Vue.component("v-select", vSelect);
Vue.use(vClickOutside);

export default {
  name: "filterComponent",
  props: {
    filter: String,
    defaults: {
      type: Object,
      required: false
    },
    filters: {
      type: Object,
      required: false
    }
  },
  created() {
    this.filterQuery = "";
    // this.$refs.email.$el.focus()
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    spinner
  },
  data() {
    return {
      options: null,
      filterQuery: "",
      loading: false,
      includeOpen: false,
      excludeOpen: false,
      showMenu: false
    };
  },
  computed: {
    filterName() {
      return I18n.t(`filter.config.${this.filter}`);
    },
    filteredOptions: function() {
      if (!this.options) {
        return [];
      }
      const regex = new RegExp(this.filterQuery, "ig");
      return this.options.filter(option => option.name.match(regex));
    }
  },
  methods: {
    addItem(item) {
      if (item) {
        const filters = {
          ...this.filters,
          [item.key]: {
            enabled: true
          }
        };
        this.$emit("updateFilters", filters);
      }
      this.showMenu = false;
    },
    removeItem(key) {
      const filters = _.omit(this.filters, key);
      this.$emit("updateFilters", filters);
    },
    removeDefaultFilter(key) {
      const filters = _.omit(this.defaults, key);
      this.$emit("updateFilters", filters);
    },
    onClickOutside(event) {
      this.showMenu = false;
      this.filterQuery = "";
    },
    toggleMenu(event) {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        this.filterQuery = "";
        if (!this.options) {
          this.fetchOptions();
        }
      }
    },
    getLabel(key) {
      if (!this.options) {
        this.fetchOptions();
      }
      const option = (this.options || []).find(option => option.key === key);
      if (option && option.name) {
        return option.name;
      }
      return key;
    },
    fetchOptions() {
      if (!this.loading) {
        this.loading = true;
        this.$emit("fetchData", {
          value: this.filter,
          callback: res => {
            console.log("fetchData results", res);
            this.options = res || [];
            this.loading = false;
          }
        });
      }
    }
  }
};
</script>



<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style >
</style>

<style scoped>
.filter-component {
  display: block;
}

.filter-button {
  color: #fff;
  background-color: #4fbddb;
  border-color: #4fbddb;
  position: relative;
  padding: 4px 10px;
  border-radius: 17px;
  margin-right: 4px;
  margin-bottom: 5px;
  min-width: 32px;
  font-weight: bold;
}

.filter-button-wrapper {
  min-height: 60px;
}

ul.context-menu {
  background-color: white;
  opacity: 1;
  position: absolute;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border: solid 1px #ddd;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4px;
  margin: 0;
  width: 200px;
  display: block;
  margin-top: 10px;
  margin-left: -5px;
  min-height: 200px;
  max-height: 200px;
  select {
    min-width: 200px;
  }
}

.search-control {
  margin-bottom: 5px;
}

.pill.default {
  opacity: 0.5;
}
</style>