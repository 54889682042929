<template lang="pug">
  ul( class="selector list-inline") 
    li(class="title") {{ title }}    
    li.pill.default.btn(v-if="!selected", v-for="item in defaults", data-id='item')
      span.text {{ getLabel(item) }}
      span.del
        i.fa.fa-circle.border
        i.fa.fa-times-circle(@click="removeDefaultItem(item)")
    li.pill.btn(v-for="item in selected" data-id='item')
      span.text {{ getLabel(item) }}
      span.del
        i.fa.fa-circle.border
        i.fa.fa-times-circle(@click="removeItem(item)")
    li(v-show="(!single || !selected || selected.length < 1)", class="add btn", title="Include only selected values in the results")
      i(class="fa fa-lg fa-plus", @click="toggleMenu")
      ul.dropdown-menu.context-menu(v-if="showMenu", v-click-outside="onClickOutside") 
        li.action(v-for="(item) in options")
          a.add(@click="addItem(true, item.key)") {{item.label}}

</template>

<script>
import vSelect from "vue-multiselect";
import Vue from "vue";
import spinner from "../components/spinner.vue";
import vClickOutside from 'v-click-outside';
Vue.component("v-select", vSelect);

export default {
  name: "groupingComponent",
  props: {
    id: String,
    selected: Array,
    fetchFilter: Function,
    title: String,
    options: Array,
    single: Boolean,
    defaults: Array
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    spinner,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    update(items) {
      this.$emit("value", { id: this.id, value: items });
    },
    addItem(enabled, key) {
      const item = this.options.find(x => x.key === key);
      if (item) {
        const items = [...(this.selected || [])];
        if (items.indexOf(key) == -1) {
          items.push(key);
        }
        this.update(items);
      }

      this.showMenu = false;
    },
    getLabel(key) {
      const item = this.options.find(x => x.key == key);
      if (item && item.label) {
        return item.label;
      }
      return key;
    },
    removeItem(item) {
      const items = _.without(this.selected, item);
      this.update(items);
    },
    removeDefaultItem(item) {
      const items = _.without(this.defaults, item);
      this.update(items);
    },
    onClickOutside(event) {
      this.showMenu = false
    },
    toggleMenu(event){
      this.showMenu = !this.showMenu;
    },
  },
  watch: {}
};
</script>



<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style >
</style>

<style scoped>
.filter-component {
  display: block;
}

.filter-button {
  color: #fff;
  background-color: #4fbddb;
  border-color: #4fbddb;
  position: relative;
  padding: 4px 10px;
  border-radius: 17px;
  margin-right: 4px;
  margin-bottom: 5px;
  min-width: 32px;
  font-weight: bold;
}

.filter-button-wrapper {
  min-height: 60px;
}

ul.context-menu {
  background-color: white;
  opacity: 1;
  position: absolute;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  border: solid 1px #DDD;
  border: solid 1px rgba(0, 0, 0, .2);
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4px;
  margin: 0;
  min-width: 200px;
  display: block;
  margin-top: 10px;
  margin-left: -5px;
  min-height: 200px;
  max-height: 200px;
  select {
    min-width: 200px;
  }  
}

.pill.default {
  opacity: 0.5;
}

</style>