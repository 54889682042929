<template lang="pug">
.report-template-editor
  h3.title.hide-new-layout {{ title }}
  .loading-indicator(v-if="!loaded")
    spinner
  .editor(v-else)
    .row
      .col-md-4
        .form-group(:class="{'has-error': $v.template.name.$error}")
          label.control-label(for='name') {{ 'attributes.name' | i18n }}
          input.form-control#name(type='text', v-model="template.name")
      .col-md-4
        .form-group
          label(for='name') {{ 'attributes.description' | i18n }}
          input#name(type='text', v-model="template.description")

    .row
      .col-md-12
        .form-group(:class="{'has-error': $v.template.translation_key.$error}")
          label.control-label(for='title', style="margin-right: 5px;") {{ 'dashboard_templates.translation_key' | i18n }}
          .subtitle Translation is saved in localizations (localeapp) under "analytics_templates.titles.[key]"
          input.form-control#title(type='text', v-model='template.translation_key')
          .help-block(v-if="$v.template.translation_key.$error") Key can contain only characters a-z and _

    .row
      .col-md-12
        h3 {{ 'attributes.visibility' | i18n }}

      .col-md-4
        .form-group
          div
            label {{ 'activerecord.attributes.dashboard_template.visible_in_zoined' | i18n }}
          toggle-button(v-model="template.visible_in_zoined")

      .col-md-4
        .form-group
          div
            label {{ 'activerecord.attributes.dashboard_template.add_for_new_customers' | i18n }}
          toggle-button(v-model="template.add_for_new_customers")

      .col-md-12
        .form-group
          div
            label {{ 'activerecord.attributes.dashboard_template.partners' | i18n }}
          partner-selector(v-model="template.partners")

    .row
      .col-md-12
        h4 {{ 'custom_report.report' | i18n }}

    .row
      .col-md-12
        .m-sm.type-item.component(
          v-for="reportType in reportTypes" 
          :class="{ 'selected': reportType.config.report_type === selectedReportType.config.report_type }"
          @click="selectReportType(reportType.config.report_type)")
          div(class="chartImg", v-bind:class="reportType.config.report_type.replace(/[^a-zA-Z]/g, '')")
          p(class="type-text") {{ 'dashboard_custom.report_editor.component_types.' + reportType.config.report_type | i18n }} 

    .row
      .col-md-12
        h4 {{ 'dashboard_custom.report_editor.filters' | i18n }}

    .row.time-filters
      .col-md-12
        .time-filters
          time-period-dropdown(v-if="isFilterEnabled('date')" :available-items="options.time_ranges", :available-series-types="options.seriesTypes" :value="filterConfiguration.time.selection", @update="updateSelection($event)")
          comparison-pill-list(v-if="isFilterEnabled('comparisons')" :comparisons="filterConfiguration.time.comparisons", @update="updateComparisons")
          business-hours-selector(v-if="isFilterEnabled('limit_to_business_hours')" :value="filterConfiguration.time.limit_to_business_hours", @update="updateBusinessHours($event)")
        widget-filters-section(:filter-configuration="filterConfiguration" :report-config="selectedReportType.config" @update="updateFilterConfiguration")

    .row
      .col-md-12
        filter-selector(:config="filters[filter.id]", :filter="filter", @update="updateFilter(filter.id, $event)", v-for="filter in options.filters" :key="filter.id")

    .row.buttons
      .col-md-12
        .pull-left(v-if="id")
          confirm-button(:button-title="'actions.delete' | i18n", variant="danger", @confirm="deleteTemplate()")
        .pull-right
          button.pull-right.btn.btn-primary(@click="save", :disabled="submitDisabled") {{ 'actions.save' | i18n }}

</template>

<script lang="ts">
import _ from "lodash";
import { convertToNewFilterConfiguration, filterToFlyover, refreshFilterTimes } from "@/lib/filter-util";
import Vue from "vue";
import Component from "vue-class-component";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import filterSelector from "../../components/filter-selector.vue";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import businessHoursSelector from "../../components/business-hours-selector.vue";
import ConfirmButton from "../../components/confirm-button.vue";
import { makeApiInstance } from "../../api/instance";
import AnalyticsTemplate from "../../model/analytics-template";
import { Prop } from "vue-property-decorator";
import i18n from "../../i18n";
import spinner from "../../components/spinner.vue";
import pillList from "../../components/pill-list.vue";
import toggleButton from "../../components/toggle-button.vue";
import Actions from "../../store/actions";
import partnerSelector from "../../components/partner-selector.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { reportTypes } from "../../custom-report/report-types";
import FilterConfiguration from "@/model/filter-configuration";
import WidgetFiltersSection from "../../flyover-filters/widget-filters-section.vue";

@Component({
  components: {
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    ConfirmButton,
    spinner,
    pillList,
    toggleButton,
    partnerSelector,
    WidgetFiltersSection,
  },
})
export default class ReportTemplateEditor extends Vue {
  @Prop()
  id: string;

  @Prop()
  reportId: string;

  @Prop()
  templateId: string;

  template: Partial<AnalyticsTemplate> = null;
  selectedReportType = null;

  @Validations()
  validations = {
    template: {
      name: { required },
      translation_key: {
        valid: (key: string) => !key || !!key.match(/^[a-z_]*$/),
      },
    },
  };

  get api() {
    return makeApiInstance({ baseURL: "/api/zoined_admin/analytics_templates/" });
  }

  get myReportsApi() {
    return makeApiInstance({ baseURL: "/api/v1/my_reports/" });
  }

  get title() {
    return this.id ? i18n.t("report_templates.edit_report_template") : i18n.t("report_templates.new_report_template");
  }

  get loaded() {
    return this.template && this.selectReportType && this.options;
  }

  get submitDisabled() {
    return false;
  }

  get reportTypes() {
    return reportTypes;
  }

  get options() {
    const filters = this.$store.state.parameters.filters.all || [];
    const groupings = this.$store.state.parameters.grouping.all || [];
    const metrics = this.$store.state.parameters.metrics.all || [];
    const time_ranges = (this.$store.state.parameters.timePeriods.all || []).map(({ id }) => ({ key: id }));
    const sort = this.$store.state.parameters.sort.all || [];

    const seriesTypes = window.zoinedContext.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];

    return {
      filters,
      groupings,
      metrics,
      time_ranges,
      sort,
      seriesTypes,
    };
  }

  get filterConfiguration() {
    return _.cloneDeep(this.template.config.filterConfiguration);
  }

  get filters() {
    return _.reduce(
      this.filterConfiguration.filters.filters,
      (result, config, filter) => {
        return {
          ...result,
          [filter]: _.fromPairs(
            _.map(config, ({ enabled, exclude, value, name }, key) => [
              key,
              {
                value: key,
                enabled,
                exclude: !!exclude,
                name: name || value || key,
              },
            ])
          ),
        };
      },
      {}
    );
  }

  get activeFilters() {
    // Get names of active filters. Some filters might have some default value set.
    const activeFilters = _.union(this.template.config.filters, Object.keys(this.selectedReportType.defaults || {}));

    if (activeFilters.includes("date")) {
      // Filter key for date is selection (not date)
      activeFilters.push("selection");
    }

    return activeFilters;
  }

  isFilterEnabled(filter) {
    return this.activeFilters.includes(filter);
  }

  selectReportType(type) {
    const currentFilters = filterToFlyover(this.template.config.filterConfiguration);

    this.selectedReportType =
      reportTypes.find((reportType) => reportType.config.report_type === type) || reportTypes[0];
    this.template.config = _.cloneDeep(this.selectedReportType.config);

    // some sensible global defaults
    const defaults = filterToFlyover({
      selection: { type: "4_weeks" },
      comparisons: [{ type: "prev_year_corresponding", enabled: true }],
      metrics: { sales: { enabled: true } },
      grouping: { store: { enabled: true } },
      column_grouping: { weekday: { enabled: true } },
    });

    //  defaults for selected type
    const reportTypeDefaults = filterToFlyover(
      Object.keys(this.selectedReportType.filters || {}).reduce((defaults, key) => {
        const defaultValue = _.get(this.selectedReportType.filters[key], "default");
        return defaultValue
          ? { ...defaults, [key]: _.isFunction(defaultValue) ? defaultValue() : defaultValue }
          : defaults;
      }, this.selectedReportType.defaults || {})
    );

    // remove filters that are not used for this report type and set correct defaults
    const filterConfiguration = Object.assign({}, currentFilters, {
      time: _.pick({ ...defaults.time, ...currentFilters.time, ...reportTypeDefaults.time }, this.activeFilters),
      widgets: _.pick(
        { ...defaults.widgets, ...currentFilters.widgets, ...reportTypeDefaults.widgets },
        this.activeFilters
      ),
      raw_filters: _.pick(
        { ...defaults.raw_filters, ...currentFilters.raw_filters, ...reportTypeDefaults.raw_filters },
        this.activeFilters
      ),
    });

    Vue.set(this.template.config, "filterConfiguration", filterConfiguration);
  }

  updateSelection(selection) {
    Vue.set(this.template.config.filterConfiguration.time, "selection", selection);
  }

  updateComparisons(comparisons) {
    Vue.set(this.template.config.filterConfiguration.time, "comparisons", comparisons);
  }

  updateBusinessHours(limitToBusinessHours) {
    Vue.set(this.template.config.filterConfiguration.time, "limit_to_business_hours", limitToBusinessHours);
  }

  updateFilter(filter, filterConfig) {
    if (!_.isEmpty(filterConfig)) {
      const config = _.reduce(
        _.values(filterConfig),
        (result, { enabled, exclude, value, name }) => ({
          ...result,
          [value]: { enabled, exclude: !!exclude, value, name: name || value },
        }),
        {}
      );
      Vue.set(this.template.config.filterConfiguration.filters.filters, filter, config);
    } else {
      Vue.delete(this.template.config.filterConfiguration.filters.filters, filter);
    }
  }

  updateFilterConfiguration(filterConfiguration: FilterConfiguration) {
    Vue.set(this.template.config, "filterConfiguration", filterConfiguration);
  }

  async deleteTemplate() {
    await this.api.delete(this.id);

    this.$router.back();
  }

  async save() {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    const url = this.id ? this.id : "";
    const method = this.id ? "put" : "post";
    const analytics_template: Partial<AnalyticsTemplate> = {
      name: this.template.name,
      description: this.template.description,
      analytics_type: "report",
      config: this.template.config,
      add_for_new_customers: this.template.add_for_new_customers,
      visible_in_zoined: this.template.visible_in_zoined,
      partner_ids: this.template.partners?.map(({ id }) => id) || [],
      translation_key: this.template.translation_key,
    };

    await this.api.request({
      url,
      method,
      data: {
        analytics_template,
      },
    });

    this.$store.dispatch(Actions.fetchNavigation);

    this.$router.back();
  }

  created() {
    this.fetchOrInitTemplate();
  }

  private async fetchOrInitTemplate() {
    if (this.id) {
      await this.api.get(this.id).then(({ data }) => {
        this.template = data;
      });
    } else if (this.reportId) {
      const { filters, report } = await this.myReportsApi.get(this.reportId).then((result) => result.data);

      this.template = {
        name: report.metadata.title,
        description: report.metadata.description,
        analytics_type: "report",
        config: { ...report.config, filterConfiguration: filters },
        visible_in_zoined: true,
        add_for_new_customers: false,
      };
    } else if (this.templateId) {
      await this.api.get(this.templateId).then((response) => {
        const template: AnalyticsTemplate = response.data;
        this.template = {
          name: i18n.t("newsletter.definition.copy_of", {
            title: template.name,
          }),
          description: template.description,
          analytics_type: "report",
          config: template.config,
          visible_in_zoined: template.visible_in_zoined,
          add_for_new_customers: template.add_for_new_customers,
        };
      });
    } else {
      const filterConfiguration = convertToNewFilterConfiguration({
        selection: { type: "4_weeks" },
        comparisons: [{ type: "prev_year_corresponding", enabled: true }],
      });

      this.template = {
        name: "",
        description: "",
        config: {
          components: [],
          filters: ["date", "comparisons", "limit_to_business_hours"],
          sort: ["-metric", "metric", "grouping"],
          controls: { orientation: true, data_labels: true },
          default: {},
          radio_selectors: ["grouping"],
          excel: true,
          benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
          budgets_enabled: true,
          filterConfiguration,
        },
        visible_in_zoined: true,
        add_for_new_customers: false,
      };
    }

    this.template.config.filterConfiguration = convertToNewFilterConfiguration(
      this.template.config.filterConfiguration
    );
    this.template.config.filterConfiguration.time = refreshFilterTimes(this.template.config.filterConfiguration.time);

    this.selectReportType(this.template.config.report_type);
  }
}
</script>

<style lang="scss" scoped>
.report-template-editor {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.title {
  margin-bottom: 20px;
}

.buttons {
  margin-top: 15px;
}

.time-filters {
  margin-bottom: 10px;
}

.row h4 {
  margin: 20px 0;
}
</style>
