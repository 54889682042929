<template lang="pug">
ul.dropdown-menu.reports(v-if="menuItems" role="menu")
  li(v-for="item in menuItems")
    a(:href="item.path")
      h3 {{ item.title }}
      p(v-if="item.description" :title="item.description") {{ visibleDescription(item.description) }}
</template>

<script lang="ts">
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class BreadcrumbsMenu extends Vue {
  get menuItems() {
    return this.$store.getters.getReportContext.breadcrumb_menu_items;
  }

  visibleDescription(description) {
    return _.truncate(description, { length: 55 });
  }
}
</script>

<style lang="scss" scoped>
ul.dropdown-menu.reports {
  min-width: 300px;
}
</style>
