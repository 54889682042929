<template lang="pug">
.company-settings
  spinner(v-if="!resolved")
  h3.hide-new-layout {{ 'company_admin.configuration.header' | i18n }}
  .row(v-if='resolved')
    .col-md-12
      h4 {{ 'company_admin.configuration.general_settings.title' | i18n }}
      .alert.alert-success(v-if="saveCompanySettingsSuccess") {{ 'company_admin.configuration.general_settings.success_text' | i18n }}
      p {{ 'company_admin.configuration.general_settings.desc' | i18n }}
      .row
        .col-md-4
          b {{ 'activerecord.attributes.company.company_language' | i18n }}
          select(v-model='generalSettings.locale')
            option(v-for="locale in availableLocales" :value="locale.id") {{ locale.name }}
        .col-md-4
          b {{ 'activerecord.attributes.company.time_zone' | i18n }}
          select(v-model='generalSettings.time_zone')
            option(v-for="zone in timezones" :value="zone") {{ zone }}
      .row
        .col-md-4
          b {{ 'activerecord.attributes.company.first_day_of_week' | i18n }}
          select(v-model='generalSettings.first_day_of_week')
            option(:value='0') {{ 'date.day_names.0' | i18n }}
            option(:value='1') {{ 'date.day_names.1' | i18n }}
        .col-md-4
          b {{ 'activerecord.attributes.company.temperature_unit' | i18n }}
          select(v-model='generalSettings.temperature_unit')
            option(value='C') {{ 'weather.unit.celsius' | i18n }}
            option(value='F') {{ 'weather.unit.fahrenheit' | i18n }}
      .row
        .col-md-4
          b {{ 'activerecord.attributes.company.date_format' | i18n }}
          select(v-model='generalSettings.date_format')
            option(:value="null") {{ 'date.locale_default' | i18n }}
            option(v-for="format in dateFormats" :value="format") {{ format }}
        .col-md-4
          b {{ 'activerecord.attributes.company.newsletter_time' | i18n }}
          time-select(v-model="generalSettings.newsletter_time")
      .row
        .col-md-4
        .col-md-4(v-if="custom_periods.length > 0")
          b {{ 'activerecord.attributes.company.use_business_calendar' | i18n }}
          select(v-model='generalSettings.use_business_calendar')
            option(:value='false') {{ 'options.no' | i18n }}
            option(:value='true') {{ 'options.yes' | i18n }}
      .mt-md
        button.btn.btn-primary.save-company-settings(:disabled="savingCompanySettings" @click='saveCompanySettings()') {{ 'actions.save' | i18n }}

      h4 {{ 'activerecord.attributes.company.business_hours' | i18n }}
      .alert.alert-success(v-if="savedBusinessHoursSuccess") {{ 'company_admin.configuration.business_hours.success_text' | i18n }}
      div
        business-hours-editor(@update="onUpdateBusinessHours")

      h4 {{ 'company_admin.configuration.dashboard_defaults.title' | i18n }}
      .alert.alert-success(v-if="savedDashboardDefaultsSuccess") {{ 'company_admin.configuration.dashboard_defaults.success_text' | i18n }}
      p {{ 'company_admin.configuration.dashboard_defaults.desc' | i18n }}
      div(class="dashboard-defaults-time")
        b {{ 'filters.selection_time' | i18n }}
        select(v-model='dashboard.selection')
          option(v-for="(value, name) in available_times" :value="value[0]") {{ value[1] }}
      div
        comparison-pill-list(:choices="comparisonChoices" :comparisons="dashboard.comparisons" :custom="false" :custom-scenario="false" @update="updateComparisons")
      .admin-filter
        widget-filter-selector(name="snippets" :config="dashboard.config.widgets.snippets" @update="updateWidget('snippets', $event)")
        widget-filter-selector(name="charts" :config="dashboard.config.widgets.charts" @update="updateWidget('charts', $event)")
        div
          component-span-selector(:value="dashboard.config.raw_filters.span" @input="updateComponentSpan")
        .filtersets
          filterset-selector(:config="dashboard.config.filters" @update="updateFiltersConfig")
        .filters
          filter-selector(v-for="filter in availableFilters" :key="filter.id" :filter="filter" :config="dashboard.config.filters.filters[filter.id]" @update="updateFilter(filter.id, $event)")

      button.btn.btn-primary(:disabled="savingDashboardDefaults", @click='saveDashboardDefaults()') {{ 'actions.save' | i18n }}

      div(v-if="features.analytics")
        h4 {{ 'company_admin.configuration.analytics_defaults.title' | i18n }}
        .alert.alert-success(v-if="savedAnalyticsDefaultsSuccess") {{ 'company_admin.configuration.analytics_defaults.success_text' | i18n }}
        p {{ 'company_admin.configuration.analytics_defaults.desc' | i18n }}
        div
          comparison-pill-list(:choices="comparisonChoices" :comparisons="analyticsDefaults.comparisons" :custom="false" :custom-scenario="false" :radio="true" @update="updateReportComparisons")
        div(class="report-defaults-save")
          button.btn.btn-primary(:disabled="savingAnalyticsDefaults", @click='saveReportDefaults()') {{ 'actions.save' | i18n }}

      h4 {{ 'company_admin.configuration.time_periods_configuration.title' | i18n }}
      p {{ 'company_admin.configuration.time_periods_configuration.desc' | i18n }}
      pill-list(:items="timePeriods" @update="updateReportSettings({time_periods: $event})")

      h4 {{ 'company_admin.configuration.comparisons_configuration.title' | i18n }}
      p {{ 'company_admin.configuration.comparisons_configuration.desc' | i18n }}

      pill-list(:items="comparisons" type="comparison" @update="updateComparisonSettings($event)")

      h4 {{ 'company_admin.configuration.metrics_configuration.title' | i18n }}
      p {{ 'company_admin.configuration.metrics_configuration.desc' | i18n }}
      configuration-pill-list(:objects="metrics" @save="saveObject('metrics', $event)" :type="'metric'" :locale='locale')

      h4 {{ 'company_admin.configuration.groupings_configuration.title' | i18n }}
      p {{ 'company_admin.configuration.groupings_configuration.desc' | i18n }}
      configuration-pill-list(:objects="groupings" @save="saveObject('groupings', $event)" :type="'grouping'" :locale='locale')

      h4 {{ 'company_admin.configuration.filters_configuration.title' | i18n }}
      p {{ 'company_admin.configuration.filters_configuration.desc' | i18n }}
      configuration-pill-list(:objects="filters" @save="saveObject('filters', $event)" :type="'filter'" :locale='locale')

      div(v-if="features.analytics")
        h4 {{ 'company_admin.configuration.available_reports.title' | i18n }}
        p {{ 'company_admin.configuration.available_reports.desc' | i18n }}
        div
          pill-button(:enabled="report.visible", :removable="false", v-for="report in reports", :key="report.name", @toggle='toggleReport(report)') {{report.title}}
</template>

<script lang="ts">
import { filterToFlyover } from "@/lib/filter-util";
import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment-timezone";
import adminCompaniesApi from "@/api/admin-companies-api";
import { ZoinedTimeRangeService } from "@/filters/zoined-time-range.service";
import _ from "lodash";
import { Watch } from "vue-property-decorator";
import TranslationService from "@/core/translation.service";
import { defaultComparisonTypes } from "../filters/time-period";
import { comparisonToMenuKey } from "../lib/menu-helper";
import Company from "@/model/company";
import TimePeriodConfig from "@/model/time-period-config";
import spinner from "@/components/spinner.vue";
import { makeApiInstance } from "@/api/instance";
import TimeSelect from "@/components/time-select.vue";
import businessHoursEditor from "@/components/business-hours-editor.vue";
import ComparisonPillList from "@/components/comparison-pill-list.vue";
import WidgetFilterSelector from "@/components/widget-filter-selector.vue";
import ComponentSpanSelector from "@/components/component-span-selector.vue";
import FiltersetSelector from "@/components/filterset-selector.vue";
import FilterSelector from "@/components/filter-selector.vue";
import ConfigurationPillList from "./configuration-pill-list.vue";
import PillList from "./pill-list.vue";
import pillButton from "@/components/pill-button.vue";
import Actions from "@/store/actions";

const zoinedTimeRangeService = new ZoinedTimeRangeService();

@Component({
  components: {
    spinner,
    TimeSelect,
    businessHoursEditor,
    ComparisonPillList,
    WidgetFilterSelector,
    ComponentSpanSelector,
    FiltersetSelector,
    FilterSelector,
    ConfigurationPillList,
    PillList,
    pillButton,
  },
})
export default class CompanySettings extends Vue {
  custom_periods = [];
  combinedPeriods = [];
  generalSettings: Partial<Company> = {};
  dashboard: any = null;
  analyticsDefaults = { comparisons: [] };
  available_times = [];
  timePeriods = [];

  timezones = moment.tz.names();

  company: Company = null;
  resolved = false;
  timePeriodsConfig: Record<string, TimePeriodConfig> = {};
  metrics = null;
  reports = null;
  groupings = null;
  filters = null;
  customPeriodsArray = null;
  timePeriodsArray = null;
  comparisonChoices = null;
  comparisons = null;
  dateFormats = null;
  availableFilters = null;
  availableLocales = null;
  savingCompanySettings = false;
  saveCompanySettingsSuccess = false;
  savingDashboardDefaults = false;
  savedDashboardDefaultsSuccess = false;
  savingAnalyticsDefaults = false;
  savedAnalyticsDefaultsSuccess = false;
  savedBusinessHoursSuccess = false;

  readonly locale = window.zoinedContext.locale;
  readonly companyId = window.zoinedContext.companyId;
  readonly features = window.zoinedContext.features;
  readonly limited = window.zoinedContext.limited;

  created() {
    const promises = [];
    promises.push(
      adminCompaniesApi.get({ id: this.companyId }).then((company) => {
        this.company = company;
      })
    );
    promises.push(
      makeApiInstance()
        .get("/api/v1/parameters/available_filters")
        .then((result) => {
          this.availableFilters = result.data;
        })
    );
    promises.push(
      makeApiInstance()
        .get("/api/v1/parameters/available_locales")
        .then((result) => {
          this.availableLocales = result.data.filter((locale) => locale.id && locale.id !== "");
        })
    );
    promises.push(
      fetch("/api/admin/companies/" + this.companyId + "/settings/metrics.json").then(async (result) => {
        this.metrics = await result.json();
      })
    );
    promises.push(
      fetch("/api/admin/companies/" + this.companyId + "/settings/reports.json").then(async (result) => {
        this.reports = await result.json();
      })
    );
    promises.push(
      fetch("/api/admin/companies/" + this.companyId + "/settings/groupings.json").then(async (result) => {
        this.groupings = await result.json();
      })
    );
    promises.push(
      fetch("/api/admin/companies/" + this.companyId + "/settings/filters.json").then(async (result) => {
        this.filters = await result.json();
      })
    );
    promises.push(
      fetch("/api/admin/companies/" + this.companyId + "/settings/custom_periods.json").then(async (result) => {
        let custom_data = [];

        const data = await result.json();

        data.map((x) => {
          switch (x) {
            case "custom_year":
              return custom_data.push("custom_current_year", "custom_current_full_year", "custom_last_year");
            case "custom_period":
              return custom_data.push(
                "custom_rolling_12_months",
                "custom_rolling_3_months",
                "custom_current_month",
                "custom_current_full_month",
                "custom_last_month"
              );
            case "custom_week":
              return custom_data.push(
                "custom_rolling_4_weeks",
                "custom_rolling_12_weeks",
                "custom_current_week",
                "custom_current_full_week",
                "custom_last_week",
                "custom_current_retail_week_until_today"
              );
            case "custom_season":
              return custom_data.push("custom_current_season", "custom_last_season", "custom_current_full_season");
          }
        });

        this.custom_periods = custom_data;
      })
    );

    Promise.all(promises).then(() => {
      const company = this.company;

      const dashboard: any = {};

      let timePeriodsConfig;
      // Dashboard defaults
      try {
        dashboard.config = filterToFlyover(JSON.parse(company.dashboard_defaults) || {});
      } catch (e) {
        // We'll just use the empty configuration if the saved one cannot be parsed.
        dashboard.config = filterToFlyover({});
      }
      if (!dashboard.config.raw_filters) {
        dashboard.config.raw_filters = {};
      }

      if (dashboard.config.time && dashboard.config.time.selection != null) {
        dashboard.selection = dashboard.config.time.selection.type;
      } else {
        dashboard.selection = "yesterday";
      }
      if (dashboard.config.time && dashboard.config.time.comparisons) {
        dashboard.comparisons = dashboard.config.time.comparisons;
      } else if (dashboard.config.time && dashboard.config.time.comparison != null) {
        dashboard.comparisons = [dashboard.config.time.comparison];
      } else {
        dashboard.comparisons = [{ type: "prev_year_corresponding", enabled: true }];
      }
      this.dashboard = dashboard;

      // Report defaults
      const analyticsDefaults = company.analytics_defaults;
      if (analyticsDefaults && analyticsDefaults.time && analyticsDefaults.time.comparisons) {
        this.analyticsDefaults.comparisons = analyticsDefaults.time.comparisons;
      } else {
        this.analyticsDefaults.comparisons = [{ type: "previous_corresponding_period", enabled: true }];
      }

      if (company.report_settings && company.report_settings.time_periods) {
        timePeriodsConfig = company.report_settings.time_periods.reduce(
          (config, timePeriod) => ({ ...config, [timePeriod.id]: timePeriod }),
          {}
        );
      } else {
        timePeriodsConfig = {};
      }

      this.timePeriodsConfig = timePeriodsConfig;

      this.customPeriodsArray = zoinedTimeRangeService.customPeriods(this.custom_periods);
      this.timePeriodsArray = zoinedTimeRangeService.timePeriods();

      this.combinedPeriods = this.timePeriodsArray.concat(this.customPeriodsArray || {});

      this.timePeriods = this.combinedPeriods.map(({ id, name: label }) =>
        Object.assign({ id, label, enabled: this.timeIsSaved(id), order: 999 }, timePeriodsConfig[id] || {})
      );

      this.timePeriods = _.sortBy(this.timePeriods, "order");

      this.buildComparisons();

      const benchmarks = ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"];
      this.comparisonChoices = this.comparisons.filter(({ enabled, type }) => enabled && !_.includes(benchmarks, type));

      this.generalSettings.time_zone = company.time_zone;
      this.generalSettings.locale = company.locale;
      this.generalSettings.first_day_of_week = company.first_day_of_week;
      this.generalSettings.temperature_unit = company.temperature_unit;
      this.generalSettings.use_business_calendar = company.use_business_calendar || false;
      this.generalSettings.date_format = company.date_format;
      this.generalSettings.newsletter_time = company.newsletter_time;

      this.dateFormats = ["YYYY-MM-DD", "MM/DD/YYYY", "DD/MM/YYYY", "DD.MM.YYYY", "YYYY.MM.DD"];

      this.resolved = true;
    });
  }

  @Watch("timePeriods")
  onTimePeriodsChange(timePeriods) {
    this.available_times = timePeriods
      ? timePeriods.filter(({ enabled }) => enabled).map(({ id, label }) => [id, label])
      : [];
  }

  buildComparisons() {
    const company = this.company,
      translationService = new TranslationService();

    const comparisonsConfig = (company.report_settings && company.report_settings.comparisons) || [];

    this.comparisons = window.zoinedContext.availableComparisons.map((comparison) => {
      const config =
        _.find(
          comparisonsConfig,
          ({ type, label, time_period }) =>
            type == comparison.type && label == comparison.label && time_period == comparison.time_period
        ) || {};

      return this.updateComparisonLabels({
        ...comparison,
        order: 999,
        enabled: _.isEmpty(comparisonsConfig) && defaultComparisonTypes.includes(comparison.type),
        ...config,
        name: translationService.comparisonTitle(comparison),
        key: comparisonToMenuKey(comparison),
      });
    });
    this.comparisons = _.sortBy(this.comparisons, "order");
  }

  updateComparisonLabels(comparison) {
    const translationService = new TranslationService();
    if (comparison.type !== "time_period") {
      Object.assign(comparison, {
        translatable: true,
        originalLabels: this.availableLocales.reduce(
          (labels, locale) => ({
            ...labels,
            [locale.id]: translationService.comparisonTitle(comparison, {
              locale: locale.id,
              // get original label
              custom: false,
            }),
          }),
          {}
        ),
        custom_labels: {
          ...this.availableLocales.reduce(
            (labels, locale) => ({
              ...labels,
              [locale.id]: translationService.comparisonTitle(comparison, {
                locale: locale.id,
              }),
            }),
            {}
          ),
        },
        name: translationService.comparisonTitle(comparison),
      });
    }
    return comparison;
  }

  updateComparisons(comparisons) {
    this.dashboard.comparisons = comparisons;
  }

  updateReportComparisons(comparisons) {
    this.analyticsDefaults.comparisons = comparisons;
  }

  updateComparisonSettings(comparisons) {
    // Dirty hack: update custom label translations from here directly
    comparisons.forEach(({ key, custom_labels }) => {
      window.zoinedContext.custom_labels.comparisons[key] = {
        ...custom_labels,
      };
    });

    // Update name
    comparisons = comparisons.map((comparison) => ({
      ...comparison,
      name: new TranslationService().comparisonTitle(comparison),
    }));

    this.comparisons = comparisons;

    // Update comparisons to server (first remove extra keys)
    // eslint-disable-next-line no-unused-vars
    const newComparisons = comparisons.map(({ key, translatable, originalLabels, ...rest }) => rest);

    this.updateReportSettings({ comparisons: newComparisons });
  }

  updateReportSettings(updated) {
    const company: Partial<Company> = {
      report_settings: {
        ...this.company.report_settings,
        time_periods: this.timePeriods,
        comparisons: this.comparisons,
        ...updated,
      },
    };
    if (updated.time_periods) {
      this.timePeriods = [...updated.time_periods];
    }
    return adminCompaniesApi.put({ id: this.companyId, company }).then(() => {
      if (updated.time_periods) {
        return this.$store.dispatch(Actions.fetchParameters("timePeriods"));
      }
    });
  }

  updateFilter(name, config) {
    this.$set(this.dashboard.config.filters.filters, name, config);
  }

  updateWidget(name, config) {
    this.$set(this.dashboard.config.widgets, name, config);
  }

  updateFiltersConfig(config) {
    this.$set(this.dashboard.config, "filters", config);
  }

  updateComponentSpan(value) {
    this.$set(this.dashboard.config.raw_filters, "span", value);
  }

  updateNewsletterTime(value) {
    this.generalSettings.newsletter_time = value;
  }

  saveCompanySettings() {
    this.savingCompanySettings = true;
    return adminCompaniesApi
      .put({ id: this.companyId, company: this.generalSettings })
      .then(() => {
        this.saveCompanySettingsSuccess = true;
        setTimeout(() => {
          this.saveCompanySettingsSuccess = null;
        }, 3000);
      })
      .finally(() => (this.savingCompanySettings = false));
  }

  saveDashboardDefaults() {
    const config = _.cloneDeep(this.dashboard.config);
    config.time = {
      selection: { type: this.dashboard.selection },
      comparisons: this.dashboard.comparisons,
    };
    const company: Partial<Company> = {
      dashboard_defaults: JSON.stringify(config),
    };

    this.savingDashboardDefaults = true;
    return adminCompaniesApi
      .put({
        id: this.companyId,
        company,
      })
      .then(() => {
        this.savingDashboardDefaults = false;
        this.savedDashboardDefaultsSuccess = true;
        setTimeout(() => {
          this.savedDashboardDefaultsSuccess = null;
        }, 3000);
      });
  }

  saveReportDefaults() {
    const company: Partial<Company> = {
      analytics_defaults: {
        time: {
          comparisons: this.analyticsDefaults.comparisons,
        },
      },
    };
    this.savingAnalyticsDefaults = true;
    return adminCompaniesApi
      .put({
        id: this.companyId,
        company,
      })
      .then(() => {
        this.savingAnalyticsDefaults = false;
        this.savedAnalyticsDefaultsSuccess = true;
        setTimeout(() => {
          this.savedAnalyticsDefaultsSuccess = null;
        }, 3000);
      });
  }

  timeIsSaved(time) {
    if (Object.values(this.timePeriodsConfig).length === 0) {
      // By default enable only default time periods (not custom)
      return zoinedTimeRangeService.defaultTimePeriodIds.includes(time);
    }

    return (
      Object.values(this.timePeriodsConfig).filter((x) => {
        return x.id === time && x.enabled;
      }).length > 0
    );
  }

  saveObject(type, { key, config }) {
    fetch("/api/admin/companies/" + this.companyId + "/settings/" + type + "/" + key, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        setting: {
          invisible: config.invisible,
          label: config.label,
        },
      }),
    }).then(() => {
      this[type][key].label = config.label;
      this[type][key].invisible = config.invisible;
      const linkedObjects = (() => {
        switch (type) {
          case "groupings":
            return this.filters;
          case "filters":
            return this.groupings;
        }
      })();
      if (linkedObjects && linkedObjects[key]) {
        linkedObjects[key].label = _.cloneDeep(config.label);
      }
    });
  }

  toggleReport(report) {
    const v = !report.visible;
    return fetch("/api/admin/companies/" + this.companyId + "/settings/reports/" + report.name, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        visible: v,
      }),
    }).then(() => {
      report.visible = v;
    });
  }

  onUpdateBusinessHours() {
    this.savedBusinessHoursSuccess = true;
    setTimeout(() => {
      this.savedBusinessHoursSuccess = null;
    }, 3000);
  }
}
</script>
