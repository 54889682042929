<template lang="pug">
.sidenav-new-analytics
  sidenav-category(:category="category" :force-open="true" v-for="category in navigationItems" :key="category.name")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import SidenavCategory from "./sidenav-category.vue";

@Component({
  components: { SidenavCategory },
})
export default class SidenavNewAnalytics extends Vue {
  get navigationItems(): any[] {
    return this.$store.state.navigation.data?.new_analytics || [];
  }
}
</script>
