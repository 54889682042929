<template>
  <toggle-button
    :value="value == 'value'"
    :true-title="'Value'"
    :false-title="'Change'"
    button-class="btn-sm nl-btn-xs"
    @input="onToggle"
  ></toggle-button>
</template>

<script setup lang="ts">
import ToggleButton from "@/components/toggle-button.vue";

defineProps<{
  value: string;
}>();

const emit = defineEmits(["input"]);

const onToggle = (value: boolean) => {
  emit("input", value ? "value" : "change");
};
</script>
