import { configToActive, selectFilterableGroupingFromTimeRange, selectGroupingFromTimeRange } from "@/lib/filter-util";
import I18n from "../i18n";
import _ from "lodash";
import Vue from "vue";
import { menuItemKey } from "@/interfaces/menu-item";

const likeForLikeWidget = {
  key: "like_for_like",
  component: "like-for-like",
  enabled: (component) => component.effectiveComparisons.filter(({ enabled }) => enabled).length == 1,
};

const zeroFillWidget = {
  key: "zerofill",
  default: "true",
  component: "zerofill",
};

export const availableComponents = {
  dashboard_snippet: {
    type: "dashboard_snippet",
    name: "dashboard_snippet",
    span: 6,
    spanOptions: [3, 4, 6],
    widgets: [
      {
        key: "metrics",
        radio: true,
        draggable: true,
      },
      likeForLikeWidget,
      {
        key: "metric_type",
        default: "value",
        title: I18n.t("filter.config.metric_type"),
        component: "filter-dropdown",
        options: [
          { key: "value", name: I18n.t("filter.metric_type.value") },
          { key: "index", name: I18n.t("filter.metric_type.index") },
        ],
      },
    ],
  },
  metric_chart: {
    type: "metric_chart",
    name: "metric_chart",
    extend_comparison: true,
    pagination: false,
    span: 12,
    widgets: [
      {
        key: "metrics",
        radio: true,
        draggable: true,
      },
      likeForLikeWidget,
      zeroFillWidget,
    ],
  },
  cross_table: {
    type: "snippet",
    name: "cross_table",
    pagination: true,
    span: 12,
    widgets: [
      {
        key: "grouping",
        options: (component) => component.options.groupings,
        draggable: true,
      },
      {
        key: "metrics",
        radio: false,
        draggable: true,
      },
      { key: "sort", radio: true, draggable: true },
      { key: "metric_range", component: "metric-range" },
      likeForLikeWidget,
      zeroFillWidget,
    ],
    defaults: {
      limit: 100,
    },
  },
  cross_tab_custom: {
    type: "snippet",
    name: "cross_tab_custom",
    pagination: true,
    span: 12,
    widgets: [
      {
        key: "grouping",
        options: (component) => component.options.groupings,
        draggable: true,
      },
      {
        key: "column_grouping",
        radio: true,
        draggable: true,
        options: (component) => component.options.groupings,
      },
      {
        key: "metrics",
        radio: false,
        draggable: true,
      },
      { key: "sort", radio: true, draggable: true },
      likeForLikeWidget,
      zeroFillWidget,
    ],
    validate(component) {
      return component.isPresent("metrics") && component.isPresent("grouping");
    },
    defaults: {
      limit: 100,
    },
  },
  pie: {
    type: "chart",
    name: "pie",
    pagination: false,
    limit: true,
    span: 6,
    spanOptions: [3, 4, 6, 12],
    limitOptions: [3, 5, 10, 15, 20],
    widgets: [
      {
        key: "grouping",
        options: (component) => component.options.groupings,
        draggable: true,
      },
      {
        key: "metrics",
        radio: true,
        draggable: true,
      },
      {
        key: "variant",
        default: "top",
        title: I18n.t("table.show"),
        component: "filter-dropdown",
        options: [
          { key: "top", name: I18n.t("chart.pie.variant.top") },
          { key: "selection", name: I18n.t("chart.pie.variant.selection") },
        ],
      },
      {
        key: "selected_items",
        component: "filter-selector",
        enabled: (component) => component.componentWidgetConfig.variant == "selection",
        options: (component) => component.groupingFilter,
      },
      {
        key: "show_values",
        component: "filter-dropdown",
        options: [
          { key: "percentage", name: I18n.t("dashboard.show_values.percentage") },
          { key: "values", name: I18n.t("dashboard.show_values.values") },
          { key: "both", name: I18n.t("dashboard.show_values.both") },
        ],
        default: "percentage",
      },
    ],
    forceSelected: {
      comparisons: [],
    },
    validate(component) {
      return (
        component.isPresent("metrics") &&
        component.isPresent("grouping") &&
        (component.componentWidgetConfig.variant == "top" ||
          (component.componentWidgetConfig.variant == "selection" && component.isPresent("selected_items")))
      );
    },
    defaults: { limit: 5 },
  },
  dual_axis: {
    type: "chart",
    name: "dual_axis",
    pagination: true,
    span: 12,
    widgets: [
      {
        key: "grouping",
        options: (component) => component.options.groupings,
        draggable: true,
      },
      {
        key: "metrics",
        draggable: true,
      },
      {
        key: "metrics2",
        options: (component) => component.options.metrics,
        draggable: true,
      },
      { key: "sort", radio: true, draggable: true },
      likeForLikeWidget,
      zeroFillWidget,
      { key: "metric_range", component: "metric-range" },
    ],
    validate(component) {
      return component.isPresent("grouping") && (component.isPresent("metrics") || component.isPresent("metrics2"));
    },
  },
  dual_axis_with_weather: {
    type: "chart",
    name: "dual_axis_with_weather",
    pagination: true,
    span: 12,
    help_key: "weather",
    required_features: ["weather"],
    widgets: [
      {
        key: "grouping",
        radio: true,
        options: (component) =>
          component.options.groupings.filter((grouping) => ["store", "day"].includes(grouping.key)),
        draggable: true,
      },
      {
        key: "metrics",
        draggable: true,
      },
      {
        key: "metrics2",
        options: (component) => component.options.metrics,
        draggable: true,
      },
      { key: "sort", radio: true, draggable: true },
      likeForLikeWidget,
      zeroFillWidget,
      { key: "metric_range", component: "metric-range" },
    ],
    validate(component) {
      return (
        (component.isPresent("metrics") || component.isPresent("metrics2")) &&
        ((component.currentGrouping === "day" && component.effectiveFilters.store?.length === 1) ||
          (component.currentGrouping === "store" &&
            component.effectiveFilters.start_time === component.effectiveFilters.end_time))
      );
    },
  },
  stacked: {
    type: "chart",
    name: "stacked",
    drilldown: true,
    pagination: true,
    span: 12,
    widgets: [
      {
        key: "grouping_x",
        radio: true,
        draggable: true,
        default: (component) =>
          component.options.groupings.find(({ key }) => key == "store") && {
            store: { enabled: true },
          },
        options: (component) => component.options.groupings,
      },
      {
        key: "grouping_stack",
        radio: true,
        draggable: true,
        default: (component) =>
          component.options.groupings.find(({ key }) => key == "category") && {
            category: { enabled: true },
          },
        options: (component) => component.options.groupings,
      },
      {
        key: "sort_stack",
        radio: true,
        draggable: true,
        options: (component) => component.options.sort,
      },
      {
        key: "metrics",
        radio: true,
        draggable: true,
      },
      {
        key: "variant",
        default: "all",
        title: I18n.t("table.show"),
        component: "filter-dropdown",
        options: [
          { key: "all", name: I18n.t("filter.config.all") },
          { key: "top", name: I18n.t("filter.config.top_only") },
          { key: "top_other", name: I18n.t("filter.config.top_other") },
          {
            key: "selection",
            name: I18n.t("filter.config.selection_only"),
          },
          {
            key: "selection_other",
            name: I18n.t("filter.config.selection_other"),
          },
        ],
      },
      {
        key: "limit_y",
        default: 20,
        title: I18n.t("newsletter.definition.sections.common.entries_to_show"),
        component: "filter-dropdown",
        enabled: ({ componentWidgetConfig: { variant } }) => variant == "top" || variant == "top_other",
        options: [5, 10, 20, 30, 40].map((key) => ({
          key,
          name: key,
        })),
      },
      {
        key: "selected_items",
        component: "filter-selector",
        enabled: ({ componentWidgetConfig: { variant } }) => variant == "selection" || variant == "selection_other",
        options: ({ componentWidgetConfig, options }) => {
          // Find current stack grouping
          const currentGrouping = _.map(componentWidgetConfig.grouping_stack, ({ enabled }, key) => ({
            key,
            enabled,
          }))
            .filter(({ enabled }) => enabled)
            .map(({ key }) => key)[0];

          // Find filter configuration for current stack grouping
          return _.find(options.filters, ({ id }) => id == currentGrouping);
        },
      },
    ],
    validate(component) {
      const {
        componentWidgetConfig: { variant },
      } = component;
      return (
        component.isPresent("metrics") &&
        component.isPresent("grouping_x") &&
        component.isPresent("grouping_stack") &&
        (variant == "selection" || variant == "selection_other" ? component.isPresent("selected_items") : true)
      );
    },
    onComponentChanged(component, newConfig, oldConfig) {
      if (
        newConfig.filterConfiguration.grouping_stack != oldConfig.filterConfiguration.grouping_stack &&
        oldConfig.filterConfiguration.grouping_stack
      ) {
        Vue.set(component.componentWidgetConfig, "selected_items", null);
      }
    },
  },
  analysis_4d: {
    type: "chart",
    name: "analysis_4d",
    pagination: true,
    span: 12,
    widgets: [
      {
        key: "grouping",
        options: (component) => component.options.groupings,
        draggable: true,
      },
      {
        key: "metrics_x",
        radio: true,
        draggable: true,
        options: (component) => component.options.metrics,
        default: (component) =>
          component.options.metrics.find(({ id }) => id == "sales") && {
            sales: { enabled: true },
          },
      },
      {
        key: "metrics_y",
        radio: true,
        draggable: true,
        options: (component) => component.options.metrics,
        default: (component) =>
          component.options.metrics.find(({ id }) => id == "sales_pcs") && {
            sales_pcs: { enabled: true },
          },
      },
      {
        key: "metrics_z",
        radio: true,
        draggable: true,
        options: (component) => component.options.metrics,
        default: (component) =>
          component.options.metrics.find(({ id }) => id == "order_count") && {
            order_count: { enabled: true },
          },
      },
    ],
    forceSelected: {
      comparisons: [],
    },
    validate(component) {
      return (
        component.isPresent("metrics_x") &&
        component.isPresent("metrics_y") &&
        component.isPresent("metrics_z") &&
        component.isPresent("grouping")
      );
    },
  },
  heatmap: {
    type: "chart",
    name: "heatmap",
    drilldown: true,
    span: 12,
    pagination: true,
    widgets: [
      {
        key: "metrics",
        radio: true,
        draggable: true,
      },
      {
        key: "grouping_x",
        radio: true,
        draggable: true,
        default: { day: { enabled: true } },
        options: (component) => component.options.groupings,
      },
      {
        key: "grouping_y",
        radio: true,
        draggable: true,
        default: { hour: { enabled: true } },
        options: (component) => component.options.groupings,
      },
      {
        key: "sort_x",
        radio: true,
        draggable: true,
        options: (component) => component.options.sort,
      },
      {
        key: "sort_y",
        radio: true,
        draggable: true,
        options: (component) => component.options.sort,
      },
      {
        key: "variant",
        default: "all",
        title: I18n.t("table.show"),
        component: "filter-dropdown",
        options: [
          { key: "all", name: I18n.t("filter.config.all") },
          { key: "top", name: I18n.t("chart.pie.variant.top") },
        ],
      },
      {
        key: "limit_y",
        default: 20,
        title: I18n.t("newsletter.definition.sections.common.entries_to_show"),
        component: "filter-dropdown",
        enabled: (component) => component.componentWidgetConfig.variant == "top",
        options: [5, 10, 20, 30, 40].map((key) => ({
          key,
          name: key,
        })),
      },
      {
        key: "proportions_set",
        default: "all",
        component: "filter-dropdown",
        options: [
          { key: "all", name: I18n.t("filter.config.all") },
          { key: "per_x", name: I18n.t("filter.proportions_set.per_x") },
          { key: "per_y", name: I18n.t("filter.proportions_set.per_y") },
        ],
      },
    ],
    defaults: {
      limit: 30,
    },
    forceSelected: {
      comparisons: [],
    },
  },
  sales_trend: {
    type: "chart",
    name: "sales_trend",
    pagination: true,
    span: 12,
    widgets: [
      {
        key: "grouping",
        options: (component) => component.options.groupings?.filter((item) => item.category_key !== "Time"),
        draggable: true,
        default: { category: { enabled: true } },
      },
      {
        key: "metrics",
        draggable: true,
      },
      {
        key: "trend",
        component: "filter-dropdown",
        default: "top",
        options: [
          { key: "top", name: I18n.t("filter.trend.top") },
          { key: "bottom", name: I18n.t("filter.trend.bottom") },
        ],
      },
    ],
    validate(component) {
      return component.isPresent("grouping") && component.isPresent("metrics");
    },
  },
  line: {
    type: "chart",
    name: "line",
    pagination: true,
    span: 12,
    widgets: [
      {
        key: "grouping",
        radio: true,
        draggable: true,
        options: (component) => component.options.groupings,
        default: (component) => {
          const { start_time, end_time } = component.effectiveFilters;
          const grouping = selectGroupingFromTimeRange(start_time, end_time);
          return { [grouping]: { enabled: true } };
        },
      },
      {
        key: "grouping_y",
        radio: true,
        draggable: true,
        options: (component) => component.options.groupings,
        default: (component) => {
          const hasStoreGrouping = _.find(component.options.groupings, (grouping) => grouping.key === "store");
          if (hasStoreGrouping) {
            return { store: { enabled: true } };
          }
        },
      },
      {
        key: "metrics",
        draggable: true,
        radio: true,
      },
      {
        key: "metrics2",
        draggable: true,
        radio: true,
        options: (component) => component.options.metrics,
      },

      {
        key: "sort",
        radio: true,
        draggable: true,
        options: (component) => component.options.sort,
        default: { "-metric": { enabled: true } },
      },
      {
        key: "variant",
        default: "all",
        title: I18n.t("table.show"),
        component: "filter-dropdown",
        options: [
          { key: "all", name: I18n.t("filter.config.all") },
          { key: "top", name: I18n.t("filter.config.top_only") },
          { key: "top_other", name: I18n.t("filter.config.top_other") },
          {
            key: "selection",
            name: I18n.t("filter.config.selection_only"),
          },
          {
            key: "selection_other",
            name: I18n.t("filter.config.selection_other"),
          },
        ],
        enabled: (component) => {
          return component.isPresent("grouping");
        },
      },
      {
        key: "limit_y",
        default: 5,
        title: I18n.t("newsletter.definition.sections.common.entries_to_show"),
        component: "filter-dropdown",
        enabled: (component) => {
          const { variant } = component.componentWidgetConfig;
          return component.isPresent("grouping") && (variant == "top" || variant == "top_other");
        },
        options: [5, 10, 15, 20].map((key) => ({
          key,
          name: key,
        })),
      },
      {
        key: "selected_items",
        component: "filter-selector",
        enabled: (component) => {
          const { variant } = component.componentWidgetConfig;
          return component.isPresent("grouping") && (variant == "selection" || variant == "selection_other");
        },
        options: ({ componentWidgetConfig, options }) => {
          // Find current stack grouping
          const currentGrouping = _.map(componentWidgetConfig.grouping, ({ enabled }, key) => ({
            key,
            enabled,
          }))
            .filter(({ enabled }) => enabled)
            .map(({ key }) => key)[0];

          // Find filter configuration for current stack grouping
          return _.find(options.filters, ({ id }) => id == currentGrouping);
        },
      },
    ],
    validate(component) {
      const { variant } = component.componentWidgetConfig;
      return (
        component.isPresent("metrics") &&
        (variant == "selection" || variant == "selection_other" ? component.isPresent("selected_items") : true)
      );
    },
    defaults: {
      limit: 30,
    },
  },
  trend_insights: {
    type: "chart",
    name: "trend_insights",
    pagination: true,
    span: 12,
    widgets: [
      {
        key: "grouping",
        options: (component) => component.options.filters,
        draggable: true,
        radio: true,
        default(component) {
          const { start_time, end_time } = component.effectiveFilters;
          const grouping = selectFilterableGroupingFromTimeRange(start_time, end_time);
          return { [grouping]: { enabled: true } };
        },
      },
      {
        key: "grouping_y",
        options: (component) => component.options.filters,
        draggable: true,
        radio: true,
        default(component) {
          const key =
            ["store", "category", "supplier"].find((key) =>
              component.options.filters.find((it) => menuItemKey(it) == key)
            ) || component.options.filters[0].key;
          return { [key]: { enabled: true } };
        },
      },
      {
        key: "metrics",
        draggable: true,
        radio: true,
      },
      {
        key: "limit_y",
        default: 5,
        title: I18n.t("newsletter.definition.sections.common.entries_to_show"),
        component: "filter-dropdown",
        options: [5, 10, 15, 20].map((key) => ({
          key,
          name: key,
        })),
      },
    ],
    validate(component) {
      return component.isPresent("grouping") && component.isPresent("grouping_y") && component.isPresent("metrics");
    },
    defaults: {
      sort: { "=balanced": { enabled: true } },
    },
  },
  // {
  //   type: "chart",
  //   name: "pyramid"
  // },
};
