import { render, staticRenderFns } from "./custom-dashboard-editor.vue?vue&type=template&id=55f8eada&scoped=true&lang=pug"
import script from "./custom-dashboard-editor.vue?vue&type=script&lang=ts"
export * from "./custom-dashboard-editor.vue?vue&type=script&lang=ts"
import style0 from "./custom-dashboard-editor.vue?vue&type=style&index=0&id=55f8eada&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f8eada",
  null
  
)

export default component.exports