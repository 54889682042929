<template lang="pug">
confirm-button.btn.btn-default.btn-dark-new-layout.btn-sm.btn-icon.report-action-button(
  tag="button",
  v-uiv-tooltip.hover="setAsDefaultTooltip",
  :title="'actions.set_as_default' | i18n",
  :popover-message="'dashboard_custom.set_as_default_confirm_message' | i18n",
  :disabled="isDefault",
  @confirm="setAsDefault()"
)
  i.fa.fa-star.fa-lg.favorite(v-if="isDefault")
  i.fa.fa-star-o.fa-lg(v-else)
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import EventBus from "../events/event-bus";
import Events from "../events/events";
import i18n from "../i18n";
import ConfirmButton from "../components/confirm-button.vue";
import ReportContext from "@/model/report-context";

@Component({
  components: {
    ConfirmButton,
  },
})
export default class FavoriteReportButton extends Vue {
  get reportContext(): ReportContext {
    return this.$store.getters.getReportContext;
  }

  get defaultDashboardId(): number {
    return this.$store.getters.defaultDashboardId;
  }

  get isDefault() {
    if (this.reportContext?.report_type === "system_dashboard") {
      return !this.defaultDashboardId;
    } else {
      return this.reportContext?.report?.id === this.defaultDashboardId;
    }
  }

  get setAsDefaultTooltip() {
    return i18n.t("actions.set_as_default");
  }
  setAsDefault() {
    EventBus.emit(Events.setAsDefault);
  }
}
</script>

<style lang="scss" scoped>
.favorite {
  color: var(--color-yellow);
}
</style>
