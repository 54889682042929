<template lang="pug">
.flyover-filters(v-if="reportContext")
  .structure
    .container
      .row(v-if="errors.length > 0")
        .col-md-12
          .alert.alert-danger
            | {{ errors.join(", ") }}
      .row(v-if="warnings.length > 0")
        .col-md-12
          .alert.alert-warning
            | {{ warnings.join(", ") }}

      .row
        .col-xs-10.col-md-9
          .time-structure
            timePeriodDropdown(v-if="isEnabled('date')" :value="filterConfiguration.time.selection" :available-items="timePeriods" :available-series-types="seriesTypes" @update="updateTime('selection', $event)")
            timePeriodDropdown(v-if="isEnabled('sale_date')" :title="'filters.selection_sale_time' | i18n" :value="filterConfiguration.time.selection" :custom="false" :show-items="saleDateTimePeriods.length" :available-items="saleDateTimePeriods" @update="updateTime('selection', $event)")
            noOfPastWeeksComparisonDropdown(v-if="isEnabled('no_of_past_weeks')" :comparison="filterConfiguration.time.comparisons[0]" @update="updateTime('comparisons', [$event])")
            comparisonPillList(v-if="isEnabled('comparisons')", :pill-style="'button'", :radio="isRadio('comparisons')", :comparisons="filterConfiguration.time.comparisons" :choices="comparisonChoices", @update="updateTime('comparisons', $event)")
            businessHoursSelector(v-if="isEnabled('limit_to_business_hours')", :value="filterConfiguration.time.limit_to_business_hours", @update="updateTime('limit_to_business_hours', $event)")
          widget-filters-section(:filter-configuration="filterConfiguration" :report-config="reportConfig" @update="filterConfiguration = $event")
        .col-xs-2.col-md-3.buttons(v-if="allowExport")
          a.export-link.export-link-pdf(@click="exportPdf()", href="#", :title="'filters.export_pdf' | i18n")
            span.text {{ 'filters.export_pdf' | i18n }}
            i.fa.fa-file-pdf-o.fa-lg
          a.export-link.export-link-xlsx(v-if="excelEnabled", @click="exportXlsx()", href="#", :title="'filters.export_xlsx' | i18n")
            span.text {{ 'filters.export_xlsx' | i18n }}
            i.fa.fa-file-excel-o.fa-lg

        .col-sm-10(v-if="isEnabled('basket_filters')")
          basket-filters-section(v-model="filterConfiguration.basket_filters" :items="filterItems")

  .real-filters
    .container.container-sm-height
      .row.row-sm-height
        .col-sm-10.col-sm-height
          .filtersets
            filtersetSelector(:config="filterConfiguration.filters", @update="updateFiltersConfig")
            showFiltersetNameToggle(v-if="hasEnabledFilterSets && chartOptionsData" :value="chartOptionsData.show_filterset_name", @input="updateChartOptions('show_filterset_name', $event)")
          .special-filters
            minAmountSelector(v-if="isEnabled('minamount')" :config="filterConfiguration.filters.filters.minamount" @update="updateFilter('minamount', $event)")
            minAmountSelector(v-if="isEnabled('min_inventory_value')" :title="minInventoryValueTitle" :config="filterConfiguration.filters.filters.min_inventory_value" @update="updateFilter('min_inventory_value', $event)")
          .real-filters
            filterSelector(:config="filterConfiguration.filters.filters[filter.id]", :filter="filter", @update="updateFilter(filter.id, $event)", v-for="filter in filterItems" :key="filter.id")
        .col-sm-2.col-sm-height.col-bottom.buttons
          .btn-wrap
          .btn-wrap
            a.btn.btn-primary.refresh-button(@click="refresh()") {{'actions.refresh' | i18n }}
</template>

<script lang="ts">
import _ from "lodash";
import { filterToFlyover, validateFilters, VALIDATION_ERROR, VALIDATION_WARNING } from "../lib/filter-util";
import FilterConfiguration from "../model/filter-configuration";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import FiltersConfiguration from "../model/filters-configuration";
import ChartOptions from "../model/chart-options";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import TranslationService from "../core/translation.service";
import i18n from "../i18n";
import timePeriodDropdown from "../components/time-period-dropdown.vue";
import comparisonPillList from "../components/comparison-pill-list.vue";
import businessHoursSelector from "../components/business-hours-selector.vue";
import noOfPastWeeksComparisonDropdown from "../components/no-of-past-weeks-comparison-dropdown.vue";
import widgetFilterSelector from "../components/widget-filter-selector.vue";
import metricRangeSelector from "../components/metric-range-selector.vue";
import rangefilterUnionSelector from "../components/rangefilter-union-selector.vue";
import likeForLikeSelector from "../components/like-for-like-selector.vue";
import zerofillSelector from "../components/zerofill-selector.vue";
import filterSelector from "../components/filter-selector.vue";
import filtersetSelector from "../components/filterset-selector.vue";
import showFiltersetNameToggle from "../components/show-filterset-name-toggle.vue";
import minAmountSelector from "../components/min-amount-selector.vue";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import Mutations from "../store/mutations";
import { exportDashboard, exportReport } from "../lib/export";
import TableConfig from "../model/table-config";
import Report from "../model/report";
import ReportContext from "../model/report-context";
import ReportConfig from "../model/report-config";
import WidgetFiltersSection from "./widget-filters-section.vue";
import BasketFiltersSection from "./basket-filters-section.vue";

@Component({
  components: {
    timePeriodDropdown,
    comparisonPillList,
    businessHoursSelector,
    noOfPastWeeksComparisonDropdown,
    widgetFilterSelector,
    metricRangeSelector,
    rangefilterUnionSelector,
    likeForLikeSelector,
    zerofillSelector,
    filterSelector,
    filtersetSelector,
    showFiltersetNameToggle,
    minAmountSelector,
    WidgetFiltersSection,
    BasketFiltersSection,
  },
})
export default class FlyoverFilters extends Vue {
  filterConfiguration: FilterConfiguration = null;
  chartOptionsData: ChartOptions = null;

  errors = [];
  warnings = [];

  get reportContext(): ReportContext {
    return this.$store.getters.getReportContext;
  }

  get config(): FilterConfiguration {
    return this.reportContext?.filter_configuration;
  }

  get chartOptions(): ChartOptions {
    return this.reportContext?.chart_options;
  }

  get tableConfig(): TableConfig {
    return this.reportContext?.table_config;
  }

  get reportConfig(): ReportConfig {
    return (
      this.reportContext?.report?.config ||
      ({
        filters: this.reportContext.active_filters,
      } as ReportConfig)
    );
  }

  get dependencies() {
    return [this.config, this.chartOptions];
  }

  get zoinedContext() {
    return window.zoinedContext;
  }

  get allowExport() {
    return this.zoinedContext.allowExport;
  }

  get excelEnabled() {
    return this.$store.getters.getReportContext?.excel_export;
  }

  get hasEnabledFilterSets() {
    const { sets } = this.filterConfiguration.filters || {};
    const enabledSets = sets?.filter(({ enabled }) => enabled);
    return enabledSets && enabledSets.length > 0;
  }

  get activeFilters() {
    return this.$store.getters.getReportContext?.active_filters;
  }

  get timePeriods(): MenuItem[] {
    return this.$store.getters.getParameters("timePeriods");
  }

  get seriesTypes() {
    return window.zoinedContext.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];
  }

  get saleDateTimePeriods() {
    return [
      "whole_history",
      "last_7_days",
      "last_30_days",
      "last_90_days",
      "last_180_days",
      "last_365_days",
      "last_2_years",
    ].map((key) => this.selectionMenuItem(key));
  }

  get comparisonChoices() {
    return this.$store.getters.getParameters("comparisons");
  }

  get filterItems(): MenuItem[] {
    return this.$store.getters.getParameters("filters");
  }

  get enabledFilterIds() {
    return this.filterItems?.map((item) => menuItemKey(item)) || [];
  }

  get minInventoryValueTitle() {
    return i18n.t("filter.config.min", { metric: i18n.t("filter.config.inventory_value") });
  }

  get report(): Report {
    return this.reportContext.report;
  }

  refresh() {
    let config = {};

    Object.assign(config, this.filterConfiguration);

    const validationErrors = validateFilters(config);

    this.errors = validationErrors.filter(({ level }) => level === VALIDATION_ERROR).map(({ message }) => message);
    this.warnings = validationErrors.filter(({ level }) => level === VALIDATION_WARNING).map(({ message }) => message);
    if (!_.isEmpty(this.errors)) {
      return;
    }

    this.$store.commit(Mutations.setFilterConfiguration, config);
    this.$store.commit(Mutations.setChartOptions, this.chartOptionsData);
    this.$store.commit(Mutations.closeFlyoverFilters);
  }

  isEnabled(filter) {
    return this.activeFilters?.includes(filter);
  }

  updateTime(key, config) {
    console.log("updateTime", key, config);
    Vue.set(this.filterConfiguration.time, key, config);
  }

  updateWidget(widget, config) {
    Vue.set(this.filterConfiguration.widgets, widget, config);
  }

  updateBasketFilters(config) {
    Vue.set(this.filterConfiguration, "basket_filters", config);
  }

  updateFilter(filter: string, config: FilterConfigurationItemMap) {
    Vue.set(this.filterConfiguration.filters, "filters", {
      ...this.filterConfiguration.filters.filters,
      [filter]: config,
    });
  }

  updateRawFilters(key, config) {
    Vue.set(this.filterConfiguration, "raw_filters", {
      ...(this.filterConfiguration.raw_filters || {}),
      [key]: config,
    });
  }

  updateFiltersConfig(config: FiltersConfiguration) {
    this.filterConfiguration.filters = config;
  }

  updateChartOptions(key, value) {
    Vue.set(this.chartOptionsData, key, value);
  }

  selectionMenuItem(key): MenuItem {
    return {
      key,
      name: new TranslationService().selectionTitle({ type: key }),
    };
  }

  isRadio(widget) {
    const radioSelectors = this.$store.getters.getReportContext?.radio_selectors;

    return radioSelectors?.includes(widget);
  }

  exportPdf() {
    if (this.report) {
      exportReport(this.report.name, {
        filterConfiguration: this.config,
        chartOptions: this.chartOptions,
        tableConfig: this.tableConfig,
        format: "pdf",
      });
    } else {
      exportDashboard({
        filterConfiguration: this.config,
        format: "pdf",
      });
    }
  }

  exportXlsx() {
    exportReport(this.report.name, {
      filterConfiguration: this.config,
      chartOptions: this.chartOptions,
      tableConfig: this.tableConfig,
      format: "xlsx",
    });
  }

  @Watch("dependencies", { deep: true, immediate: true })
  updateModel(newVal, oldVal) {
    if (_.isEqual(newVal, oldVal)) {
      return;
    }
    const filterConfiguration: FilterConfiguration | any = filterToFlyover(this.config || {});
    Vue.set(this, "filterConfiguration", filterConfiguration);
    Vue.set(this, "chartOptionsData", _.cloneDeep(this.chartOptions));
  }

  @Watch("enabledFilterIds")
  removeOrphanedFilters() {
    if (!this.filterConfiguration.filters) {
      return;
    }
    if (this.enabledFilterIds.length > 0) {
      const filters = _.reduce(
        this.filterConfiguration.filters.filters,
        (result, filters, dimension) => {
          return Object.assign(
            result,
            // basket cross sell
            dimension == "minamount" ||
              // shelf warmers (min_inventory_value)
              dimension.startsWith("min_") ||
              // regular filters
              this.enabledFilterIds.includes(dimension)
              ? { [dimension]: filters }
              : {}
          );
        },
        {}
      );
      // Update filters if needed
      if (!_.isEqual(this.filterConfiguration.filters.filters, filters)) {
        this.filterConfiguration.filters.filters = filters;
        this.refresh();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flyover-filters {
  background-color: #333436;
  padding-top: 30px;
}
</style>
