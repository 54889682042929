<template lang="pug">
pill-list(:title="'activerecord.models.user' | i18n", :typeahead="true", :items="items" :available-items="menuItems", @update="onUpdate($event)", @search="fetchUsers($event)")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import PillItem from "../interfaces/pill-item";
import MenuItem from "../interfaces/menu-item";
import pillList from "./pill-list.vue";
import UsersApiService from "../api/users-api-service";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    pillList,
  },
})
export default class UserRolePillList extends Vue {
  @Prop({ default: [] })
  roles: Array<any>;

  menuUsers = [];

  get items(): PillItem[] {
    return this.roles.map(({ id, name }) => ({
      value: id,
      name,
    }));
  }

  get menuItems(): MenuItem[] {
    return this.menuUsers.map(({ id, first_name, last_name }) => ({
      key: id,
      name: `${first_name} ${last_name}`,
    }));
  }

  fetchUsers(search) {
    this.menuUsers = [];
    new UsersApiService().getUsers({ search }).then((users) => (this.menuUsers = users));
  }

  onUpdate({ items }) {
    const roles = items.map(({ value, name }) => ({
      id: value,
      name,
    }));
    this.$emit("update", roles);
  }
}
</script>
