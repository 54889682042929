import { render, staticRenderFns } from "./profile-add-newsletter-subscription-modal.vue?vue&type=template&id=0dc33f1d&lang=pug"
import script from "./profile-add-newsletter-subscription-modal.vue?vue&type=script&lang=js"
export * from "./profile-add-newsletter-subscription-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports