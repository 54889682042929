<template lang="pug">
.filterset-selector(v-if="availableItems")
  pill-list(
    :title="'filters.filterset.saved_filter' | i18n", 
    :items="items", 
    :available-items="availableItems", 
    :searchable="availableItems.length > 10", 
    :radio="true", 
    :deleteable="true", 
    @update="onUpdateItems($event.items)"
    @delete="onDelete($event)"
    )
  button.btn.btn-sm.btn-primary(:disabled="isSetActive || !hasEnabledFilters", @click="openSaveModal") {{ 'actions.save' | i18n }}
  modal(
    v-model="saveModalOpen",
    :append-to-body="true",
    :title="'filters.actions.save_as' | i18n",
    :cancel-text="(requireConfirmation ? 'actions.edit' : 'actions.cancel') | i18n",
    :cancel-type="requireConfirmation ? 'primary' : 'default'",
    :ok-text="(requireConfirmation ? 'actions.confirm' : 'actions.save') | i18n",
    :ok-type="requireConfirmation ? 'danger' : 'primary'",
    :before-close="beforeClose"
    @hide="onHide"
    )
    form
      spinner(v-if="saving")
      .form(:class="{disabled: saving}")
        .form-group
          input.form-control(type='text' v-model='filterset.name' required=true :placeholder="'filters.filterset.name_placeholder' | i18n" autofocus=true style="max-width:100%;")
        .form-group(v-if="isAdmin")
          .checkbox
            label(for="shared")
              input(type="checkbox", name="shared", v-model="filterset.shared")
              | {{ 'filters.filterset.shared' | i18n }}
      div(v-if="requireConfirmation") {{ 'filters.filterset.override_filter_name' | i18n }}
      div(v-else-if="saveAsOwn") {{ 'filters.filterset.cannot_save_shared' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import pillList from "./pill-list.vue";
import { Prop, Watch } from "vue-property-decorator";
import { Modal } from "uiv";
import spinner from "../components/spinner.vue";
import _ from "lodash";
import FiltersConfiguration from "../model/filters-configuration";
import FiltersetItem from "../model/filterset-item";
import Actions from "../store/actions";

@Component({
  components: {
    pillList,
    Modal,
    spinner,
  },
})
export default class FiltersetSelector extends Vue {
  @Prop({
    default: (): FiltersConfiguration => ({
      v: 2,
      sets: [],
      filters: {},
    }),
  })
  config: FiltersConfiguration;

  saveModalOpen = false;
  saving = false;
  requireConfirmation = false;
  confirmed = false;
  filterset: { name: string; shared: boolean } = {
    name: "",
    shared: false,
  };
  lastSetId: number = null;

  isAdmin = !!window.zoinedContext?.isAdmin;
  saveAsOwn = false;

  get effectiveConfig(): FiltersConfiguration {
    if (this.config) {
      const sets = (this.config.sets || []).filter(({ id }) => _.find(this.filtersets, (set) => set.id == id));
      return {
        ...this.config,
        sets,
      };
    } else {
      return null;
    }
  }

  get items() {
    return (
      this.effectiveConfig.sets &&
      this.effectiveConfig.sets.map(({ id, enabled }) => {
        const set = _.find(this.filtersets, (set) => set.id == id);
        return {
          value: id,
          enabled,
          name: set.name,
        };
      })
    );
  }

  get availableItems() {
    return (
      this.filtersets &&
      this.filtersets.map(({ id, name, shared }) => ({
        key: id,
        name,
        deletable: !shared || this.isAdmin,
        category: shared ? "shared" : "own",
      }))
    );
  }

  get enabledSetId() {
    return _.find(this.effectiveConfig.sets, ({ enabled }) => enabled)?.id;
  }

  get enabledSet() {
    return this.enabledSetId && _.find(this.filtersets, (set) => set.id == this.enabledSetId);
  }

  get lastSet() {
    return this.lastSetId && _.find(this.filtersets, (set) => set.id == this.lastSetId);
  }

  get isSetActive() {
    return !!this.enabledSetId;
  }

  get hasEnabledFilters() {
    return !_.isEmpty(this.effectiveConfig.filters);
  }

  get filtersets() {
    return this.$store.state.filtersets.all;
  }

  openSaveModal() {
    this.saveModalOpen = true;
    this.filterset = {
      name: this.lastSet?.name || "",
      shared: !!this.lastSet?.shared,
    };
    if (!this.isAdmin) {
      this.filterset.shared = false;
    }
  }

  onUpdateItems(items) {
    const sets: FiltersetItem[] = items.map(({ value, enabled }) => ({ id: value, enabled }));
    this.onFiltersetsUpdated(sets);
  }

  onFiltersetsUpdated(sets: FiltersetItem[]) {
    const enabledSetId = sets.find(({ enabled }) => enabled)?.id;
    const enabledSet = enabledSetId && _.find(this.filtersets, (set) => set.id == enabledSetId);
    const filters = enabledSet ? _.cloneDeep(enabledSet.config.filters) : this.config.filters;
    const config: FiltersConfiguration = {
      v: 2,
      sets,
      filters,
    };
    this.$emit("update", config);
  }

  @Watch("config.filters")
  watchFilters(filters, old) {
    if (!_.isEqual(filters, old)) {
      // Set up enabled flag for filtersets based on current filters state
      const sets = _.map(this.effectiveConfig.sets, ({ id, enabled }) => {
        if (enabled) {
          const set = _.find(this.filtersets, (set) => set.id == id);
          if (!_.isEqual(filters, set.config.filters)) {
            enabled = false;
          }
        }
        return { id, enabled };
      });

      if (!_.isEqual(this.config.sets, sets)) {
        const config = {
          ...this.config,
          sets,
        };
        this.$emit("update", config);
      }
    }
  }

  @Watch("config.sets", { immediate: true })
  watchSets(sets) {
    const enabledSetId = sets?.find(({ enabled }) => enabled)?.id;
    if (enabledSetId) {
      this.lastSetId = enabledSetId;
    }
  }

  @Watch("filtersets")
  watchFiltersets() {
    this.onFiltersetsUpdated(this.config.sets || []);
  }

  onHide() {
    this.saving = this.requireConfirmation = this.saveAsOwn = this.confirmed = false;
  }

  onDelete(id) {
    this.$store.dispatch("filtersets/delete", id);
  }

  beforeClose(msg) {
    if (msg == "ok") {
      if (this.requireConfirmation) {
        this.confirmed = true;
      }
      this.save();
      return false;
    } else if (msg == "cancel" && this.requireConfirmation) {
      this.requireConfirmation = false;
      return false;
    } else {
      return true;
    }
  }

  save() {
    if (this.saving || _.isEmpty(this.filterset.name)) {
      return;
    }

    const config = {
      v: 2,
      filters: this.config.filters,
    };

    if (this.lastSet?.name === this.filterset.name) {
      if (this.lastSet.shared && !this.isAdmin) {
        this.saveAsOwn = true;
        this.lastSetId = null;
      } else {
        return this.updateFilterset(this.lastSet, config);
      }
    } else {
      const existingSet = _.find(this.filtersets, (set) => {
        return set.name == this.filterset.name && (this.isAdmin || !set.shared);
      });

      if (existingSet) {
        if (this.confirmed) {
          return this.updateFilterset(existingSet, config);
        } else {
          this.requireConfirmation = true;
        }
      } else {
        return this.createFilterset(config);
      }
    }
  }

  createFilterset(config) {
    this.saving = true;

    const filterset = {
      ...this.filterset,
      config,
    };

    this.$store.dispatch("filtersets/add", filterset).then((filterset) => {
      this.saveModalOpen = false;

      // Add set to config
      const sets = [...this.config.sets, { id: filterset.id, enabled: true }];
      const config = {
        ...this.config,
        sets,
      };

      this.$emit("update", config);
    });
  }

  updateFilterset(existingSet, config) {
    this.saving = true;

    const filterset = {
      ...existingSet,
      ...this.filterset,
      config,
    };

    this.$store.dispatch("filtersets/update", [filterset.id, filterset]).then((filterset) => {
      this.saveModalOpen = false;

      const sets = this.config.sets.map(({ id }) => ({
        id,
        enabled: id === filterset.id,
      }));

      if (!sets.find(({ id }) => id == filterset.id)) {
        sets.push({ id: filterset.id, enabled: true });
      }

      const config = {
        ...this.config,
        sets,
      };
      this.$emit("update", config);
    });
  }

  created() {
    if (!this.filtersets) {
      this.$store.dispatch(Actions.fetchFiltersets);
    }
  }
}
</script>

<style lang="scss" scoped>
.filterset-selector {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 20px;
  }
}
</style>
