<template lang="pug">
uiv-modal(v-if="team" v-model="open" :title="title")
  .row(v-if='error')
    .col-md-12
      .alert.alert-danger {{ error }}

  .row
    .col-md-12
      h4 {{ 'activerecord.attributes.team.name' | i18n }}
      input(type='text', v-model='team.name')

  .row
    .col-md-12
      h4 {{ 'activerecord.attributes.team.identifier' | i18n }}
      input(type='text', v-model='team.identifier', placeholder="example-team")
  .row
    .col-md-12
      h4
        | {{ 'setup.teams.team_permissions' | i18n }}
        span.info-bubble(v-uiv-tooltip="permissionsTooltip")
      permissions-editor(:permissions="team.permissions", default-permission="none", @update="updatePermissions")

  .row(v-if="restrictedDashboards && restrictedDashboards.length > 0")
    .col-md-12
      h4
        | {{ 'profile.allowed_restricted_dashboards' | i18n }}
        span.info-bubble(v-uiv-tooltip="allowedDashboardsTooltip")                  
      .permissions-row
        allowed-reports(:title="'dashboard_custom.dashboards' | i18n" :restricted-reports="restrictedDashboards" :allowed-reports="team.permitted_dashboards" @change="updatePermittedDashboards")


  .row(v-if="restrictedReports && restrictedReports.length > 0")
    .col-md-12
      h4
        | {{ 'profile.allowed_restricted_reports' | i18n }}
        span.info-bubble(v-uiv-tooltip="allowedReportsTooltip")                  
      .permissions-row
        allowed-reports(:title="'analytics.reports' | i18n", :restricted-reports="restrictedReports" :allowed-reports="team.permitted_reports" @change="updatePermittedReports")

  .row(v-if="newsletterDefinitions && newsletterDefinitions.length > 0")
    .col-md-12
      h4
        | {{ 'newsletter.subscription.list_title' | i18n }}
        span.info-bubble(v-uiv-tooltip="newsletterSubscriptionsTooltip")
      pill-list(:items="newsletterSubscriptions", :available-items="newsletterDefinitions", :label="'profile.subscriptions'|i18n", sort="title", :togglable="false" @update="updateNewsletterSubscriptions")

  div(slot="footer")
    confirm-button.pull-left(v-if="team.id" @confirm="deleteTeam", :button-title="'actions.delete' | i18n")
    button.btn(@click="cancel") {{ 'actions.cancel' | i18n }}
    button.btn.btn-primary(type="submit" @click="save") {{ 'actions.save' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Team from "../model/team";
import adminTeamsApi from "../api/admin-teams-api";
import PermissionsEditor from "../permissions/permissions-editor.vue";
import AllowedReports from "../permissions/allowed_reports.vue";
import ConfirmButton from "../components/confirm-button.vue";
import i18n from "../i18n";
import adminNewsletterDefinitions from "../api/admin-newsletter-definitions-api";
import adminCompaniesApi from "../api/admin-companies-api";
import PillList from "../components/pill-list.vue";
import PillItem from "@/interfaces/pill-item";
import MenuItem from "@/interfaces/menu-item";

@Component({
  components: {
    PermissionsEditor,
    AllowedReports,
    ConfirmButton,
    PillList,
  },
})
export default class TeamModal extends Vue {
  team: Team = null;

  newsletterSubscriptions: PillItem[] = [];

  open = false;

  error = null;

  newsletterDefinitions: MenuItem[] = null;

  restrictedDashboards = null;

  restrictedReports = null;

  get companyId() {
    return window.zoinedContext.companyId;
  }

  get title() {
    return this.team?.id ? this.team.name : "New team";
  }

  get permissionsTooltip() {
    return i18n.t("setup.teams.tooltips.permissions");
  }

  get allowedDashboardsTooltip() {
    return i18n.t("setup.teams.tooltips.allowed_dashboards");
  }

  get allowedReportsTooltip() {
    return i18n.t("setup.teams.tooltips.allowed_reports");
  }

  get newsletterSubscriptionsTooltip() {
    return i18n.t("setup.teams.tooltips.newsletter_subscriptions");
  }

  created() {
    adminNewsletterDefinitions.get({ company_id: this.companyId }).then((newsletterDefinitions) => {
      this.newsletterDefinitions = newsletterDefinitions.map(({ id, title }) => ({ id, name: title }));
    });
    adminCompaniesApi.getRestrictedDashboards({ id: this.companyId }).then((dashboards) => {
      this.restrictedDashboards = dashboards;
    });
    adminCompaniesApi.getRestrictedReports({ id: this.companyId }).then((reports) => {
      this.restrictedReports = reports;
    });
  }

  show(team: Team = null) {
    this.team = team || {
      id: null,
      name: "",
      identifier: "",
      permissions: null,
      permitted_dashboards: [],
      permitted_reports: [],
      newsletter_subscriptions: [],
    };
    this.newsletterSubscriptions = (this.team.newsletter_subscriptions || []).map(({ id, title }) => ({
      value: id,
      name: title,
      enabled: true,
    }));

    this.open = true;
  }

  async save() {
    try {
      const { id, name, identifier, permissions, permitted_dashboards, permitted_reports } = this.team;
      const attrs = {
        name,
        identifier,
        permissions,
        newsletter_definition_ids: this.newsletterSubscriptions.map(({ value }) => value),
        permitted_dashboard_ids: permitted_dashboards.map(({ id }) => id),
        permitted_report_ids: permitted_reports.map(({ id }) => id),
      };
      const action = id
        ? adminTeamsApi.put({ id, company_id: this.companyId, team: attrs })
        : adminTeamsApi.post({ company_id: this.companyId, team: attrs });

      const team = await action;
      this.$emit("save", team);
      this.open = false;
    } catch (error) {
      if (error.data && error.data.error) {
        this.error = error.data.error;
      } else {
        throw error;
      }
    }
  }

  cancel() {
    this.open = false;
  }

  async deleteTeam() {
    await adminTeamsApi.delete({ id: this.team.id, company_id: this.companyId });
    this.$emit("delete", this.team);
  }

  updatePermissions(permissions) {
    this.team.permissions = permissions;
  }

  updatePermittedReports(reports) {
    this.team.permitted_reports = reports;
  }

  updatePermittedDashboards(dashboards) {
    this.team.permitted_dashboards = dashboards;
  }

  updateNewsletterSubscriptions({ items }) {
    this.newsletterSubscriptions = items;
  }
}
</script>
