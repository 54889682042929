<template lang="pug">
.dashboard-block
  chart.graph(
    :type="effectiveChart",
    :pagination="false",
    :filters="effectiveFilters",
    :component="component",
    @pageChanged="pageChanged",
    ref="chart"
  )
  .graph-pagination(v-if="pagination")
    .row
      .col-sm-12
        .extra-controls
          .btn-group
            label.btn.btn-default.btn-sm(:class="{ active: relevant == true }", @click="relevant = true")
              | {{ "dashboard.show_more.show_relevant" | i18n }}
            label.btn.btn-default.btn-sm(:class="{ active: relevant == false }", @click="relevant = false")
              | {{ "dashboard.show_more.show_all" | i18n }}
          .btn-group(v-if="showTop")
            label.btn.btn-default.btn-sm(:class="{ active: top == true }", @click="top = true")
              | {{ "dashboard.show_more.top" | i18n }}
            label.btn.btn-default.btn-sm(:class="{ active: top == false }", @click="top = false")
              | {{ "dashboard.show_more.bottom" | i18n }}

      .col-sm-12
        pagination(:page="page", :totalPages="totalPages", @pageChange="changePage")
</template>

<script>
import chart from "../../zoined-chart/zoined-chart.vue";
import pagination from "../../components/pagination.vue";
import cloneDeep from "clone";

export default {
  components: {
    chart,
    pagination,
  },
  props: {
    chart: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    showTop: {
      type: Boolean,
      default: true,
    },
    component: {
      type: Object,
      required: false,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      page: 1,
      totalPages: 1,
      relevant: true,
      top: !(this.filters.trend === "bottom" || this.chart.endsWith("_bottom")),
    };
  },
  computed: {
    plainChartName: function () {
      if (this.chart.endsWith("_bottom")) return this.chart.replace(/_bottom$/, "");
      if (this.chart.endsWith("_top")) return this.chart.replace(/_top/, "");
      return this.chart;
    },
    effectiveFilters: function () {
      const f = cloneDeep(this.filters);
      delete f.trend;
      if (this.relevant) f.trend_relevant = true;
      return f;
    },
    effectiveChart: function () {
      return this.plainChartName + (this.top ? "_top" : "_bottom");
    },
  },
  methods: {
    changePage: function (page) {
      this.$refs.chart.switchPage(page);
    },
    pageChanged: function (pagination) {
      this.$emit("pageChanged", pagination); // Pass event up in the chain
      this.page = pagination.page;
      this.totalPages = pagination.totalPages;
    },
  },
};
</script>

<style lang="scss" scoped>
.extra-controls {
  margin-bottom: 10px;
  .btn-group:not(:last-child) {
    margin-right: 10px;
  }
}
</style>
