<template lang="pug">
confirm-button.btn.btn-default.btn-dark-new-layout.btn-sm.btn-icon.delete-report-button.report-action-button(
  tag="button"
  :popover-message="'custom_report.delete_report' | i18n"
  v-uiv-tooltip.hover="deleteTooltip" 
  @confirm="deleteReport"
) 
  i.fa.fa-trash.fa-lg
</template>

<script lang="ts">
import i18n from "../i18n";
import Vue from "vue";
import Component from "vue-class-component";
import ConfirmButton from "./confirm-button.vue";
import EventBus from "../events/event-bus";
import Events from "../events/events";

@Component({
  components: { ConfirmButton },
})
export default class DeleteReportButton extends Vue {
  get deleteTooltip() {
    return i18n.t("actions.delete");
  }

  deleteReport() {
    EventBus.emit(Events.deleteReport);
  }
}
</script>
