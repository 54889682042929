import Vuex, { createLogger } from "vuex";
import RootState from "./root-state";
import flyoverFilters from "./modules/flyover-filters";
import parameters from "./modules/parameters";
import navigation from "./modules/navigation";
import reportContext from "./modules/report-context";
import role from "./modules/role";
import preferences from "./modules/preferences";
import currentPage from "./modules/current-page";
import { createApiSlice } from "./api-slice";
import Actions from "./actions";
import Mutations from "./mutations";

const companyId = window.zoinedContext?.companyId;

const store = new Vuex.Store<RootState>({
  modules: {
    flyoverFilters,
    navigation,
    parameters,
    reportContext,
    role,
    preferences,
    currentPage,
    filtersets: createApiSlice("filtersets", `/api/internal/companies/${companyId}/filter_sets`, {
      transformItem: (filter_set) => ({ filter_set }),
    }),
    pdfReports: createApiSlice("pdfReports", `/api/admin/companies/${companyId}/pdf_reports`),
    excelReports: createApiSlice("excelReports", `/api/admin/companies/${companyId}/excel_reports`),
    customPeriods: createApiSlice("customPeriods", `/api/admin/companies/${companyId}/settings/custom_periods`),
  },
  getters: {
    defaultDashboardId: (state) => state.role.data?.default_dashboard_id,

    getReportContext: (state) => state.reportContext.data,

    hasChanges: (state, getters) => !!getters.getReportContext?.has_changes,

    preferences: (state) => state.preferences.data,

    // Returns parameters from reportContext, or from global store
    getParameters: (state, getters) => (param: string) =>
      getters.getReportContext?.parameters?.[param] || state.parameters[param]?.all,

    pageTitle: (state, getters) => state.currentPage.title || getters.getReportContext?.title,
  },
  plugins: [createLogger()],
});

if (window.zoinedContext.analyticsNavigation) {
  // Set navigation state from zoined context
  store.commit(Mutations.fetchNavigationSuccess, window.zoinedContext.analyticsNavigation);
}

if (window.zoinedContext.current_role) {
  store.commit(Mutations.setRoleData, window.zoinedContext.current_role);
}

if (window.zoinedContext.preferences) {
  store.commit(Mutations.setPreferences, window.zoinedContext.preferences);
}

export const fetchInitialParameters = () => {
  // Fetch main parameters initially
  return Promise.all([
    store.dispatch(Actions.fetchParameters("grouping")),
    store.dispatch(Actions.fetchParameters("metrics")),
    store.dispatch(Actions.fetchParameters("filters")),
    store.dispatch(Actions.fetchParameters("sort")),
    store.dispatch(Actions.fetchParameters("timePeriods")),
    store.dispatch(Actions.fetchParameters("comparisons")),
  ]);
};

export default store;
