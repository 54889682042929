<template lang="pug">
pill-list(
  :title="title",
  :searchable="false",
  :items="items",
  :defaults="defaultItems",
  :available-items="menuItems",
  :menu-open="itemMenuOpen",
  :pill-style="pillStyle",
  :draggable="true",
  :radio="radio",
  :togglable="togglable",
  @update:menu-open="onUpdateMenuOpen($event)",
  @update="onUpdate($event)"
)
  template(v-if="custom || customScenario", slot="menu-footer")
    .custom(v-if="custom")
      .custom-handle(@click="showCustomCalendar = !showCustomCalendar")
        .pull-right
          i.fa(:class="showCustomCalendar ? 'fa-chevron-up' : 'fa-chevron-down'")
        | {{ "filters.date_selector.custom" | i18n }}
      .custom-calendar(v-if="showCustomCalendar")
        calendar(
          is-expanded,
          :attributes="customCalendarAttributes",
          :max-date="new Date()",
          :first-day-of-week="firstDayOfWeek + 1",
          :locale="locale",
          @dayclick="onDayClick"
        )
    .custom-scenario(v-if="customScenario", @click="toggleScenario")
      .custom-scenario-handle
        .pull-right
          i.fa(:class="scenario ? 'fa-chevron-up' : 'fa-chevron-down'")
        | {{ "filters.custom_scenario.title" | i18n }}
      .custom-scenario-form(v-if="scenario", @click.stop="")
        .form-group
          label {{ "comparison" | i18n }}
          comparison-selector(v-model="scenario.comparison", :choices="availableComparisons")
        .form-group
          label {{ "filters.custom_scenario.percentage_change" | i18n }}
          .row
            .col-md-12
              .input-group
                input.form-control(type="number", v-model="scenario.percentageChange")
                span.input-group-btn
                  button.btn.btn-default(@click="addToScalingModifier(5)") +
                span.input-group-btn
                  button.btn.btn-default(@click="addToScalingModifier(-5)") -
        .actions
          button.btn.btn-success(@click="addCustomScenario", :disabled="!validScenario") {{ "actions.add" | i18n }}
</template>

<script>
import pillList from "./pill-list";
import Component from "vue-class-component";
import Vue from "vue";
import Calendar from "v-calendar/lib/components/calendar.umd";

import moment from "moment";
import TranslationService from "../core/translation.service";
import { Watch } from "vue-property-decorator";
import i18n from "../i18n";
import { comparisonToMenuKey, menuKeyToComparison } from "../lib/menu-helper";
import _ from "lodash";
import ComparisonSelector from "./comparison-selector.vue";

const translationService = new TranslationService();

@Component({
  components: { pillList, Calendar, ComparisonSelector },
  props: {
    title: {
      type: String,
      default: () => {
        return i18n.t("filters.compare_to");
      },
    },
    comparisons: {
      type: Array,
    },
    choices: {
      type: Array,
    },
    defaults: {
      type: Array,
    },
    pillStyle: {
      type: String,
      default: "pill",
    },
    radio: {
      type: Boolean,
      default: false,
    },
    togglable: {
      type: Boolean,
      default: true,
    },
    custom: {
      type: Boolean,
      default: true,
    },
    customScenario: {
      type: Boolean,
      default: true,
    },
  },
})
export default class ComparisonPillList extends Vue {
  showCustomCalendar = false;
  showCustomScenario = false;
  itemMenuOpen = false;
  customRangeDate = null;
  firstDayOfWeek = window.zoinedContext.firstDayOfWeek;
  locale = i18n.locale;
  scenario = null;

  get availableComparisons() {
    return this.choices || this.$store.getters.getParameters("comparisons");
  }

  get customCalendarAttributes() {
    return [
      {
        highlight: true,
        dates: this.customRangeDate,
      },
    ];
  }

  get items() {
    return (
      this.comparisons &&
      this.comparisons.map((comparison) => ({
        value: this.menuKey(comparison),
        name: translationService.comparisonTitle(comparison),
        enabled: !!comparison.enabled,
      }))
    );
  }

  get defaultItems() {
    return (
      this.defaults &&
      this.defaults.map((comparison) => ({
        value: this.menuKey(comparison),
        name: translationService.comparisonTitle(comparison),
        enabled: comparison.enabled,
      }))
    );
  }

  get menuItems() {
    if (!this.availableComparisons) {
      return [];
    } else {
      return this.availableComparisons.map((comparison) => ({
        key: this.menuKey(comparison),
        name: comparison.name,
      }));
    }
  }

  get validScenario() {
    return this.scenario && this.scenario.percentageChange !== 0;
  }

  menuKey(comparison) {
    return comparisonToMenuKey(comparison);
  }

  toggleScenario() {
    const { id, ...comparison } = _.first(this.availableComparisons);
    comparison.type = id;

    this.scenario = this.scenario
      ? null
      : {
          comparison,
          percentageChange: 0,
        };
  }

  addToScalingModifier(value) {
    this.scenario.percentageChange += value;
  }

  addCustomScenario() {
    const { comparison, percentageChange } = this.scenario;

    const scaling_modifier = percentageChange / 100 + 1;

    let comparisons = this.comparisons || [];
    if (this.radio) {
      comparisons = comparisons.map((c) => ({ ...c, enabled: false }));
    }

    comparisons = [...comparisons, { ...comparison, scaling_modifier, enabled: true }];

    this.itemMenuOpen = false;

    this.$emit("update", comparisons);
  }

  onUpdate({ items }) {
    const comparisons = items.map(({ value, enabled }) => {
      return menuKeyToComparison(value, enabled);
    });
    this.$emit("update", comparisons);
  }

  onUpdateMenuOpen(val) {
    this.itemMenuOpen = val;
  }

  onDayClick({ date }) {
    if (this.customRangeDate) {
      this.addCustomComparison(date, this.customRangeDate);
      this.itemMenuOpen = false;
    } else {
      this.customRangeDate = date;
    }
  }

  addCustomComparison(d1, d2) {
    let start = moment(d1);
    let end = moment(d2);
    [start, end] = start.isAfter(end) ? [end, start] : [start, end];

    const comparison = {
      type: "custom",
      start_time: start.format("YYYY-MM-DD"),
      end_time: end.format("YYYY-MM-DD"),
      enabled: true,
    };
    if (!this.comparisonExists(comparison)) {
      this.$emit("update", [...(this.comparisons || []), comparison]);
    }
  }

  comparisonExists(comparison) {
    return _.some(this.comparisons, (c) => this.menuKey(c) == this.menuKey(comparison));
  }

  @Watch("itemMenuOpen")
  onItemMenuOpen(open) {
    // Reset calendar when menu if closed
    if (!open) {
      this.showCustomCalendar = false;
      this.customRangeDate = null;
      this.scenario = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.custom {
  border-top: 1px solid #ccc;
  padding: 14px;
  cursor: pointer;
}
.custom-scenario {
  border-top: 1px solid #ccc;
  padding: 14px;
  cursor: pointer;
}

.custom-calendar {
  margin-top: 10px;
}

.custom-scenario-form {
  margin-top: 15px;

  .input-group-btn:not(:last-child) {
    .btn {
      border-radius: 0;
      margin-left: -1px;
    }
  }

  .actions {
    margin-top: 10px;
  }
}
</style>
